import React, {Component} from 'react';
import  "../../sass/components/UserCards.scss";
import  "../../sass/common.scss";
import ListGroup from "react-bootstrap/ListGroup";
import {isNullOrUndefined} from "../../utils/utils";
import Button from "react-bootstrap/Button";
import ReactPlayer from "react-player";

interface SubmissionPlayerProps {
    link: string
    className?: string;
    onPlay?: ()=>void;
    onPause?: ()=>void;
    onPlayerStatusUpdate?: (state: { played: number, playedSeconds: number, loaded: number, loadedSeconds: number })=>void;
    playing: boolean;
}

class SubmissionPlayer extends Component<SubmissionPlayerProps,any> {
    render() {
        let css = ['player-container'];
        if(this.props.className!==null && this.props.className!==undefined){
            css.push(this.props.className);
        }
        return (
            <div className={css.join(" ")}>
                <div className='player-wrapper middle-align'>
                    <ReactPlayer
                        className='react-player'
                        url={this.props.link}
                        width='100%'
                        onPlay={this.props.onPlay}
                        onPause={this.props.onPause}
                        playing={this.props.playing}
                        controls={true}
                        height='100%' progressInterval={1000} onProgress={this.props.onPlayerStatusUpdate}/>
                </div>
            </div>
        );
    }
}

export default SubmissionPlayer;