import React from 'react';
import withApiHandler, {ErrorHandler, InjectedApiHandlerProps} from "../hocs/WithApiHandler";
import withSystemState, {InjectedSystemStateProps} from "../hocs/WithSystemState";
import "../../sass/pages/Conversation.scss";
import "../../sass/common.scss";
import {AxiosResponse} from "axios";
import Spinner from "react-bootstrap/Spinner";
import asApiClient from "../../api_clients/as_client/ASApiClient";
import Button from "react-bootstrap/Button";
import {IoMdArrowRoundBack} from "react-icons/io";
import {Formik} from "formik";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import * as yup from "yup";
import Card from "react-bootstrap/Card";
import Avatar from "react-avatar";
import {IconContext} from "react-icons";
import HotIcon from '../../images/hot-icon.png';
import WorkIcon from '../../images/work-icon.png';
import TrashIcon from '../../images/trash-icon.png';
import { ReactComponent as PlusIcon } from '../../images/plus-icon.svg';
import {
    MiscConversation,
    MiscConversationMessage,
    ResponsesBaseResponse,
    ResponsesConversationResponse
} from "../../api_clients/as_client/src";

interface ConversationBaseProps{
    conversationUnid: string;
    conversation: MiscConversation;
    onBackHandler: ()=>void;
}

interface ConversationState{
    loading: boolean;
    messages: MiscConversationMessage[];
}

type ConversationProps = ConversationBaseProps & InjectedApiHandlerProps & InjectedSystemStateProps;

const messageSchema = yup.object({
    message: yup.string().required()
});

class Conversation extends React.Component<ConversationProps,ConversationState> {

    state={
        loading: false,
        messages: [] as MiscConversationMessage[],
    };

    componentDidMount(): void {
        this.loadMessages();
    }

    loadMessages = () =>{
        this.setState({
            loading:true,
        });
        this.props.handleRequest(asApiClient.conversationsApi.getConversationMessages(this.props.conversationUnid,this.props.systemState.token),(response:AxiosResponse<ResponsesConversationResponse>)=>{
            if(response.data.data!==undefined && response.data.data.messages !== null && response.data.data.messages!==undefined) {
                this.setState({
                    messages: response.data.data.messages
                });
            }
        },undefined,()=>{
            this.setState({
                loading:false
            })
        });
    };

    getPageContent = () =>{
        if(this.state.loading){
            return(
                <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            );
        }
        return(
            <div>
                {this.state.messages.map((message: MiscConversationMessage,index:number)=>{
                    return(
                        <div key={"message-"+index} className={"message"+(message.myMessage?" mine":"")}>
                            <div className={"content"}>
                                {message.message}
                            </div>
                        </div>
                    );
                })}
                {this.getMessageForm()}
            </div>
        );
    };

    onMessage = (formData:any) =>{
        this.setState({
            loading:true,
        });
        this.props.handleRequest(asApiClient.conversationsApi.createMessage(this.props.conversationUnid,this.props.systemState.token,formData),(response:AxiosResponse<ResponsesBaseResponse>)=>{
            this.loadMessages();
        },undefined,()=>{
            this.setState({
                loading:false
            })
        });
    };

    getMessageForm = () => {
        return (
            <div className={"message-form wn-form"}>
                <Formik
                    validationSchema={messageSchema}
                    onSubmit={this.onMessage}
                    initialValues={{
                        message: '',
                    }}
                >
                    {({
                          handleSubmit,
                          handleChange,
                          handleBlur,
                          values,
                          touched,
                          isValid,
                          errors,
                      }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Form.Row>
                                <Form.Group as={Col} xs="12" controlId={"validationMessage"}>
                                    <Form.Label className={"sr-only"}>Comment</Form.Label>
                                    <Form.Control as="textarea"
                                                  type="text"
                                                  name="message"
                                                  value={values.message}
                                                  onChange={handleChange}
                                                  isInvalid={!!errors.message}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <div className={"send-button"}>
                                    <Button size={"sm"} onClick={()=>handleSubmit()}>Send</Button>
                                </div>
                            </Form.Row>
                        </Form>
                    )}
                </Formik>
            </div>
        );
    };
    render(){
        return(
            <>
                <Button variant={"light"} onClick={()=>this.props.onBackHandler()}><IoMdArrowRoundBack/></Button>
                {this.props.conversation===null||this.props.conversation===undefined?null:<ConversationPreviewDisplay conversation={this.props.conversation}/>}
                <div className={"conversation"}>
                    {this.getPageContent()}
                </div>
            </>
        );
    }
}

interface ConversationPreviewProps{
    conversation: MiscConversation;
    onViewMessages?: (conversationUnid:string)=>void;
}

export function ConversationPreviewDisplay(props:ConversationPreviewProps) {
    let conversation = props.conversation;
    let icon = null;
    if (conversation.rating === 3) {
        icon = <img className={"rate-icon"} src={HotIcon} alt={"hot"}/>;
    } else if (conversation.rating === 2) {
        icon = <img className={"rate-icon"} src={WorkIcon} alt={"needs work"}/>;
    } else {
        icon = <img className={"rate-icon"} src={TrashIcon} alt={"trash"}/>;
    }
    let src = undefined as string | undefined;
    if(conversation.curatorPicture!==undefined && conversation.curatorPicture.length>0){
        src = conversation.curatorPicture;
    }
    return(
        <div className={"wn-list conversation-preview-container"}>
            <div className={"item"} onClick={()=>{if(props.onViewMessages!==null && props.onViewMessages!==undefined)props.onViewMessages(conversation.conversationUnid as string)}}>
                <Avatar size={"40px"} src={src} name={conversation.curatorName} round={true}/>
                <div className={"stacked-title"}>
                    <div className={"name"}>{conversation.curatorName}</div>
                    <div className={"track"}>{conversation.trackName}</div>
                </div>
                <div className={"rating-container"}>
                    <IconContext.Provider value={{size: "1.2em"}}>
                        {icon}
                    </IconContext.Provider>
                </div>
            </div>
        </div>
    );
}

export default withSystemState(withApiHandler(Conversation,ErrorHandler.TOAST));