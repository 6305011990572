import React, {Component} from 'react';
import {
    CardCVCElement,
    CardExpiryElement,
    CardNumberElement,
    injectStripe,
    ReactStripeElements
} from 'react-stripe-elements';
import Spinner from "react-bootstrap/Spinner";
import "../../sass/components/CardEntryForm.scss";
import  "../../sass/common.scss";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import {toast} from "react-toastify";
import Button from "react-bootstrap/Button";
import {Formik, FormikErrors, FormikValues} from "formik";
import * as yup from "yup";

interface CardEntryFormProps extends ReactStripeElements.InjectedStripeProps {
    tokenHandler: (token: stripe.Token, saveCard: boolean) => void;
    saveOptional?: boolean;
    exitingCardUpdateHandler?: (cardUpdate: any) => void;
    existingCard?: any|null|undefined;
    onCancel?: (() => void)|null|undefined;
}

const billingSchema = yup.object({
    name: yup.string().required(),
    address_line1: yup.string().required(),
    address_line2: yup.string(),
    address_city: yup.string().required(),
    address_state: yup.string().required(),
    address_zip: yup.string().required(),
    address_country: yup.string().required(),
});

const existingBillingSchema = yup.object({
    name: yup.string().required(),
    address_line1: yup.string().required(),
    address_line2: yup.string(),
    address_city: yup.string().required(),
    address_state: yup.string().required(),
    address_zip: yup.string().required(),
    address_country: yup.string().required(),
    exp_month: yup.number().min(1).max(12).required(),
    exp_year: yup.number().min(getThisYear()).required(),
});


function getThisYear():number{
    let date = new Date();
    return date.getFullYear();
}

class CardEntryForm extends Component<CardEntryFormProps,any> {
    state = {
        submitting: false,
        saveCard: false
    };

    submit = async (formData:any) => {
        if(!this.state.submitting) {
            this.setState({submitting:true},async ()=>{
                if(this.props.existingCard!==null&&this.props.existingCard!==undefined){
                    if(this.props.exitingCardUpdateHandler!==null && this.props.exitingCardUpdateHandler!==undefined){
                        let cardUpdateData = {
                            name: formData.name,
                            addressLine1: formData.address_line1,
                            addressLine2: formData.address_line2,
                            addressCity: formData.address_city,
                            addressState: formData.address_state,
                            addressZip: formData.address_zip,
                            addressCountry: formData.address_country,
                            expMonth: formData.exp_month,
                            expYear: formData.exp_year
                        } as any;

                        this.props.exitingCardUpdateHandler(cardUpdateData);
                    }
                }else{
                    if(this.props.tokenHandler!==null && this.props.tokenHandler!==undefined){
                        if (this.props.stripe) {
                            let tokenOptions = {...formData};
                            tokenOptions.type = "card";
                            let tokenResponse = await this.props.stripe.createToken(tokenOptions);
                            if(!(tokenResponse.error===null || tokenResponse.error===undefined)){
                                toast.error(tokenResponse.error.message, {position: toast.POSITION.TOP_CENTER});
                            }else {
                                if (tokenResponse.token !== undefined) {
                                    let save = this.props.saveOptional===true?this.state.saveCard:true;
                                    this.props.tokenHandler(tokenResponse.token, save);
                                }
                            }
                        }
                    }
                }
                this.setState({
                    submitting: false
                });
            });

        }
    };

    onCancel = () =>{
        if(!(this.props.onCancel===undefined || this.props.onCancel===null)){
            this.props.onCancel();
        }
    };

    getButtonDisplay = ():React.ReactNode =>{
        if(this.state.submitting){
            return(
                <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            );
        }
        return <span>Continue</span>
    };

    createOptions = (fontSize?:string, padding?:string) => {
        return {
            style: {
                base: {
                    fontSize,
                    color: '#424770',
                    letterSpacing: '0.025em',
                    fontFamily: 'Source Code Pro, monospace',
                    '::placeholder': {
                        color: '#aab7c4',
                    },
                    padding,
                },
                invalid: {
                    color: '#9e2146',
                },
            },
        };
    };

    getCancelButton = () =>{
        if(!(this.props.onCancel===null || this.props.onCancel===undefined)){
            return(
                <Button className="cancel" size="lg" block variant={"danger"} onClick={this.onCancel}>Cancel</Button>
            );
        }
    };

    getInitialCardValues = () =>{
        let values;
        if(this.props.existingCard!==null && this.props.existingCard!==undefined) {
            let card = this.props.existingCard;
            values = {
                name: card.name,
                address_line1: card.addressLine1,
                address_line2: card.addressLine2,
                address_city: card.addressCity,
                address_state: card.addressState,
                address_zip: card.addressZip,
                address_country: card.addressCountry,
                exp_month: card.expMonth,
                exp_year: card.expYear
            };
        } else{
            values = {
                name: '',
                address_line1: '',
                address_line2: '',
                address_city: '',
                address_state: '',
                address_zip: '',
                address_country: 'US'
            }
        }
        return values;
    };

    getFormSchema = () =>{
        if(this.props.existingCard!==null && this.props.existingCard!==undefined){
            return existingBillingSchema;
        }else{
            return billingSchema;
        }
    };

    getCardInputs = (values: FormikValues, errors: FormikErrors<FormikValues>, handleChange:any ) =>{
        if(this.props.existingCard!==null && this.props.existingCard!==undefined){
            return(
                <Form.Row>
                    <Form.Group as={Col} md="4" controlId="validationFormikExisting01">
                        <Form.Label>Expiration Month</Form.Label>
                        <Form.Control
                            type="number"
                            name="exp_month"
                            value={values.exp_month?values.exp_month:0}
                            onChange={handleChange}
                            isInvalid={!!errors.exp_month}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.exp_month}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId="validationFormikExisting02">
                        <Form.Label>Expiration Year</Form.Label>
                        <Form.Control
                            type="number"
                            name="exp_year"
                            value={values.exp_year?values.exp_year:0}
                            onChange={handleChange}
                            isInvalid={!!errors.exp_year}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.exp_year}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Form.Row>
            );
        }else{
            return(
                <>
                    <Form.Group controlId="card-number">
                        <span>Card Number</span>
                        <CardNumberElement
                            {...this.createOptions("14px")}
                        />
                    </Form.Group>
                    <Form.Group controlId="expiration-date">
                        <span>Expiration Date</span>
                        <CardExpiryElement
                            {...this.createOptions("14px")}
                        />
                    </Form.Group>
                    <Form.Group controlId="cvc">
                        <span>CVC</span>
                        <CardCVCElement
                            {...this.createOptions("14px")}
                        />
                    </Form.Group>
                </>
            );
        }
    };

    render() {
        return (
            <Container className="checkout-form">
                <Row>
                    <Col xs={12} lg={12}>
                        <Formik
                            validationSchema={this.getFormSchema()}
                            onSubmit={this.submit}
                            enableReinitialize={true}
                            initialValues={this.getInitialCardValues()}
                        >
                            {({
                                  handleSubmit,
                                  handleChange,
                                  handleBlur,
                                  values,
                                  touched,
                                  isValid,
                                  errors,
                              }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    {this.getCardInputs(values,errors,handleChange)}
                                    <div className={"billing"}>
                                        Billing Address
                                    </div>
                                    <Form.Row>
                                        <Form.Group as={Col} md="4" controlId="validationFormik01">
                                            <Form.Label>Name on Card</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="name"
                                                value={values.name}
                                                onChange={handleChange}
                                                isInvalid={!!errors.name}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.name}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" controlId="validationFormik02">
                                            <Form.Label>Address Line 1</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="address_line1"
                                                value={values.address_line1}
                                                onChange={handleChange}
                                                isInvalid={!!errors.address_line1}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.address_line1}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" controlId="validationFormik03">
                                            <Form.Label>Address Line 2</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="address_line2"
                                                value={values.address_line2}
                                                onChange={handleChange}
                                                isInvalid={!!errors.address_line2}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.address_line2}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="4" controlId="validationFormik04">
                                            <Form.Label>City</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="address_city"
                                                value={values.address_city}
                                                onChange={handleChange}
                                                isInvalid={!!errors.address_city}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.address_city}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="4" controlId="validationFormik05">
                                            <Form.Label>State</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="address_state"
                                                value={values.address_state}
                                                onChange={handleChange}
                                                isInvalid={!!errors.address_state}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.address_state}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="4" controlId="validationFormik06">
                                            <Form.Label>Zip Code</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="address_zip"
                                                value={values.address_zip}
                                                onChange={handleChange}
                                                isInvalid={!!errors.address_zip}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.address_zip}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        {this.props.saveOptional===true?<Form.Group as={Col} md="12" controlId="formBasicCheckbox">
                                            <Form.Check type="checkbox" label="save card for later" onChange={(e:any)=>this.setState({saveCard:e.target.checked})}/>
                                        </Form.Group> :null}
                                    </Form.Row>
                                    {this.getCancelButton()}
                                    <Button variant={"primary"} size={"lg"} block onClick={()=>handleSubmit()}>{this.getButtonDisplay()}</Button>
                                </Form>
                            )}
                        </Formik>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default injectStripe(CardEntryForm);