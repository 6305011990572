import React from 'react';
import withApiHandler, {ErrorHandler, InjectedApiHandlerProps} from "../hocs/WithApiHandler";
import withSystemState, {InjectedSystemStateProps} from "../hocs/WithSystemState";
import Row from "react-bootstrap/Row";
import "../../sass/pages/CardsPage.scss";
import "../../sass/common.scss";
import {AxiosResponse} from "axios";
import Spinner from "react-bootstrap/Spinner";
import {RouteComponentProps, withRouter} from 'react-router-dom';
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Collapse from "react-bootstrap/Collapse";
import {Elements, StripeProvider} from "react-stripe-elements";
import asApiClient from "../../api_clients/as_client/ASApiClient";
import UserCards from "../misc/UserCards";
import config from "../../config/config";
import CardEntryForm from "../misc/CardEntryForm";
import {AddCard, MiscCard, ResponsesCardsResponse} from "../../api_clients/as_client/src";

interface CardsViewBaseProps{
}

interface CardsViewState{
    userCards: MiscCard[];
    loading: boolean;
    enteringCard: boolean;
}

type CardsViewProps = CardsViewBaseProps & InjectedApiHandlerProps & InjectedSystemStateProps & RouteComponentProps

class CardsView extends React.Component<CardsViewProps,CardsViewState> {

    state={
        userCards: [] as MiscCard[],
        loading: false,
        enteringCard: false,
    };

    componentDidMount(): void {
        this.loadCards();
    }

    loadCards = () =>{
        this.setState({
            loading:true,
            enteringCard:false
        });
        this.props.handleRequest(asApiClient.usersApi.getCards(this.props.systemState.token),(response:AxiosResponse<ResponsesCardsResponse>)=>{
            let cards = response.data.data.cards;
            if(cards===null){
                cards = [];
            }
            this.setState({
                userCards: cards
            });
        },undefined,()=>{
            this.setState({
                loading:false
            })
        });
    };

    tokenHandler = (token: stripe.Token) =>{
        let body = {
            tokenID:token.id
        } as AddCard;
        this.props.handleRequest(asApiClient.usersApi.addCard(this.props.systemState.token,body),()=>{
            this.loadCards();
        });
    };

    onCardDelete = (card:MiscCard) =>{
        this.props.handleRequest(asApiClient.usersApi.deleteCard(card.unid as string,this.props.systemState.token),()=>{
            this.loadCards();
        })
    };

    getPageContent = () =>{
        if(this.state.loading){
            return(
                <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            );
        }
        return(
            <>
                <Row>
                    <Col>
                        <UserCards cards={this.state.userCards} onDelete={this.onCardDelete}/>
                        <div className={"wn-form"}>
                            <Button variant="primary" size="lg" block onClick={()=>this.setState({enteringCard:true})}>Add a Card</Button>
                        </div>
                    </Col>
                </Row>
                <Row className={"middle-align"}>
                    <Col xs={11} md={11} lg={11}>
                        <Collapse in={this.state.enteringCard}>
                            <div>
                                <StripeProvider apiKey={config.stripe.key}>
                                    <Elements>
                                        <CardEntryForm tokenHandler={this.tokenHandler} onCancel={()=>this.setState({enteringCard:false})}/>
                                    </Elements>
                                </StripeProvider>
                            </div>
                        </Collapse>
                    </Col>
                </Row>
            </>

        );
    };

    render(){
        return(
            <>
                <div className={"cards-page"}>
                    {this.getPageContent()}
                </div>
            </>
        );
    }
}

export default withSystemState(withApiHandler(withRouter(CardsView),ErrorHandler.TOAST));