import React, {Component} from 'react';
import  "../../sass/common.scss";
import  "../../sass/components/Banner.scss";
import withApiHandler, {ErrorHandler, InjectedApiHandlerProps} from "../hocs/WithApiHandler";
import {Carousel} from "react-responsive-carousel";
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import ReactPlayer from "react-player";
import Button from "react-bootstrap/Button";
import {MdClose} from "react-icons/md";
import { IconContext } from 'react-icons';
import {DbmodelsBannerItem} from "../../api_clients/as_client/src";


interface BannerProps extends InjectedApiHandlerProps{
    bannerImages: DbmodelsBannerItem[];
}

interface BannerState{
    display: boolean;
}

class Banner extends Component<BannerProps,BannerState> {

    state={
        display: true
    };

    displayItem = (item: DbmodelsBannerItem) =>{
        if(item.type==="image"){
            return(
                <img src={item.url}/>
            );
        }else if(item.type==="video"){
            return(
                <ReactPlayer
                    className='react-player'
                    url={item.url}
                    playing={true}
                    loop={true}
                    width='100%'
                    height='100%'
                    controls={true}
                />
            );
        }
    };

    onClose = () =>{
        this.setState({display:false});
    };

    render() {
        if(!this.state.display){
            return null;
        }
        if(this.props.bannerImages.length<=0){
            return null;
        }
        return (
            <div className={"banner"}>
                <div className={"dismiss"}>
                    <IconContext.Provider value={{ size: "2em"}}>
                        <Button variant={"light"} onClick={this.onClose}><MdClose/></Button>
                    </IconContext.Provider>
                </div>
                <Carousel autoPlay={true} infiniteLoop={true} interval={4000} showThumbs={false} useKeyboardArrows={true}>
                    {this.props.bannerImages.map((item:DbmodelsBannerItem, index:number)=>{
                        return(
                            <div key={index}>
                                {this.displayItem(item)}
                            </div>
                        );
                    })}
                </Carousel>
            </div>
        );
    }
}

export default withApiHandler(Banner,ErrorHandler.TOAST);