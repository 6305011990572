import {
    DbmodelsSubmission,
} from "../../api_clients/as_client/src";
export interface SystemState {
    token: string;
    adminToken: string,
    submission: DbmodelsSubmission
}

export const UPDATE_SYSTEM_TOKEN = 'UPDATE_SYSTEM_TOKEN';
export const UPDATE_ADMIN_SYSTEM_TOKEN = 'UPDATE_ADMIN_SYSTEM_TOKEN';
export const UPDATE_SUBMISSION = 'UPDATE_SUBMISSION'

interface UpdateSystemStateAction {
    type: typeof UPDATE_SYSTEM_TOKEN
    payload: string
}

interface UpdateAdminSystemStateAction {
    type: typeof UPDATE_ADMIN_SYSTEM_TOKEN
    payload: string
}

interface UpdateSubmission {
    type: typeof UPDATE_SUBMISSION
    payload: DbmodelsSubmission
}

export type SystemActionTypes = UpdateSystemStateAction | UpdateAdminSystemStateAction | UpdateSubmission;