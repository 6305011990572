import React, {Component} from 'react';
import "../../sass/common.scss";
import withApiHandler, {ErrorHandler, InjectedApiHandlerProps} from "../hocs/WithApiHandler";
import asApiClient from "../../api_clients/as_client/ASApiClient";
import {AxiosResponse} from "axios";
import {getPosition} from "./CuratorLeaderboard";
import config from "../../config/config";
import {MiscTrackLeaderboardEntry, ResponsesTrackLeaderboardResponse} from "../../api_clients/as_client/src";
import {getViewRatedTrackTitle, getViewTrackTitle, ModalEntry} from "../../pages/LandingPage";
import SubmissionViewer from "./SubmissionViewer";

interface TrackLeaderboardBaseProps {
    openModal: (modal: ModalEntry)=>void;
}

interface TrackLeaderboardState{
    trackEntries: MiscTrackLeaderboardEntry[];
}

type TrackLeaderboardProps = TrackLeaderboardBaseProps & InjectedApiHandlerProps;

class TrackLeaderboard extends Component<TrackLeaderboardProps,TrackLeaderboardState> {
    state={
        trackEntries: []
    };
    componentDidMount(): void {
        this.props.handleRequest(asApiClient.leaderboardsApi.getTrackLeaderboard(),(response:AxiosResponse<ResponsesTrackLeaderboardResponse>)=>{
            if(response.data.data.trackLeaderboard!==null && response.data.data.trackLeaderboard!==undefined) {
                this.setState({
                    trackEntries: response.data.data.trackLeaderboard
                });
            }
        });
    }

    showSubmission = (entry:MiscTrackLeaderboardEntry) =>{
        this.props.openModal({
            title: getViewTrackTitle(entry.trackName as string,entry.artistName as string),
            body: <div className={"submission-modal"}><SubmissionViewer submissionUnid={entry.submissionUnid as string}/></div>
        })
    };

    render() {
        return (
            <div className={"track-leaderboard"}>
                <div className={"wn-list tracks"}>
                    {this.state.trackEntries.map((entry:MiscTrackLeaderboardEntry,index:number)=>{
                        return(
                            <div className={"item"} key={"curator-entry-"+index} onClick={()=>this.showSubmission(entry)}>
                                <div className={"position"}>{getPosition(index)}</div>
                                <div className={"name"}><div className={"track-name"}>{entry.trackName}</div><div className={"artist-name"}>{entry.artistName}</div></div>
                                <div className={"points"}>{entry.points} <span className={"small"}>points</span></div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

export default withApiHandler(TrackLeaderboard,ErrorHandler.TOAST);