import React, {Component} from 'react';
import "../../sass/common.scss";
import withApiHandler, {ErrorHandler, InjectedApiHandlerProps} from "../hocs/WithApiHandler";
import asApiClient from "../../api_clients/as_client/ASApiClient";
import {AxiosResponse} from "axios";
import FirstIcon from '../../images/first-icon.png';
import SecondIcon from '../../images/second-icon.png';
import ThirdIcon from '../../images/third-icon.png';
import {MiscCuratorLeaderboardEntry, ResponsesCuratorLeaderboardResponse} from "../../api_clients/as_client/src";

interface CuratorLeaderboardBaseProps {

}

interface CuratorLeaderboardState{
    curatorEntries: MiscCuratorLeaderboardEntry[];
}

type CuratorLeaderboardProps = CuratorLeaderboardBaseProps & InjectedApiHandlerProps;

class CuratorLeaderboard extends Component<CuratorLeaderboardProps,CuratorLeaderboardState> {
    state={
        curatorEntries: []
    };
    componentDidMount(): void {
        this.props.handleRequest(asApiClient.leaderboardsApi.getCuratorLeaderboard(),(response:AxiosResponse<ResponsesCuratorLeaderboardResponse>)=>{
            if(response.data.data!==undefined&&response.data.data.curatorLeaderboard!==null && response.data.data.curatorLeaderboard!==undefined) {
                this.setState({
                    curatorEntries: response.data.data.curatorLeaderboard
                });
            }
        });
    }

    render() {
        return (
            <div className={"curator-leaderboard"}>
                <div className={"wn-list curators"}>
                    {this.state.curatorEntries.map((entry:MiscCuratorLeaderboardEntry,index:number)=>{
                        return(
                            <div className={"item"} key={"curator-entry-"+index}>
                                <div className={"position"}>{getPosition(index)}</div>
                                <div className={"name"}>{entry.name}</div>
                                <div className={"points"}>{entry.points} <span className={"small"}>points</span></div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

export function getPosition(index:number){
    if(index>=0 && index<3){
        if(index===0){
            return <img className={"position-icon"} src={FirstIcon} alt={"first place"}/>
        }else if(index===1){
            return <img className={"position-icon"} src={SecondIcon} alt={"second place"}/>
        }else if(index===2){
            return <img className={"position-icon"} src={ThirdIcon} alt={"third place"}/>
        }
    }else{
        return <span>#{index+1}</span>
    }
}

export default withApiHandler(CuratorLeaderboard,ErrorHandler.TOAST);