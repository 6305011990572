import React, {Component, RefObject} from 'react';
import "../../sass/common.scss";
import Button from "react-bootstrap/Button";
import ReactPlayer from "react-player";
import Collapse from "react-bootstrap/Collapse";
import ProgressBar from "react-bootstrap/ProgressBar";
import config from "../../config/config";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import {Formik} from "formik";
import * as yup from "yup";
import "../../sass/components/SubmissionRater.scss";
import HotIcon from '../../images/hot-icon.png';
import WorkIcon from '../../images/work-icon.png';
import TrashIcon from '../../images/trash-icon.png';
import { ReactComponent as PlusIcon } from '../../images/plus-icon.svg';
import SubmissionPlayer from "./SubmissionPlayer";
import {DbmodelsSubmissionDetailed} from "../../api_clients/as_client/src";

interface SubmissionDisplayBaseProps {
    submission: DbmodelsSubmissionDetailed;
    onRate?: (rating: number) => void;
    onFlag?: ()=>void;
    onComment?: (formData:any, submission:DbmodelsSubmissionDetailed) => void;
    onContinuousScroll?: boolean;
    forwardRef?: any;
    viewOnly?: boolean;
    lastSubmission?: boolean;
    onPlay?: ()=>void;
    flagDisabled?: boolean;
}

interface SubmissionDisplayState {
    playedSeconds: number;
    showCommentForm: boolean;
    playing: boolean;
}

const commentSchema = yup.object({
    comment: yup.string().required()
});

type SubmissionDisplayProps = SubmissionDisplayBaseProps;

class SubmissionDisplay extends Component<SubmissionDisplayProps, SubmissionDisplayState> {
    state = {
        playedSeconds: 0,
        showCommentForm:false,
        playing: false,
    };

    onPlayerStatusUpdate = (state: { played: number, playedSeconds: number, loaded: number, loadedSeconds: number }) => {
        if (this.state.playedSeconds < config.secondsBeforeRating) {
            if(state.playedSeconds>0) {
                this.setState({
                    playedSeconds: this.state.playedSeconds + 1
                });
            }
        }
    };

    onComment = (formData:any) =>{
        if(this.props.onComment!==null && this.props.onComment!==undefined){
            this.props.onComment(formData,this.props.submission);
        }
    };

    getComment = () =>{
        if(this.props.submission.comment!==null && this.props.submission.comment!==undefined && this.props.submission.comment.message!==undefined && this.props.submission.comment.message.length>0){
            return this.props.submission.comment.message;
        }
        return null;
    };

    getCommentForm = () => {
        if (this.hasScore()) {
            let comment = this.getComment();
            let hasComment = this.props.submission.comment!==null && this.props.submission.comment!==undefined && this.props.submission.comment.message!==undefined && this.props.submission.comment.message.length>0;
            if(comment!==null){
                return <div className={"comment-content"}><span className={"text"}>{comment}</span></div>
            }
            // @ts-ignore
            // @ts-ignore
            return (
                <Formik
                    validationSchema={commentSchema}
                    onSubmit={this.onComment}
                    initialValues={{
                        comment: '',
                    }}
                >
                    {({
                          handleSubmit,
                          handleChange,
                          handleBlur,
                          values,
                          touched,
                          isValid,
                          errors,
                      }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Form.Row>
                                <Form.Group as={Col} xs="12" controlId={"validationComment-"+this.props.submission.unid}>
                                    <Form.Label className={"sr-only"}>Enter artist feedback</Form.Label>
                                    <Form.Control as="textarea"
                                                  type="text"
                                                  name="comment"
                                                  placeholder={"enter artist feedback"}
                                                  disabled={hasComment}
                                                  value={comment!==null?comment:values.comment}
                                                  onChange={handleChange}
                                                  isInvalid={!!errors.comment}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.comment}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row>
                            {hasComment?null:<Button className="comment-send" size={"sm"} onClick={()=>handleSubmit()}>Submit</Button>}
                        </Form>
                    )}
                </Formik>
            );
        }
        return null;
    };

    onRate = (rating: number) => {
        if (this.props.onRate !== null && this.props.onRate !== undefined) {
            this.props.onRate(rating);
        }
        this.setState({playing:false});
    };

    onFlag = () =>{
        if (this.props.onFlag !== null && this.props.onFlag !== undefined) {
            this.props.onFlag();
        }
        this.setState({playing:false});
    };

    getRateBar = () => {
        if (!this.hasScore()) {
            return (
                <div className={"ratebar"}>
                    <div className={"rate-section"}>
                        <div className={"divider"}><div className={"content"}/></div>
                        <div tabIndex={0} aria-label={"rate hot"} className={"rate-btn"} onClick={() => this.onRate(3)}>
                            <img className={"rate-icon"} src={HotIcon} alt={"hot"}/>
                            <div className={"rate-text"}>Fire</div>
                        </div>
                    </div>
                    <div className={"rate-section"}>
                        <div className={"divider"}><div className={"content"}/></div>
                        <div tabIndex={0} aria-label={"rate needs work"} className={"rate-btn"} onClick={() => this.onRate(2)}>
                            <img className={"rate-icon"} src={WorkIcon} alt={"needs work"}/>
                            <div className={"rate-text"}>Needs Work</div>
                        </div>
                    </div>
                    <div className={"rate-section"}>
                        <div tabIndex={0} aria-label={"rate trash"} className={"rate-btn"} onClick={() => this.onRate(1)}>
                            <img className={"rate-icon"} src={TrashIcon} alt={"trash"}/>
                            <div className={"rate-text"}>Trash</div>
                        </div>
                    </div>
                </div>
            );
        } else {
            let submission = this.props.submission;
            if(submission.rating!==undefined) {
                let icon = null;
                if (submission.rating.score === 3) {
                    icon = <img className={"rate-icon"} src={HotIcon} alt={"hot"}/>;
                } else if (submission.rating.score === 2) {
                    icon = <img className={"rate-icon"} src={WorkIcon} alt={"needs work"}/>;
                } else {
                    icon = <img className={"rate-icon"} src={TrashIcon} alt={"trash"}/>;
                }
                let hasComment = this.props.submission.comment !== null && this.props.submission.comment !== undefined && this.props.submission.comment.message!==undefined && this.props.submission.comment.message.length > 0;
                return (
                    <div tabIndex={0} aria-label={"tap to leave artist feedback"}
                         className={"ratebar center comment" + (this.state.showCommentForm ? "" : " action")}
                         onClick={() => this.state.showCommentForm ? null : this.setState({showCommentForm: true})}>
                        <div className={"rating " + (this.state.showCommentForm && !hasComment ? "comment" : "")}>
                            {icon}
                        </div>
                        <Collapse in={!this.props.viewOnly && !this.state.showCommentForm}>
                            <div className={"tap-to-comment"}>
                                <PlusIcon/> <span className="text">tap to leave artist feedback</span>
                            </div>
                        </Collapse>
                        <Collapse in={this.props.viewOnly || this.state.showCommentForm}>
                            <div className={"comment-form"}>
                                {this.getCommentForm()}
                            </div>
                        </Collapse>
                    </div>
                );
            }
        }
    };

    getActionSection = () => {
        return (
            <>
                <Collapse in={!this.props.viewOnly && (this.state.playedSeconds < config.secondsBeforeRating)}>
                    <div>
                        {this.state.playedSeconds===0?<div className={"tap-to-start"}>Tap Above To Listen</div>:
                        <><div className={"time-left"}>{config.secondsBeforeRating - Math.floor(this.state.playedSeconds)} seconds left before you can rate</div>
                            <ProgressBar min={0} max={config.secondsBeforeRating} now={this.state.playedSeconds}/>
                        </>}
                    </div>
                </Collapse>
                <Collapse in={this.props.viewOnly || (this.state.playedSeconds >= config.secondsBeforeRating)}>
                    <div>
                        {this.getRateBar()}
                    </div>
                </Collapse>
            </>
        );
    };

    hasScore = () =>{
        return this.props.submission.rating !== null && this.props.submission.rating!==undefined && this.props.submission.rating.score!==undefined && this.props.submission.rating.score>0;
    };

    getFlaggingAction = () =>{
        if(this.props.flagDisabled===true){
            return null;
        }
        if (this.props.onFlag !== null && this.props.onFlag !== undefined) {
            return (
                <div className={"wn-form"}><div className={"flag text-link"}><a href="#!" onClick={()=>this.onFlag()}>Track Not Playing?</a></div></div>
            );
        }
        return null;
    };

    onPlay = () =>{
        this.setState({playing:true});
        if(this.props.onPlay!==undefined && this.props.onPlay!==null){
            this.props.onPlay();
        }
    };

    render() {
        let submission = this.props.submission;
        let css = (this.hasScore() ? " rated" : "") +(this.props.onContinuousScroll?" cont-scroll":"");
        return (
            <div key={"submission-" + submission.unid} className={"submission" + (this.props.lastSubmission?" last":"")}>
                <div className={"title"}>{submission.title}</div>
                <div className={"artist"}>{submission.artist}</div>
                {this.getFlaggingAction()}
                <SubmissionPlayer link={submission.link as string} playing={this.state.playing} onPlay={this.onPlay}
                                  onPause={()=>this.setState({playing:false})} onPlayerStatusUpdate={this.onPlayerStatusUpdate} className={css}/>
                {this.getActionSection()}
            </div>
        );
    }
}

export default SubmissionDisplay;