import React, {Component, RefObject} from 'react';
import "../../sass/common.scss";
import "../../sass/components/SubmissionRater.scss";
import SubmissionPlayer from "./SubmissionPlayer";
import withApiHandler, {ErrorHandler, InjectedApiHandlerProps} from "../hocs/WithApiHandler";
import asApiClient from "../../api_clients/as_client/ASApiClient";
import withSystemState, {InjectedSystemStateProps} from "../hocs/WithSystemState";
import {AxiosResponse} from "axios";
import {
    DbmodelsSubmissionWithArtist, ResponsesSubmissionResponse,
    ResponsesSubmissionWithArtistAndRatingResponse
} from "../../api_clients/as_client/src";
import Spinner from "react-bootstrap/Spinner";

interface SubmissionViewerBaseProps {
    submissionUnid: string;
}

interface SubmissionViewerState {
    submission: DbmodelsSubmissionWithArtist | null;
    loading:boolean;

}

type SubmissionViewerProps = SubmissionViewerBaseProps & InjectedApiHandlerProps & InjectedSystemStateProps;

class SubmissionViewer extends Component<SubmissionViewerProps, SubmissionViewerState> {
    state = {
        submission: null,
        loading: true
    };

    componentDidMount(): void {
        this.props.handleRequest(asApiClient.submissionsApi.getSubmission(this.props.submissionUnid),(response:AxiosResponse<ResponsesSubmissionWithArtistAndRatingResponse>)=>{
            this.setState({
                submission: response.data.data.submission
            });
        },undefined,()=>{
            this.setState({loading:false})
        })
    }

    render() {
        if (this.state.loading) {
            return (
                <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            );
        }
        if(this.state.submission!=null) {
            let sub = this.state.submission as unknown as DbmodelsSubmissionWithArtist;
            return (
                <div className={"submission-viewer"}>
                    <SubmissionPlayer playing={false} link={sub.link as string}/>
                </div>
            );
        }
        return null;
    }
}

export default withSystemState(withApiHandler(SubmissionViewer,ErrorHandler.TOAST));