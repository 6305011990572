let local = {
    stripe:{
        key: "pk_test_yA9h0z75Nb5gPo238gKECaGs"
    },
    asApi:{
        endpoint: "http://localhost:5000"
    },
    fb:{
        appID: "485186892110066",
        pixelID: "213382756761427"
    },
    secondsBeforeRating: 2,
    googleAnalytics:{
        id: "UA-157620535-1"
    },
    googleGMT:{
        id: "GTM-PH8RMXC"
    },
    leaderboardsStartTime:{
        curator: "2020-03-03T00:00:00Z",
        track: "2020-03-03T00:00:00Z"
    },
    hubSpot:{
        events:{
            login: "000008975429",
            signup: "000008975442",
            trackRated: "000008975449",
            trackFlagged: "000008976142",
            trackComment: "000008975784",
            submitStep2: "000008974804",
            submitStep4ID: "000008975787",
            flagDetailsRequested: "000009075665",
            flagBanned: "000009075497",

            submitCC: "000009155334",
            submitOptin: "000009155335",
            submitInterest: "000009155333",
            sponsorClick: "000009155348"
        }
    }
};

let dev = {
    stripe:{
        key: "pk_test_yA9h0z75Nb5gPo238gKECaGs"
    },
    asApi:{
        endpoint: "https://degrfkqetc9zx.cloudfront.net"
    },
    fb:{
        appID: "658526187577412",
        pixelID: "213382756761427"
    },
    secondsBeforeRating: 3,
    googleAnalytics:{
        id: "UA-112687309-3"
    },
    googleGMT:{
        id: "GTM-PH8RMXC"
    },
    leaderboardsStartTime:{
        curator: "2020-03-03T00:00:00Z",
        track: "2020-03-03T00:00:00Z"
    },
    hubSpot:{
        events:{
            login: "000008975429",
            signup: "000008975442",
            trackRated: "000008975449",
            trackFlagged: "000008976142",
            trackComment: "000008975784",
            submitStep2: "000008974804",
            submitStep4ID: "000008975787",
            flagDetailsRequested: "000009075665",
            flagBanned: "000009075497",

            submitCC: "000009155334",
            submitOptin: "000009155335",
            submitInterest: "000009155333",
            sponsorClick: "000009155348"
        }
    }
};

let staging = {
    stripe:{
        key: ""
    },
    asApi:{
        endpoint: "http://artistsubmission-stating-2.us-east-2.elasticbeanstalk.com"
    },
    fb:{
        appID: "",
        pixelID: ""
    },
    secondsBeforeRating: 20,
    googleAnalytics:{
        id: ""
    },
    googleGMT:{
        id: "GTM-WBQ626Z"
    },
    leaderboardsStartTime:{
        curator: "2020-03-03T00:00:00Z",
        track: "2020-03-03T00:00:00Z"
    },
    hubSpot:{
        events:{
            login: "000008975429",
            signup: "000008975442",
            trackRated: "000008975449",
            trackFlagged: "000008976142",
            trackComment: "000008975784",
            submitStep2: "000008974804",
            submitStep4ID: "000008975787",
            flagDetailsRequested: "000009075665",
            flagBanned: "000009075497",

            submitCC: "000009155334",
            submitOptin: "000009155335",
            submitInterest: "000009155333",
            sponsorClick: "000009155348"
        }
    }
};

let prod = {
    stripe:{
        key: "pk_live_nmDmKwuPsym0jY72n5cl15z800fJ8qBM4J"
    },
    asApi:{
        endpoint: "https://d13fn6ee4cgnri.cloudfront.net"
    },
    fb:{
        appID: "658526187577412",
        pixelID: "213382756761427"
    },
    secondsBeforeRating: 5,
    googleAnalytics:{
        id: "UA-112687309-3"
    },
    googleGMT:{
        id: "GTM-WBQ626Z"
    },
    leaderboardsStartTime:{
        curator: "2020-03-06T17:00:00Z",
        track: "2020-03-06T17:00:00Z"
    },
    hubSpot:{
        events:{
            login: "000008975429",
            signup: "000008975442",
            trackRated: "000008975449",
            trackFlagged: "000008976142",
            trackComment: "000008975784",
            submitStep2: "000008974804",
            submitStep4ID: "000008975787",
            flagDetailsRequested: "000009075665",
            flagBanned: "000009075497",

            submitCC: "000009155334",
            submitOptin: "000009155335",
            submitInterest: "000009155333",
            sponsorClick: "000009155348"
        }
    }
};

const config  = () =>{
    console.log(process.env.REACT_APP_ENV);
    return process.env.REACT_APP_ENV === "prod"?prod:process.env.REACT_APP_ENV === "dev"?dev:process.env.REACT_APP_ENV === "staging"?staging:local;
};

export default config();
