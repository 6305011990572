// tslint:disable
/**
 * Artist Submission API
 * This is the api used by artist submission app
 *
 * The version of the OpenAPI document: 0.100000
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AddCard
 */
export interface AddCard {
    /**
     * 
     * @type {string}
     * @memberof AddCard
     */
    tokenID: string;
}
/**
 * 
 * @export
 * @interface AddContest
 */
export interface AddContest {
    /**
     * 
     * @type {string}
     * @memberof AddContest
     */
    endTime: string;
    /**
     * 
     * @type {string}
     * @memberof AddContest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AddContest
     */
    startTime: string;
    /**
     * 
     * @type {string}
     * @memberof AddContest
     */
    submitDeadline: string;
    /**
     * 
     * @type {string}
     * @memberof AddContest
     */
    type: string;
}
/**
 * 
 * @export
 * @interface AddFlagIssueDetails
 */
export interface AddFlagIssueDetails {
    /**
     * 
     * @type {string}
     * @memberof AddFlagIssueDetails
     */
    adBlocker?: string;
    /**
     * 
     * @type {string}
     * @memberof AddFlagIssueDetails
     */
    browser?: string;
    /**
     * 
     * @type {string}
     * @memberof AddFlagIssueDetails
     */
    device?: string;
    /**
     * 
     * @type {string}
     * @memberof AddFlagIssueDetails
     */
    problems?: string;
}
/**
 * 
 * @export
 * @interface CreateComment
 */
export interface CreateComment {
    /**
     * 
     * @type {string}
     * @memberof CreateComment
     */
    comment: string;
}
/**
 * 
 * @export
 * @interface CreateMessage
 */
export interface CreateMessage {
    /**
     * 
     * @type {string}
     * @memberof CreateMessage
     */
    message: string;
}
/**
 * 
 * @export
 * @interface CreateRating
 */
export interface CreateRating {
    /**
     * 
     * @type {number}
     * @memberof CreateRating
     */
    score: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateRating
     */
    shared?: boolean;
}
/**
 * 
 * @export
 * @interface CreateSubmission
 */
export interface CreateSubmission {
    /**
     * 
     * @type {string}
     * @memberof CreateSubmission
     */
    link: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSubmission
     */
    title: string;
}
/**
 * 
 * @export
 * @interface DbmodelsBannerItem
 */
export interface DbmodelsBannerItem {
    /**
     * 
     * @type {string}
     * @memberof DbmodelsBannerItem
     */
    createTime?: string;
    /**
     * 
     * @type {string}
     * @memberof DbmodelsBannerItem
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof DbmodelsBannerItem
     */
    unid?: string;
    /**
     * 
     * @type {string}
     * @memberof DbmodelsBannerItem
     */
    url?: string;
}
/**
 * 
 * @export
 * @interface DbmodelsContest
 */
export interface DbmodelsContest {
    /**
     * 
     * @type {string}
     * @memberof DbmodelsContest
     */
    createTime?: string;
    /**
     * 
     * @type {string}
     * @memberof DbmodelsContest
     */
    endTime?: string;
    /**
     * 
     * @type {string}
     * @memberof DbmodelsContest
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof DbmodelsContest
     */
    startTime?: string;
    /**
     * 
     * @type {string}
     * @memberof DbmodelsContest
     */
    submitDeadline?: string;
    /**
     * 
     * @type {string}
     * @memberof DbmodelsContest
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof DbmodelsContest
     */
    unid?: string;
    /**
     * 
     * @type {string}
     * @memberof DbmodelsContest
     */
    updateTime?: string;
}
/**
 * 
 * @export
 * @interface DbmodelsConversationMessage
 */
export interface DbmodelsConversationMessage {
    /**
     * 
     * @type {string}
     * @memberof DbmodelsConversationMessage
     */
    createTime?: string;
    /**
     * 
     * @type {string}
     * @memberof DbmodelsConversationMessage
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof DbmodelsConversationMessage
     */
    readTime?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DbmodelsConversationMessage
     */
    unid?: string;
}
/**
 * 
 * @export
 * @interface DbmodelsRating
 */
export interface DbmodelsRating {
    /**
     * 
     * @type {string}
     * @memberof DbmodelsRating
     */
    createTime?: string;
    /**
     * 
     * @type {number}
     * @memberof DbmodelsRating
     */
    score?: number;
    /**
     * 
     * @type {string}
     * @memberof DbmodelsRating
     */
    unid?: string;
    /**
     * 
     * @type {string}
     * @memberof DbmodelsRating
     */
    updateTime?: string;
}
/**
 * 
 * @export
 * @interface DbmodelsSubmission
 */
export interface DbmodelsSubmission {
    /**
     * 
     * @type {string}
     * @memberof DbmodelsSubmission
     */
    closedTime?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DbmodelsSubmission
     */
    createTime?: string;
    /**
     * 
     * @type {string}
     * @memberof DbmodelsSubmission
     */
    inProgressTime?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DbmodelsSubmission
     */
    link?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DbmodelsSubmission
     */
    optInContests?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DbmodelsSubmission
     */
    problem?: string;
    /**
     * 
     * @type {string}
     * @memberof DbmodelsSubmission
     */
    shareLink?: string;
    /**
     * 
     * @type {string}
     * @memberof DbmodelsSubmission
     */
    source?: string;
    /**
     * 
     * @type {number}
     * @memberof DbmodelsSubmission
     */
    status?: number;
    /**
     * 
     * @type {string}
     * @memberof DbmodelsSubmission
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof DbmodelsSubmission
     */
    unid?: string;
    /**
     * 
     * @type {string}
     * @memberof DbmodelsSubmission
     */
    updateTime?: string;
}
/**
 * 
 * @export
 * @interface DbmodelsSubmissionDetailed
 */
export interface DbmodelsSubmissionDetailed {
    /**
     * 
     * @type {string}
     * @memberof DbmodelsSubmissionDetailed
     */
    artist?: string;
    /**
     * 
     * @type {string}
     * @memberof DbmodelsSubmissionDetailed
     */
    closedTime?: string | null;
    /**
     * 
     * @type {DbmodelsConversationMessage}
     * @memberof DbmodelsSubmissionDetailed
     */
    comment?: DbmodelsConversationMessage;
    /**
     * 
     * @type {string}
     * @memberof DbmodelsSubmissionDetailed
     */
    createTime?: string;
    /**
     * 
     * @type {string}
     * @memberof DbmodelsSubmissionDetailed
     */
    inProgressTime?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DbmodelsSubmissionDetailed
     */
    link?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DbmodelsSubmissionDetailed
     */
    optInContests?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DbmodelsSubmissionDetailed
     */
    problem?: string;
    /**
     * 
     * @type {DbmodelsRating}
     * @memberof DbmodelsSubmissionDetailed
     */
    rating?: DbmodelsRating;
    /**
     * 
     * @type {string}
     * @memberof DbmodelsSubmissionDetailed
     */
    shareLink?: string;
    /**
     * 
     * @type {string}
     * @memberof DbmodelsSubmissionDetailed
     */
    source?: string;
    /**
     * 
     * @type {number}
     * @memberof DbmodelsSubmissionDetailed
     */
    status?: number;
    /**
     * 
     * @type {string}
     * @memberof DbmodelsSubmissionDetailed
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof DbmodelsSubmissionDetailed
     */
    unid?: string;
    /**
     * 
     * @type {string}
     * @memberof DbmodelsSubmissionDetailed
     */
    updateTime?: string;
}
/**
 * 
 * @export
 * @interface DbmodelsSubmissionWithArtist
 */
export interface DbmodelsSubmissionWithArtist {
    /**
     * 
     * @type {string}
     * @memberof DbmodelsSubmissionWithArtist
     */
    artist?: string;
    /**
     * 
     * @type {string}
     * @memberof DbmodelsSubmissionWithArtist
     */
    closedTime?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DbmodelsSubmissionWithArtist
     */
    createTime?: string;
    /**
     * 
     * @type {string}
     * @memberof DbmodelsSubmissionWithArtist
     */
    inProgressTime?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DbmodelsSubmissionWithArtist
     */
    link?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DbmodelsSubmissionWithArtist
     */
    optInContests?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DbmodelsSubmissionWithArtist
     */
    problem?: string;
    /**
     * 
     * @type {string}
     * @memberof DbmodelsSubmissionWithArtist
     */
    shareLink?: string;
    /**
     * 
     * @type {string}
     * @memberof DbmodelsSubmissionWithArtist
     */
    source?: string;
    /**
     * 
     * @type {number}
     * @memberof DbmodelsSubmissionWithArtist
     */
    status?: number;
    /**
     * 
     * @type {string}
     * @memberof DbmodelsSubmissionWithArtist
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof DbmodelsSubmissionWithArtist
     */
    unid?: string;
    /**
     * 
     * @type {string}
     * @memberof DbmodelsSubmissionWithArtist
     */
    updateTime?: string;
}
/**
 * 
 * @export
 * @interface DbmodelsUser
 */
export interface DbmodelsUser {
    /**
     * 
     * @type {boolean}
     * @memberof DbmodelsUser
     */
    acceptsMarketing?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DbmodelsUser
     */
    becameArtistAt?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DbmodelsUser
     */
    contactEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DbmodelsUser
     */
    contactFirstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DbmodelsUser
     */
    contactLastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DbmodelsUser
     */
    contactZip?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DbmodelsUser
     */
    createTime?: string;
    /**
     * 
     * @type {string}
     * @memberof DbmodelsUser
     */
    email?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DbmodelsUser
     */
    emailConfirmed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DbmodelsUser
     */
    emailInvalid?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DbmodelsUser
     */
    flagBanned?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DbmodelsUser
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof DbmodelsUser
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DbmodelsUser
     */
    profilePicture?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DbmodelsUser
     */
    promptedPhoneNumber?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DbmodelsUser
     */
    role?: number;
    /**
     * 
     * @type {string}
     * @memberof DbmodelsUser
     */
    unid?: string;
    /**
     * 
     * @type {string}
     * @memberof DbmodelsUser
     */
    updateTime?: string;
}
/**
 * 
 * @export
 * @interface EditContest
 */
export interface EditContest {
    /**
     * 
     * @type {string}
     * @memberof EditContest
     */
    endTime: string;
    /**
     * 
     * @type {string}
     * @memberof EditContest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof EditContest
     */
    startTime: string;
    /**
     * 
     * @type {string}
     * @memberof EditContest
     */
    submitDeadline: string;
    /**
     * 
     * @type {string}
     * @memberof EditContest
     */
    type: string;
}
/**
 * 
 * @export
 * @interface EditOptIns
 */
export interface EditOptIns {
    /**
     * 
     * @type {boolean}
     * @memberof EditOptIns
     */
    acceptsMarketing?: boolean;
    /**
     * 
     * @type {RequestsContactInfo}
     * @memberof EditOptIns
     */
    contactInfo?: RequestsContactInfo;
    /**
     * 
     * @type {boolean}
     * @memberof EditOptIns
     */
    enterContests?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EditOptIns
     */
    submissionUnid?: string;
}
/**
 * 
 * @export
 * @interface EditUser
 */
export interface EditUser {
    /**
     * 
     * @type {string}
     * @memberof EditUser
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EditUser
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof EditUser
     */
    password?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EditUser
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof EditUser
     */
    promptedPhoneNumber?: boolean | null;
}
/**
 * 
 * @export
 * @interface FBLoginAccount
 */
export interface FBLoginAccount {
    /**
     * 
     * @type {string}
     * @memberof FBLoginAccount
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FBLoginAccount
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof FBLoginAccount
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof FBLoginAccount
     */
    picture: string;
}
/**
 * 
 * @export
 * @interface ForgotPassword
 */
export interface ForgotPassword {
    /**
     * 
     * @type {string}
     * @memberof ForgotPassword
     */
    email: string;
}
/**
 * 
 * @export
 * @interface LoginAccount
 */
export interface LoginAccount {
    /**
     * 
     * @type {string}
     * @memberof LoginAccount
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof LoginAccount
     */
    password: string;
}
/**
 * 
 * @export
 * @interface LoginAccount1
 */
export interface LoginAccount1 {
    /**
     * 
     * @type {string}
     * @memberof LoginAccount1
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof LoginAccount1
     */
    password: string;
}
/**
 * 
 * @export
 * @interface MiscAdminCuratorLeaderboardEntry
 */
export interface MiscAdminCuratorLeaderboardEntry {
    /**
     * 
     * @type {string}
     * @memberof MiscAdminCuratorLeaderboardEntry
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof MiscAdminCuratorLeaderboardEntry
     */
    fbID?: string;
    /**
     * 
     * @type {string}
     * @memberof MiscAdminCuratorLeaderboardEntry
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof MiscAdminCuratorLeaderboardEntry
     */
    points?: number;
}
/**
 * 
 * @export
 * @interface MiscAdminTrackLeaderboardEntry
 */
export interface MiscAdminTrackLeaderboardEntry {
    /**
     * 
     * @type {string}
     * @memberof MiscAdminTrackLeaderboardEntry
     */
    artistEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof MiscAdminTrackLeaderboardEntry
     */
    artistFbID?: string;
    /**
     * 
     * @type {string}
     * @memberof MiscAdminTrackLeaderboardEntry
     */
    artistName?: string;
    /**
     * 
     * @type {number}
     * @memberof MiscAdminTrackLeaderboardEntry
     */
    points?: number;
    /**
     * 
     * @type {string}
     * @memberof MiscAdminTrackLeaderboardEntry
     */
    trackName?: string;
    /**
     * 
     * @type {string}
     * @memberof MiscAdminTrackLeaderboardEntry
     */
    trackUrl?: string;
}
/**
 * 
 * @export
 * @interface MiscArtistLeaderboardEntry
 */
export interface MiscArtistLeaderboardEntry {
    /**
     * 
     * @type {string}
     * @memberof MiscArtistLeaderboardEntry
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof MiscArtistLeaderboardEntry
     */
    points?: number;
    /**
     * 
     * @type {number}
     * @memberof MiscArtistLeaderboardEntry
     */
    userID?: number;
}
/**
 * 
 * @export
 * @interface MiscAvailableProductWithDiscount
 */
export interface MiscAvailableProductWithDiscount {
    /**
     * 
     * @type {boolean}
     * @memberof MiscAvailableProductWithDiscount
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MiscAvailableProductWithDiscount
     */
    createTime?: string;
    /**
     * 
     * @type {number}
     * @memberof MiscAvailableProductWithDiscount
     */
    discountedPrice?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MiscAvailableProductWithDiscount
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof MiscAvailableProductWithDiscount
     */
    price?: number;
    /**
     * 
     * @type {number}
     * @memberof MiscAvailableProductWithDiscount
     */
    ratings?: number;
    /**
     * 
     * @type {string}
     * @memberof MiscAvailableProductWithDiscount
     */
    unid?: string;
    /**
     * 
     * @type {string}
     * @memberof MiscAvailableProductWithDiscount
     */
    updateTime?: string;
}
/**
 * 
 * @export
 * @interface MiscCard
 */
export interface MiscCard {
    /**
     * 
     * @type {string}
     * @memberof MiscCard
     */
    addressCity: string;
    /**
     * 
     * @type {string}
     * @memberof MiscCard
     */
    addressCountry: string;
    /**
     * 
     * @type {string}
     * @memberof MiscCard
     */
    addressLine1: string;
    /**
     * 
     * @type {string}
     * @memberof MiscCard
     */
    addressLine2?: string;
    /**
     * 
     * @type {string}
     * @memberof MiscCard
     */
    addressState: string;
    /**
     * 
     * @type {string}
     * @memberof MiscCard
     */
    addressZip: string;
    /**
     * 
     * @type {string}
     * @memberof MiscCard
     */
    brand?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MiscCard
     */
    defaultPayment?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MiscCard
     */
    expMonth?: number;
    /**
     * 
     * @type {number}
     * @memberof MiscCard
     */
    expYear?: number;
    /**
     * 
     * @type {string}
     * @memberof MiscCard
     */
    last4?: string;
    /**
     * 
     * @type {string}
     * @memberof MiscCard
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof MiscCard
     */
    unid?: string;
}
/**
 * 
 * @export
 * @interface MiscConversation
 */
export interface MiscConversation {
    /**
     * 
     * @type {string}
     * @memberof MiscConversation
     */
    conversationUnid?: string;
    /**
     * 
     * @type {string}
     * @memberof MiscConversation
     */
    curatorName?: string;
    /**
     * 
     * @type {string}
     * @memberof MiscConversation
     */
    curatorPicture?: string;
    /**
     * 
     * @type {number}
     * @memberof MiscConversation
     */
    rating?: number;
    /**
     * 
     * @type {string}
     * @memberof MiscConversation
     */
    trackName?: string;
}
/**
 * 
 * @export
 * @interface MiscConversationMessage
 */
export interface MiscConversationMessage {
    /**
     * 
     * @type {string}
     * @memberof MiscConversationMessage
     */
    message?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MiscConversationMessage
     */
    myMessage?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MiscConversationMessage
     */
    read?: boolean;
}
/**
 * 
 * @export
 * @interface MiscCuratorLeaderboardEntry
 */
export interface MiscCuratorLeaderboardEntry {
    /**
     * 
     * @type {string}
     * @memberof MiscCuratorLeaderboardEntry
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof MiscCuratorLeaderboardEntry
     */
    points?: number;
    /**
     * 
     * @type {number}
     * @memberof MiscCuratorLeaderboardEntry
     */
    userID?: number;
}
/**
 * 
 * @export
 * @interface MiscFlagIssueDetails
 */
export interface MiscFlagIssueDetails {
    /**
     * 
     * @type {string}
     * @memberof MiscFlagIssueDetails
     */
    adBlocker?: string;
    /**
     * 
     * @type {string}
     * @memberof MiscFlagIssueDetails
     */
    browser?: string;
    /**
     * 
     * @type {string}
     * @memberof MiscFlagIssueDetails
     */
    createTime?: string;
    /**
     * 
     * @type {string}
     * @memberof MiscFlagIssueDetails
     */
    device?: string;
    /**
     * 
     * @type {number}
     * @memberof MiscFlagIssueDetails
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof MiscFlagIssueDetails
     */
    problems?: string;
    /**
     * 
     * @type {string}
     * @memberof MiscFlagIssueDetails
     */
    userEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof MiscFlagIssueDetails
     */
    userName?: string;
    /**
     * 
     * @type {string}
     * @memberof MiscFlagIssueDetails
     */
    userUnid?: string;
}
/**
 * 
 * @export
 * @interface MiscProblemSubmission
 */
export interface MiscProblemSubmission {
    /**
     * 
     * @type {number}
     * @memberof MiscProblemSubmission
     */
    flagCount?: number;
    /**
     * 
     * @type {string}
     * @memberof MiscProblemSubmission
     */
    submissionLink?: string;
    /**
     * 
     * @type {string}
     * @memberof MiscProblemSubmission
     */
    submissionProblem?: string;
    /**
     * 
     * @type {number}
     * @memberof MiscProblemSubmission
     */
    submissionStatus?: number;
    /**
     * 
     * @type {string}
     * @memberof MiscProblemSubmission
     */
    submissionTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof MiscProblemSubmission
     */
    submissionUnid?: string;
}
/**
 * 
 * @export
 * @interface MiscRatingResult
 */
export interface MiscRatingResult {
    /**
     * 
     * @type {string}
     * @memberof MiscRatingResult
     */
    comment?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MiscRatingResult
     */
    conversationUnid?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MiscRatingResult
     */
    curatorName?: string;
    /**
     * 
     * @type {string}
     * @memberof MiscRatingResult
     */
    curatorProfilePicture?: string;
    /**
     * 
     * @type {string}
     * @memberof MiscRatingResult
     */
    rateTime?: string;
    /**
     * 
     * @type {number}
     * @memberof MiscRatingResult
     */
    rating?: number;
}
/**
 * 
 * @export
 * @interface MiscSubmissionResults
 */
export interface MiscSubmissionResults {
    /**
     * 
     * @type {Array<MiscRatingResult>}
     * @memberof MiscSubmissionResults
     */
    ratingsResult?: Array<MiscRatingResult>;
}
/**
 * 
 * @export
 * @interface MiscTrackLeaderboardEntry
 */
export interface MiscTrackLeaderboardEntry {
    /**
     * 
     * @type {string}
     * @memberof MiscTrackLeaderboardEntry
     */
    artistName?: string;
    /**
     * 
     * @type {number}
     * @memberof MiscTrackLeaderboardEntry
     */
    points?: number;
    /**
     * 
     * @type {string}
     * @memberof MiscTrackLeaderboardEntry
     */
    submissionUnid?: string;
    /**
     * 
     * @type {string}
     * @memberof MiscTrackLeaderboardEntry
     */
    trackName?: string;
}
/**
 * 
 * @export
 * @interface MiscUserBadge
 */
export interface MiscUserBadge {
    /**
     * 
     * @type {string}
     * @memberof MiscUserBadge
     */
    badgeName?: string;
    /**
     * 
     * @type {string}
     * @memberof MiscUserBadge
     */
    status?: string;
    /**
     * 
     * @type {number}
     * @memberof MiscUserBadge
     */
    statusProgression?: number;
    /**
     * 
     * @type {number}
     * @memberof MiscUserBadge
     */
    timesEarned?: number;
}
/**
 * 
 * @export
 * @interface PaymentsNewCard
 */
export interface PaymentsNewCard {
    /**
     * 
     * @type {boolean}
     * @memberof PaymentsNewCard
     */
    save?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PaymentsNewCard
     */
    tokenID?: string;
}
/**
 * 
 * @export
 * @interface RequestsContactInfo
 */
export interface RequestsContactInfo {
    /**
     * 
     * @type {string}
     * @memberof RequestsContactInfo
     */
    contactEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestsContactInfo
     */
    contactFirstName?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestsContactInfo
     */
    contactLastName?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestsContactInfo
     */
    contactZip?: string;
}
/**
 * 
 * @export
 * @interface ResetPassword
 */
export interface ResetPassword {
    /**
     * 
     * @type {string}
     * @memberof ResetPassword
     */
    password: string;
}
/**
 * 
 * @export
 * @interface ResponsesAdminCuratorLeaderboardResponse
 */
export interface ResponsesAdminCuratorLeaderboardResponse {
    /**
     * 
     * @type {number}
     * @memberof ResponsesAdminCuratorLeaderboardResponse
     */
    api_version: number;
    /**
     * 
     * @type {ResponsesAdminCuratorLeaderboardResponseData}
     * @memberof ResponsesAdminCuratorLeaderboardResponse
     */
    data: ResponsesAdminCuratorLeaderboardResponseData;
}
/**
 * 
 * @export
 * @interface ResponsesAdminCuratorLeaderboardResponseData
 */
export interface ResponsesAdminCuratorLeaderboardResponseData {
    /**
     * 
     * @type {Array<MiscAdminCuratorLeaderboardEntry>}
     * @memberof ResponsesAdminCuratorLeaderboardResponseData
     */
    curatorLeaderboard: Array<MiscAdminCuratorLeaderboardEntry>;
}
/**
 * 
 * @export
 * @interface ResponsesAdminFlagIssueDetailsResponse
 */
export interface ResponsesAdminFlagIssueDetailsResponse {
    /**
     * 
     * @type {number}
     * @memberof ResponsesAdminFlagIssueDetailsResponse
     */
    api_version: number;
    /**
     * 
     * @type {ResponsesAdminFlagIssueDetailsResponseData}
     * @memberof ResponsesAdminFlagIssueDetailsResponse
     */
    data: ResponsesAdminFlagIssueDetailsResponseData;
}
/**
 * 
 * @export
 * @interface ResponsesAdminFlagIssueDetailsResponseData
 */
export interface ResponsesAdminFlagIssueDetailsResponseData {
    /**
     * 
     * @type {Array<MiscFlagIssueDetails>}
     * @memberof ResponsesAdminFlagIssueDetailsResponseData
     */
    details: Array<MiscFlagIssueDetails>;
}
/**
 * 
 * @export
 * @interface ResponsesAdminTrackLeaderboardResponse
 */
export interface ResponsesAdminTrackLeaderboardResponse {
    /**
     * 
     * @type {number}
     * @memberof ResponsesAdminTrackLeaderboardResponse
     */
    api_version: number;
    /**
     * 
     * @type {ResponsesAdminTrackLeaderboardResponseData}
     * @memberof ResponsesAdminTrackLeaderboardResponse
     */
    data: ResponsesAdminTrackLeaderboardResponseData;
}
/**
 * 
 * @export
 * @interface ResponsesAdminTrackLeaderboardResponseData
 */
export interface ResponsesAdminTrackLeaderboardResponseData {
    /**
     * 
     * @type {Array<MiscAdminTrackLeaderboardEntry>}
     * @memberof ResponsesAdminTrackLeaderboardResponseData
     */
    trackLeaderboard: Array<MiscAdminTrackLeaderboardEntry>;
}
/**
 * 
 * @export
 * @interface ResponsesAppErrorResponse
 */
export interface ResponsesAppErrorResponse {
    /**
     * 
     * @type {number}
     * @memberof ResponsesAppErrorResponse
     */
    api_version: number;
    /**
     * 
     * @type {number}
     * @memberof ResponsesAppErrorResponse
     */
    code: number;
    /**
     * 
     * @type {string}
     * @memberof ResponsesAppErrorResponse
     */
    message: string;
}
/**
 * 
 * @export
 * @interface ResponsesArtistLeaderboardResponse
 */
export interface ResponsesArtistLeaderboardResponse {
    /**
     * 
     * @type {number}
     * @memberof ResponsesArtistLeaderboardResponse
     */
    api_version: number;
    /**
     * 
     * @type {ResponsesArtistLeaderboardResponseData}
     * @memberof ResponsesArtistLeaderboardResponse
     */
    data: ResponsesArtistLeaderboardResponseData;
}
/**
 * 
 * @export
 * @interface ResponsesArtistLeaderboardResponseData
 */
export interface ResponsesArtistLeaderboardResponseData {
    /**
     * 
     * @type {Array<MiscArtistLeaderboardEntry>}
     * @memberof ResponsesArtistLeaderboardResponseData
     */
    artistLeaderboard: Array<MiscArtistLeaderboardEntry>;
}
/**
 * 
 * @export
 * @interface ResponsesAvailableProductsResponse
 */
export interface ResponsesAvailableProductsResponse {
    /**
     * 
     * @type {number}
     * @memberof ResponsesAvailableProductsResponse
     */
    api_version: number;
    /**
     * 
     * @type {ResponsesAvailableProductsResponseData}
     * @memberof ResponsesAvailableProductsResponse
     */
    data: ResponsesAvailableProductsResponseData;
}
/**
 * 
 * @export
 * @interface ResponsesAvailableProductsResponseData
 */
export interface ResponsesAvailableProductsResponseData {
    /**
     * 
     * @type {Array<MiscAvailableProductWithDiscount>}
     * @memberof ResponsesAvailableProductsResponseData
     */
    availableProducts: Array<MiscAvailableProductWithDiscount>;
}
/**
 * 
 * @export
 * @interface ResponsesBannerResponse
 */
export interface ResponsesBannerResponse {
    /**
     * 
     * @type {number}
     * @memberof ResponsesBannerResponse
     */
    api_version: number;
    /**
     * 
     * @type {ResponsesBannerResponseData}
     * @memberof ResponsesBannerResponse
     */
    data: ResponsesBannerResponseData;
}
/**
 * 
 * @export
 * @interface ResponsesBannerResponseData
 */
export interface ResponsesBannerResponseData {
    /**
     * 
     * @type {Array<DbmodelsBannerItem>}
     * @memberof ResponsesBannerResponseData
     */
    banner: Array<DbmodelsBannerItem>;
}
/**
 * 
 * @export
 * @interface ResponsesBaseResponse
 */
export interface ResponsesBaseResponse {
    /**
     * 
     * @type {ResponsesBaseResponseBody}
     * @memberof ResponsesBaseResponse
     */
    Body?: ResponsesBaseResponseBody;
}
/**
 * 
 * @export
 * @interface ResponsesBaseResponseBody
 */
export interface ResponsesBaseResponseBody {
    /**
     * 
     * @type {number}
     * @memberof ResponsesBaseResponseBody
     */
    api_version: number;
}
/**
 * 
 * @export
 * @interface ResponsesCardsResponse
 */
export interface ResponsesCardsResponse {
    /**
     * 
     * @type {number}
     * @memberof ResponsesCardsResponse
     */
    api_version: number;
    /**
     * 
     * @type {ResponsesCardsResponseData}
     * @memberof ResponsesCardsResponse
     */
    data: ResponsesCardsResponseData;
}
/**
 * 
 * @export
 * @interface ResponsesCardsResponseData
 */
export interface ResponsesCardsResponseData {
    /**
     * 
     * @type {Array<MiscCard>}
     * @memberof ResponsesCardsResponseData
     */
    cards: Array<MiscCard>;
}
/**
 * 
 * @export
 * @interface ResponsesCommentResponse
 */
export interface ResponsesCommentResponse {
    /**
     * 
     * @type {number}
     * @memberof ResponsesCommentResponse
     */
    api_version: number;
    /**
     * 
     * @type {ResponsesCommentResponseData}
     * @memberof ResponsesCommentResponse
     */
    data: ResponsesCommentResponseData;
}
/**
 * 
 * @export
 * @interface ResponsesCommentResponseData
 */
export interface ResponsesCommentResponseData {
    /**
     * 
     * @type {DbmodelsConversationMessage}
     * @memberof ResponsesCommentResponseData
     */
    comment: DbmodelsConversationMessage;
}
/**
 * 
 * @export
 * @interface ResponsesContestForDisplayResponse
 */
export interface ResponsesContestForDisplayResponse {
    /**
     * 
     * @type {number}
     * @memberof ResponsesContestForDisplayResponse
     */
    api_version: number;
    /**
     * 
     * @type {ResponsesContestForDisplayResponseData}
     * @memberof ResponsesContestForDisplayResponse
     */
    data: ResponsesContestForDisplayResponseData;
}
/**
 * 
 * @export
 * @interface ResponsesContestForDisplayResponseData
 */
export interface ResponsesContestForDisplayResponseData {
    /**
     * 
     * @type {DbmodelsContest}
     * @memberof ResponsesContestForDisplayResponseData
     */
    current: DbmodelsContest;
    /**
     * 
     * @type {DbmodelsContest}
     * @memberof ResponsesContestForDisplayResponseData
     */
    upcoming: DbmodelsContest;
}
/**
 * 
 * @export
 * @interface ResponsesContestResponse
 */
export interface ResponsesContestResponse {
    /**
     * 
     * @type {number}
     * @memberof ResponsesContestResponse
     */
    api_version: number;
    /**
     * 
     * @type {ResponsesContestResponseData}
     * @memberof ResponsesContestResponse
     */
    data: ResponsesContestResponseData;
}
/**
 * 
 * @export
 * @interface ResponsesContestResponseData
 */
export interface ResponsesContestResponseData {
    /**
     * 
     * @type {DbmodelsContest}
     * @memberof ResponsesContestResponseData
     */
    contest: DbmodelsContest;
}
/**
 * 
 * @export
 * @interface ResponsesContestsResponse
 */
export interface ResponsesContestsResponse {
    /**
     * 
     * @type {number}
     * @memberof ResponsesContestsResponse
     */
    api_version: number;
    /**
     * 
     * @type {ResponsesContestsResponseData}
     * @memberof ResponsesContestsResponse
     */
    data: ResponsesContestsResponseData;
}
/**
 * 
 * @export
 * @interface ResponsesContestsResponseData
 */
export interface ResponsesContestsResponseData {
    /**
     * 
     * @type {Array<DbmodelsContest>}
     * @memberof ResponsesContestsResponseData
     */
    contests: Array<DbmodelsContest>;
}
/**
 * 
 * @export
 * @interface ResponsesConversationResponse
 */
export interface ResponsesConversationResponse {
    /**
     * 
     * @type {number}
     * @memberof ResponsesConversationResponse
     */
    api_version: number;
    /**
     * 
     * @type {ResponsesConversationResponseData}
     * @memberof ResponsesConversationResponse
     */
    data: ResponsesConversationResponseData;
}
/**
 * 
 * @export
 * @interface ResponsesConversationResponseData
 */
export interface ResponsesConversationResponseData {
    /**
     * 
     * @type {Array<MiscConversationMessage>}
     * @memberof ResponsesConversationResponseData
     */
    messages: Array<MiscConversationMessage>;
}
/**
 * 
 * @export
 * @interface ResponsesConversationsResponse
 */
export interface ResponsesConversationsResponse {
    /**
     * 
     * @type {number}
     * @memberof ResponsesConversationsResponse
     */
    api_version: number;
    /**
     * 
     * @type {ResponsesConversationsResponseData}
     * @memberof ResponsesConversationsResponse
     */
    data: ResponsesConversationsResponseData;
}
/**
 * 
 * @export
 * @interface ResponsesConversationsResponseData
 */
export interface ResponsesConversationsResponseData {
    /**
     * 
     * @type {Array<MiscConversation>}
     * @memberof ResponsesConversationsResponseData
     */
    conversations: Array<MiscConversation>;
}
/**
 * 
 * @export
 * @interface ResponsesCuratorLeaderboardResponse
 */
export interface ResponsesCuratorLeaderboardResponse {
    /**
     * 
     * @type {number}
     * @memberof ResponsesCuratorLeaderboardResponse
     */
    api_version: number;
    /**
     * 
     * @type {ResponsesCuratorLeaderboardResponseData}
     * @memberof ResponsesCuratorLeaderboardResponse
     */
    data: ResponsesCuratorLeaderboardResponseData;
}
/**
 * 
 * @export
 * @interface ResponsesCuratorLeaderboardResponseData
 */
export interface ResponsesCuratorLeaderboardResponseData {
    /**
     * 
     * @type {Array<MiscCuratorLeaderboardEntry>}
     * @memberof ResponsesCuratorLeaderboardResponseData
     */
    curatorLeaderboard: Array<MiscCuratorLeaderboardEntry>;
}
/**
 * 
 * @export
 * @interface ResponsesDetailedSubmissionsResponse
 */
export interface ResponsesDetailedSubmissionsResponse {
    /**
     * 
     * @type {number}
     * @memberof ResponsesDetailedSubmissionsResponse
     */
    api_version: number;
    /**
     * 
     * @type {ResponsesDetailedSubmissionsResponseData}
     * @memberof ResponsesDetailedSubmissionsResponse
     */
    data: ResponsesDetailedSubmissionsResponseData;
}
/**
 * 
 * @export
 * @interface ResponsesDetailedSubmissionsResponseData
 */
export interface ResponsesDetailedSubmissionsResponseData {
    /**
     * 
     * @type {Array<DbmodelsSubmissionDetailed>}
     * @memberof ResponsesDetailedSubmissionsResponseData
     */
    submissions: Array<DbmodelsSubmissionDetailed>;
}
/**
 * 
 * @export
 * @interface ResponsesFlaggedSubmissionResponse
 */
export interface ResponsesFlaggedSubmissionResponse {
    /**
     * 
     * @type {number}
     * @memberof ResponsesFlaggedSubmissionResponse
     */
    api_version: number;
    /**
     * 
     * @type {ResponsesFlaggedSubmissionResponseData}
     * @memberof ResponsesFlaggedSubmissionResponse
     */
    data: ResponsesFlaggedSubmissionResponseData;
}
/**
 * 
 * @export
 * @interface ResponsesFlaggedSubmissionResponseData
 */
export interface ResponsesFlaggedSubmissionResponseData {
    /**
     * 
     * @type {string}
     * @memberof ResponsesFlaggedSubmissionResponseData
     */
    status: string | null;
}
/**
 * 
 * @export
 * @interface ResponsesLoginResponse
 */
export interface ResponsesLoginResponse {
    /**
     * 
     * @type {number}
     * @memberof ResponsesLoginResponse
     */
    api_version: number;
    /**
     * 
     * @type {ResponsesLoginResponseData}
     * @memberof ResponsesLoginResponse
     */
    data: ResponsesLoginResponseData;
}
/**
 * 
 * @export
 * @interface ResponsesLoginResponseData
 */
export interface ResponsesLoginResponseData {
    /**
     * 
     * @type {string}
     * @memberof ResponsesLoginResponseData
     */
    token: string;
    /**
     * 
     * @type {DbmodelsUser}
     * @memberof ResponsesLoginResponseData
     */
    user: DbmodelsUser;
}
/**
 * 
 * @export
 * @interface ResponsesPaymentResponse
 */
export interface ResponsesPaymentResponse {
    /**
     * 
     * @type {number}
     * @memberof ResponsesPaymentResponse
     */
    api_version: number;
    /**
     * 
     * @type {ResponsesPaymentResponseData}
     * @memberof ResponsesPaymentResponse
     */
    data: ResponsesPaymentResponseData;
}
/**
 * 
 * @export
 * @interface ResponsesPaymentResponseData
 */
export interface ResponsesPaymentResponseData {
    /**
     * 
     * @type {number}
     * @memberof ResponsesPaymentResponseData
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof ResponsesPaymentResponseData
     */
    transactionID: string;
}
/**
 * 
 * @export
 * @interface ResponsesProblemSubmissionsResponse
 */
export interface ResponsesProblemSubmissionsResponse {
    /**
     * 
     * @type {number}
     * @memberof ResponsesProblemSubmissionsResponse
     */
    api_version: number;
    /**
     * 
     * @type {ResponsesProblemSubmissionsResponseData}
     * @memberof ResponsesProblemSubmissionsResponse
     */
    data: ResponsesProblemSubmissionsResponseData;
}
/**
 * 
 * @export
 * @interface ResponsesProblemSubmissionsResponseData
 */
export interface ResponsesProblemSubmissionsResponseData {
    /**
     * 
     * @type {Array<MiscProblemSubmission>}
     * @memberof ResponsesProblemSubmissionsResponseData
     */
    submissions: Array<MiscProblemSubmission>;
}
/**
 * 
 * @export
 * @interface ResponsesRatingResponse
 */
export interface ResponsesRatingResponse {
    /**
     * 
     * @type {number}
     * @memberof ResponsesRatingResponse
     */
    api_version: number;
    /**
     * 
     * @type {ResponsesRatingResponseData}
     * @memberof ResponsesRatingResponse
     */
    data: ResponsesRatingResponseData;
}
/**
 * 
 * @export
 * @interface ResponsesRatingResponseData
 */
export interface ResponsesRatingResponseData {
    /**
     * 
     * @type {DbmodelsRating}
     * @memberof ResponsesRatingResponseData
     */
    rating: DbmodelsRating;
}
/**
 * 
 * @export
 * @interface ResponsesSubmissionResponse
 */
export interface ResponsesSubmissionResponse {
    /**
     * 
     * @type {number}
     * @memberof ResponsesSubmissionResponse
     */
    api_version: number;
    /**
     * 
     * @type {ResponsesSubmissionResponseData}
     * @memberof ResponsesSubmissionResponse
     */
    data: ResponsesSubmissionResponseData;
}
/**
 * 
 * @export
 * @interface ResponsesSubmissionResponseData
 */
export interface ResponsesSubmissionResponseData {
    /**
     * 
     * @type {DbmodelsSubmission}
     * @memberof ResponsesSubmissionResponseData
     */
    submission: DbmodelsSubmission;
}
/**
 * 
 * @export
 * @interface ResponsesSubmissionResultsResponse
 */
export interface ResponsesSubmissionResultsResponse {
    /**
     * 
     * @type {number}
     * @memberof ResponsesSubmissionResultsResponse
     */
    api_version: number;
    /**
     * 
     * @type {ResponsesSubmissionResultsResponseData}
     * @memberof ResponsesSubmissionResultsResponse
     */
    data: ResponsesSubmissionResultsResponseData;
}
/**
 * 
 * @export
 * @interface ResponsesSubmissionResultsResponseData
 */
export interface ResponsesSubmissionResultsResponseData {
    /**
     * 
     * @type {MiscSubmissionResults}
     * @memberof ResponsesSubmissionResultsResponseData
     */
    submissionResults: MiscSubmissionResults;
}
/**
 * 
 * @export
 * @interface ResponsesSubmissionWithArtistAndRatingResponse
 */
export interface ResponsesSubmissionWithArtistAndRatingResponse {
    /**
     * 
     * @type {number}
     * @memberof ResponsesSubmissionWithArtistAndRatingResponse
     */
    api_version: number;
    /**
     * 
     * @type {ResponsesSubmissionWithArtistAndRatingResponseData}
     * @memberof ResponsesSubmissionWithArtistAndRatingResponse
     */
    data: ResponsesSubmissionWithArtistAndRatingResponseData;
}
/**
 * 
 * @export
 * @interface ResponsesSubmissionWithArtistAndRatingResponseData
 */
export interface ResponsesSubmissionWithArtistAndRatingResponseData {
    /**
     * 
     * @type {DbmodelsSubmissionWithArtist}
     * @memberof ResponsesSubmissionWithArtistAndRatingResponseData
     */
    submission: DbmodelsSubmissionWithArtist;
}
/**
 * 
 * @export
 * @interface ResponsesSubmissionsResponse
 */
export interface ResponsesSubmissionsResponse {
    /**
     * 
     * @type {number}
     * @memberof ResponsesSubmissionsResponse
     */
    api_version: number;
    /**
     * 
     * @type {ResponsesSubmissionsResponseData}
     * @memberof ResponsesSubmissionsResponse
     */
    data: ResponsesSubmissionsResponseData;
}
/**
 * 
 * @export
 * @interface ResponsesSubmissionsResponseData
 */
export interface ResponsesSubmissionsResponseData {
    /**
     * 
     * @type {Array<DbmodelsSubmission>}
     * @memberof ResponsesSubmissionsResponseData
     */
    submissions: Array<DbmodelsSubmission>;
}
/**
 * 
 * @export
 * @interface ResponsesTrackLeaderboardResponse
 */
export interface ResponsesTrackLeaderboardResponse {
    /**
     * 
     * @type {number}
     * @memberof ResponsesTrackLeaderboardResponse
     */
    api_version: number;
    /**
     * 
     * @type {ResponsesTrackLeaderboardResponseData}
     * @memberof ResponsesTrackLeaderboardResponse
     */
    data: ResponsesTrackLeaderboardResponseData;
}
/**
 * 
 * @export
 * @interface ResponsesTrackLeaderboardResponseData
 */
export interface ResponsesTrackLeaderboardResponseData {
    /**
     * 
     * @type {Array<MiscTrackLeaderboardEntry>}
     * @memberof ResponsesTrackLeaderboardResponseData
     */
    trackLeaderboard: Array<MiscTrackLeaderboardEntry>;
}
/**
 * 
 * @export
 * @interface ResponsesUserProfileResponse
 */
export interface ResponsesUserProfileResponse {
    /**
     * 
     * @type {number}
     * @memberof ResponsesUserProfileResponse
     */
    api_version: number;
    /**
     * 
     * @type {ResponsesUserProfileResponseData}
     * @memberof ResponsesUserProfileResponse
     */
    data: ResponsesUserProfileResponseData;
}
/**
 * 
 * @export
 * @interface ResponsesUserProfileResponseData
 */
export interface ResponsesUserProfileResponseData {
    /**
     * 
     * @type {number}
     * @memberof ResponsesUserProfileResponseData
     */
    artistPoints: number | null;
    /**
     * 
     * @type {Array<MiscUserBadge>}
     * @memberof ResponsesUserProfileResponseData
     */
    badges: Array<MiscUserBadge>;
    /**
     * 
     * @type {number}
     * @memberof ResponsesUserProfileResponseData
     */
    curatorPoints: number;
    /**
     * 
     * @type {boolean}
     * @memberof ResponsesUserProfileResponseData
     */
    fbLogin: boolean;
    /**
     * 
     * @type {string}
     * @memberof ResponsesUserProfileResponseData
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ResponsesUserProfileResponseData
     */
    picture: string;
    /**
     * 
     * @type {number}
     * @memberof ResponsesUserProfileResponseData
     */
    submissions: number;
}
/**
 * 
 * @export
 * @interface ResponsesUserResponse
 */
export interface ResponsesUserResponse {
    /**
     * 
     * @type {number}
     * @memberof ResponsesUserResponse
     */
    api_version: number;
    /**
     * 
     * @type {ResponsesUserResponseData}
     * @memberof ResponsesUserResponse
     */
    data: ResponsesUserResponseData;
}
/**
 * 
 * @export
 * @interface ResponsesUserResponseData
 */
export interface ResponsesUserResponseData {
    /**
     * 
     * @type {DbmodelsUser}
     * @memberof ResponsesUserResponseData
     */
    user: DbmodelsUser;
}
/**
 * 
 * @export
 * @interface SaveProblemSubmission
 */
export interface SaveProblemSubmission {
    /**
     * 
     * @type {string}
     * @memberof SaveProblemSubmission
     */
    link: string;
    /**
     * 
     * @type {string}
     * @memberof SaveProblemSubmission
     */
    problem?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SaveProblemSubmission
     */
    removeFlags?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SaveProblemSubmission
     */
    status?: number;
}
/**
 * 
 * @export
 * @interface SignUpAccount
 */
export interface SignUpAccount {
    /**
     * 
     * @type {boolean}
     * @memberof SignUpAccount
     */
    acceptsMarketing?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SignUpAccount
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof SignUpAccount
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SignUpAccount
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof SignUpAccount
     */
    phoneNumber?: string | null;
}
/**
 * 
 * @export
 * @interface SubmissionPayment
 */
export interface SubmissionPayment {
    /**
     * 
     * @type {string}
     * @memberof SubmissionPayment
     */
    cardID?: string;
    /**
     * 
     * @type {string}
     * @memberof SubmissionPayment
     */
    couponCode?: string;
    /**
     * 
     * @type {PaymentsNewCard}
     * @memberof SubmissionPayment
     */
    newCard?: PaymentsNewCard;
    /**
     * 
     * @type {string}
     * @memberof SubmissionPayment
     */
    productUnid?: string;
    /**
     * 
     * @type {string}
     * @memberof SubmissionPayment
     */
    submissionUnid?: string;
}

/**
 * AccountsApi - axios parameter creator
 * @export
 */
export const AccountsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _14f55b53Abee4b8eA64aE840e7d9ee01(options: any = {}): RequestArgs {
            const localVarPath = `/accounts/signup`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _20a4acf05f5c4d03A2001b10a5082542(options: any = {}): RequestArgs {
            const localVarPath = `/accounts/login`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _5239b5b0346b424aA8705a37db9fe392(options: any = {}): RequestArgs {
            const localVarPath = `/accounts/forgotPassword`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ac19f1461e3b47ba95deD5df5ba0c003(options: any = {}): RequestArgs {
            const localVarPath = `/accounts/resetPassword`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bd10a4d8Bfff460b87f5Bdac66e5d434(options: any = {}): RequestArgs {
            const localVarPath = `/accounts/fblogin`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FBLoginAccount} fBLoginAccount 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fBLogin(fBLoginAccount: FBLoginAccount, options: any = {}): RequestArgs {
            // verify required parameter 'fBLoginAccount' is not null or undefined
            if (fBLoginAccount === null || fBLoginAccount === undefined) {
                throw new RequiredError('fBLoginAccount','Required parameter fBLoginAccount was null or undefined when calling fBLogin.');
            }
            const localVarPath = `/accounts/fblogin`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof fBLoginAccount !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(fBLoginAccount !== undefined ? fBLoginAccount : {}) : (fBLoginAccount || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ForgotPassword} forgotPassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgotPassword(forgotPassword: ForgotPassword, options: any = {}): RequestArgs {
            // verify required parameter 'forgotPassword' is not null or undefined
            if (forgotPassword === null || forgotPassword === undefined) {
                throw new RequiredError('forgotPassword','Required parameter forgotPassword was null or undefined when calling forgotPassword.');
            }
            const localVarPath = `/accounts/forgotPassword`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof forgotPassword !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(forgotPassword !== undefined ? forgotPassword : {}) : (forgotPassword || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LoginAccount} loginAccount 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(loginAccount: LoginAccount, options: any = {}): RequestArgs {
            // verify required parameter 'loginAccount' is not null or undefined
            if (loginAccount === null || loginAccount === undefined) {
                throw new RequiredError('loginAccount','Required parameter loginAccount was null or undefined when calling login.');
            }
            const localVarPath = `/accounts/login`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof loginAccount !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(loginAccount !== undefined ? loginAccount : {}) : (loginAccount || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {ResetPassword} resetPassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(token: string, resetPassword: ResetPassword, options: any = {}): RequestArgs {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling resetPassword.');
            }
            // verify required parameter 'resetPassword' is not null or undefined
            if (resetPassword === null || resetPassword === undefined) {
                throw new RequiredError('resetPassword','Required parameter resetPassword was null or undefined when calling resetPassword.');
            }
            const localVarPath = `/accounts/resetPassword`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof resetPassword !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(resetPassword !== undefined ? resetPassword : {}) : (resetPassword || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SignUpAccount} signUpAccount 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signUp(signUpAccount: SignUpAccount, options: any = {}): RequestArgs {
            // verify required parameter 'signUpAccount' is not null or undefined
            if (signUpAccount === null || signUpAccount === undefined) {
                throw new RequiredError('signUpAccount','Required parameter signUpAccount was null or undefined when calling signUp.');
            }
            const localVarPath = `/accounts/signup`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof signUpAccount !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(signUpAccount !== undefined ? signUpAccount : {}) : (signUpAccount || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountsApi - functional programming interface
 * @export
 */
export const AccountsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _14f55b53Abee4b8eA64aE840e7d9ee01(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesBaseResponse> {
            const localVarAxiosArgs = AccountsApiAxiosParamCreator(configuration)._14f55b53Abee4b8eA64aE840e7d9ee01(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _20a4acf05f5c4d03A2001b10a5082542(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesBaseResponse> {
            const localVarAxiosArgs = AccountsApiAxiosParamCreator(configuration)._20a4acf05f5c4d03A2001b10a5082542(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _5239b5b0346b424aA8705a37db9fe392(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesBaseResponse> {
            const localVarAxiosArgs = AccountsApiAxiosParamCreator(configuration)._5239b5b0346b424aA8705a37db9fe392(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ac19f1461e3b47ba95deD5df5ba0c003(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesBaseResponse> {
            const localVarAxiosArgs = AccountsApiAxiosParamCreator(configuration).ac19f1461e3b47ba95deD5df5ba0c003(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bd10a4d8Bfff460b87f5Bdac66e5d434(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesBaseResponse> {
            const localVarAxiosArgs = AccountsApiAxiosParamCreator(configuration).bd10a4d8Bfff460b87f5Bdac66e5d434(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {FBLoginAccount} fBLoginAccount 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fBLogin(fBLoginAccount: FBLoginAccount, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesLoginResponse> {
            const localVarAxiosArgs = AccountsApiAxiosParamCreator(configuration).fBLogin(fBLoginAccount, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {ForgotPassword} forgotPassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgotPassword(forgotPassword: ForgotPassword, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesBaseResponse> {
            const localVarAxiosArgs = AccountsApiAxiosParamCreator(configuration).forgotPassword(forgotPassword, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {LoginAccount} loginAccount 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(loginAccount: LoginAccount, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesLoginResponse> {
            const localVarAxiosArgs = AccountsApiAxiosParamCreator(configuration).login(loginAccount, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {ResetPassword} resetPassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(token: string, resetPassword: ResetPassword, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesBaseResponse> {
            const localVarAxiosArgs = AccountsApiAxiosParamCreator(configuration).resetPassword(token, resetPassword, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {SignUpAccount} signUpAccount 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signUp(signUpAccount: SignUpAccount, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesLoginResponse> {
            const localVarAxiosArgs = AccountsApiAxiosParamCreator(configuration).signUp(signUpAccount, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AccountsApi - factory interface
 * @export
 */
export const AccountsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _14f55b53Abee4b8eA64aE840e7d9ee01(options?: any): AxiosPromise<ResponsesBaseResponse> {
            return AccountsApiFp(configuration)._14f55b53Abee4b8eA64aE840e7d9ee01(options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _20a4acf05f5c4d03A2001b10a5082542(options?: any): AxiosPromise<ResponsesBaseResponse> {
            return AccountsApiFp(configuration)._20a4acf05f5c4d03A2001b10a5082542(options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _5239b5b0346b424aA8705a37db9fe392(options?: any): AxiosPromise<ResponsesBaseResponse> {
            return AccountsApiFp(configuration)._5239b5b0346b424aA8705a37db9fe392(options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ac19f1461e3b47ba95deD5df5ba0c003(options?: any): AxiosPromise<ResponsesBaseResponse> {
            return AccountsApiFp(configuration).ac19f1461e3b47ba95deD5df5ba0c003(options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bd10a4d8Bfff460b87f5Bdac66e5d434(options?: any): AxiosPromise<ResponsesBaseResponse> {
            return AccountsApiFp(configuration).bd10a4d8Bfff460b87f5Bdac66e5d434(options)(axios, basePath);
        },
        /**
         * 
         * @param {FBLoginAccount} fBLoginAccount 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fBLogin(fBLoginAccount: FBLoginAccount, options?: any): AxiosPromise<ResponsesLoginResponse> {
            return AccountsApiFp(configuration).fBLogin(fBLoginAccount, options)(axios, basePath);
        },
        /**
         * 
         * @param {ForgotPassword} forgotPassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgotPassword(forgotPassword: ForgotPassword, options?: any): AxiosPromise<ResponsesBaseResponse> {
            return AccountsApiFp(configuration).forgotPassword(forgotPassword, options)(axios, basePath);
        },
        /**
         * 
         * @param {LoginAccount} loginAccount 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(loginAccount: LoginAccount, options?: any): AxiosPromise<ResponsesLoginResponse> {
            return AccountsApiFp(configuration).login(loginAccount, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} token 
         * @param {ResetPassword} resetPassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(token: string, resetPassword: ResetPassword, options?: any): AxiosPromise<ResponsesBaseResponse> {
            return AccountsApiFp(configuration).resetPassword(token, resetPassword, options)(axios, basePath);
        },
        /**
         * 
         * @param {SignUpAccount} signUpAccount 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signUp(signUpAccount: SignUpAccount, options?: any): AxiosPromise<ResponsesLoginResponse> {
            return AccountsApiFp(configuration).signUp(signUpAccount, options)(axios, basePath);
        },
    };
};

/**
 * AccountsApi - object-oriented interface
 * @export
 * @class AccountsApi
 * @extends {BaseAPI}
 */
export class AccountsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public _14f55b53Abee4b8eA64aE840e7d9ee01(options?: any) {
        return AccountsApiFp(this.configuration)._14f55b53Abee4b8eA64aE840e7d9ee01(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public _20a4acf05f5c4d03A2001b10a5082542(options?: any) {
        return AccountsApiFp(this.configuration)._20a4acf05f5c4d03A2001b10a5082542(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public _5239b5b0346b424aA8705a37db9fe392(options?: any) {
        return AccountsApiFp(this.configuration)._5239b5b0346b424aA8705a37db9fe392(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public ac19f1461e3b47ba95deD5df5ba0c003(options?: any) {
        return AccountsApiFp(this.configuration).ac19f1461e3b47ba95deD5df5ba0c003(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public bd10a4d8Bfff460b87f5Bdac66e5d434(options?: any) {
        return AccountsApiFp(this.configuration).bd10a4d8Bfff460b87f5Bdac66e5d434(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {FBLoginAccount} fBLoginAccount 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public fBLogin(fBLoginAccount: FBLoginAccount, options?: any) {
        return AccountsApiFp(this.configuration).fBLogin(fBLoginAccount, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {ForgotPassword} forgotPassword 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public forgotPassword(forgotPassword: ForgotPassword, options?: any) {
        return AccountsApiFp(this.configuration).forgotPassword(forgotPassword, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {LoginAccount} loginAccount 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public login(loginAccount: LoginAccount, options?: any) {
        return AccountsApiFp(this.configuration).login(loginAccount, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} token 
     * @param {ResetPassword} resetPassword 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public resetPassword(token: string, resetPassword: ResetPassword, options?: any) {
        return AccountsApiFp(this.configuration).resetPassword(token, resetPassword, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {SignUpAccount} signUpAccount 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public signUp(signUpAccount: SignUpAccount, options?: any) {
        return AccountsApiFp(this.configuration).signUp(signUpAccount, options)(this.axios, this.basePath);
    }

}


/**
 * AdminsApi - axios parameter creator
 * @export
 */
export const AdminsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _4efcf8f81b4c4da1857d5774b7a4268a(options: any = {}): RequestArgs {
            const localVarPath = `/admins/flagIsssueDetails`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _670e596461bc4626B936388501bab246(options: any = {}): RequestArgs {
            const localVarPath = `/admins/leaderboards/curators`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} submissionUnid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _6c6b6fafBacb4a208b2629c9b6813fb9(submissionUnid: string, options: any = {}): RequestArgs {
            // verify required parameter 'submissionUnid' is not null or undefined
            if (submissionUnid === null || submissionUnid === undefined) {
                throw new RequiredError('submissionUnid','Required parameter submissionUnid was null or undefined when calling _6c6b6fafBacb4a208b2629c9b6813fb9.');
            }
            const localVarPath = `/admins/problems/submissions/{submissionUnid}`
                .replace(`{${"submissionUnid"}}`, encodeURIComponent(String(submissionUnid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _81aa684cA5e945e69356Fb2bd6276ce4(options: any = {}): RequestArgs {
            const localVarPath = `/admins/contests`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} contestUnid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _9eeed0c75ddc4c9a8ae0D8b0adf02a6e(contestUnid: string, options: any = {}): RequestArgs {
            // verify required parameter 'contestUnid' is not null or undefined
            if (contestUnid === null || contestUnid === undefined) {
                throw new RequiredError('contestUnid','Required parameter contestUnid was null or undefined when calling _9eeed0c75ddc4c9a8ae0D8b0adf02a6e.');
            }
            const localVarPath = `/admins/contests/{contestUnid}`
                .replace(`{${"contestUnid"}}`, encodeURIComponent(String(contestUnid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {AddContest} addContest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAdminContests(token: string, addContest: AddContest, options: any = {}): RequestArgs {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling addAdminContests.');
            }
            // verify required parameter 'addContest' is not null or undefined
            if (addContest === null || addContest === undefined) {
                throw new RequiredError('addContest','Required parameter addContest was null or undefined when calling addAdminContests.');
            }
            const localVarPath = `/admins/contests`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof addContest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(addContest !== undefined ? addContest : {}) : (addContest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LoginAccount1} loginAccount1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLogin(loginAccount1: LoginAccount1, options: any = {}): RequestArgs {
            // verify required parameter 'loginAccount1' is not null or undefined
            if (loginAccount1 === null || loginAccount1 === undefined) {
                throw new RequiredError('loginAccount1','Required parameter loginAccount1 was null or undefined when calling adminLogin.');
            }
            const localVarPath = `/admins/login`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof loginAccount1 !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(loginAccount1 !== undefined ? loginAccount1 : {}) : (loginAccount1 || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        b689938640df445d89cf729c13057f69(options: any = {}): RequestArgs {
            const localVarPath = `/admins/login`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ddf2b1ff6ebe462287d5Cf3420771d9d(options: any = {}): RequestArgs {
            const localVarPath = `/admins/leaderboards/tracks`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} contestUnid 
         * @param {string} token 
         * @param {EditContest} editContest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editAdminContest(contestUnid: string, token: string, editContest: EditContest, options: any = {}): RequestArgs {
            // verify required parameter 'contestUnid' is not null or undefined
            if (contestUnid === null || contestUnid === undefined) {
                throw new RequiredError('contestUnid','Required parameter contestUnid was null or undefined when calling editAdminContest.');
            }
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling editAdminContest.');
            }
            // verify required parameter 'editContest' is not null or undefined
            if (editContest === null || editContest === undefined) {
                throw new RequiredError('editContest','Required parameter editContest was null or undefined when calling editAdminContest.');
            }
            const localVarPath = `/admins/contests/{contestUnid}`
                .replace(`{${"contestUnid"}}`, encodeURIComponent(String(contestUnid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof editContest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(editContest !== undefined ? editContest : {}) : (editContest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fdce33d8Df12497cB67b193eb0708da6(options: any = {}): RequestArgs {
            const localVarPath = `/admins/problems/submissions`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminContests(token: string, options: any = {}): RequestArgs {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling getAdminContests.');
            }
            const localVarPath = `/admins/contests`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {string} [end] 
         * @param {string} [start] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminCuratorLeaderboard(token: string, end?: string, start?: string, options: any = {}): RequestArgs {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling getAdminCuratorLeaderboard.');
            }
            const localVarPath = `/admins/leaderboards/curators`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminFlagIssueDetails(token: string, email?: string, options: any = {}): RequestArgs {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling getAdminFlagIssueDetails.');
            }
            const localVarPath = `/admins/flagIsssueDetails`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} createEnd 
         * @param {string} createStart 
         * @param {string} token 
         * @param {string} [closeEnd] 
         * @param {string} [closeStart] 
         * @param {string} [contestUnid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminTrackLeaderboard(createEnd: string, createStart: string, token: string, closeEnd?: string, closeStart?: string, contestUnid?: string, options: any = {}): RequestArgs {
            // verify required parameter 'createEnd' is not null or undefined
            if (createEnd === null || createEnd === undefined) {
                throw new RequiredError('createEnd','Required parameter createEnd was null or undefined when calling getAdminTrackLeaderboard.');
            }
            // verify required parameter 'createStart' is not null or undefined
            if (createStart === null || createStart === undefined) {
                throw new RequiredError('createStart','Required parameter createStart was null or undefined when calling getAdminTrackLeaderboard.');
            }
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling getAdminTrackLeaderboard.');
            }
            const localVarPath = `/admins/leaderboards/tracks`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (closeEnd !== undefined) {
                localVarQueryParameter['closeEnd'] = closeEnd;
            }

            if (closeStart !== undefined) {
                localVarQueryParameter['closeStart'] = closeStart;
            }

            if (contestUnid !== undefined) {
                localVarQueryParameter['contestUnid'] = contestUnid;
            }

            if (createEnd !== undefined) {
                localVarQueryParameter['createEnd'] = createEnd;
            }

            if (createStart !== undefined) {
                localVarQueryParameter['createStart'] = createStart;
            }

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProblemSubmissions(token: string, type?: string, options: any = {}): RequestArgs {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling getProblemSubmissions.');
            }
            const localVarPath = `/admins/problems/submissions`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} submissionUnid 
         * @param {string} token 
         * @param {SaveProblemSubmission} saveProblemSubmission 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveProblemSubmission(submissionUnid: string, token: string, saveProblemSubmission: SaveProblemSubmission, options: any = {}): RequestArgs {
            // verify required parameter 'submissionUnid' is not null or undefined
            if (submissionUnid === null || submissionUnid === undefined) {
                throw new RequiredError('submissionUnid','Required parameter submissionUnid was null or undefined when calling saveProblemSubmission.');
            }
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling saveProblemSubmission.');
            }
            // verify required parameter 'saveProblemSubmission' is not null or undefined
            if (saveProblemSubmission === null || saveProblemSubmission === undefined) {
                throw new RequiredError('saveProblemSubmission','Required parameter saveProblemSubmission was null or undefined when calling saveProblemSubmission.');
            }
            const localVarPath = `/admins/problems/submissions/{submissionUnid}`
                .replace(`{${"submissionUnid"}}`, encodeURIComponent(String(submissionUnid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof saveProblemSubmission !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(saveProblemSubmission !== undefined ? saveProblemSubmission : {}) : (saveProblemSubmission || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminsApi - functional programming interface
 * @export
 */
export const AdminsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _4efcf8f81b4c4da1857d5774b7a4268a(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesBaseResponse> {
            const localVarAxiosArgs = AdminsApiAxiosParamCreator(configuration)._4efcf8f81b4c4da1857d5774b7a4268a(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _670e596461bc4626B936388501bab246(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesBaseResponse> {
            const localVarAxiosArgs = AdminsApiAxiosParamCreator(configuration)._670e596461bc4626B936388501bab246(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} submissionUnid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _6c6b6fafBacb4a208b2629c9b6813fb9(submissionUnid: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesBaseResponse> {
            const localVarAxiosArgs = AdminsApiAxiosParamCreator(configuration)._6c6b6fafBacb4a208b2629c9b6813fb9(submissionUnid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _81aa684cA5e945e69356Fb2bd6276ce4(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesBaseResponse> {
            const localVarAxiosArgs = AdminsApiAxiosParamCreator(configuration)._81aa684cA5e945e69356Fb2bd6276ce4(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} contestUnid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _9eeed0c75ddc4c9a8ae0D8b0adf02a6e(contestUnid: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesBaseResponse> {
            const localVarAxiosArgs = AdminsApiAxiosParamCreator(configuration)._9eeed0c75ddc4c9a8ae0D8b0adf02a6e(contestUnid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {AddContest} addContest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAdminContests(token: string, addContest: AddContest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesContestResponse> {
            const localVarAxiosArgs = AdminsApiAxiosParamCreator(configuration).addAdminContests(token, addContest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {LoginAccount1} loginAccount1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLogin(loginAccount1: LoginAccount1, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesLoginResponse> {
            const localVarAxiosArgs = AdminsApiAxiosParamCreator(configuration).adminLogin(loginAccount1, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        b689938640df445d89cf729c13057f69(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesBaseResponse> {
            const localVarAxiosArgs = AdminsApiAxiosParamCreator(configuration).b689938640df445d89cf729c13057f69(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ddf2b1ff6ebe462287d5Cf3420771d9d(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesBaseResponse> {
            const localVarAxiosArgs = AdminsApiAxiosParamCreator(configuration).ddf2b1ff6ebe462287d5Cf3420771d9d(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} contestUnid 
         * @param {string} token 
         * @param {EditContest} editContest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editAdminContest(contestUnid: string, token: string, editContest: EditContest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesContestResponse> {
            const localVarAxiosArgs = AdminsApiAxiosParamCreator(configuration).editAdminContest(contestUnid, token, editContest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fdce33d8Df12497cB67b193eb0708da6(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesBaseResponse> {
            const localVarAxiosArgs = AdminsApiAxiosParamCreator(configuration).fdce33d8Df12497cB67b193eb0708da6(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminContests(token: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesContestsResponse> {
            const localVarAxiosArgs = AdminsApiAxiosParamCreator(configuration).getAdminContests(token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {string} [end] 
         * @param {string} [start] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminCuratorLeaderboard(token: string, end?: string, start?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesAdminCuratorLeaderboardResponse> {
            const localVarAxiosArgs = AdminsApiAxiosParamCreator(configuration).getAdminCuratorLeaderboard(token, end, start, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminFlagIssueDetails(token: string, email?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesAdminFlagIssueDetailsResponse> {
            const localVarAxiosArgs = AdminsApiAxiosParamCreator(configuration).getAdminFlagIssueDetails(token, email, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} createEnd 
         * @param {string} createStart 
         * @param {string} token 
         * @param {string} [closeEnd] 
         * @param {string} [closeStart] 
         * @param {string} [contestUnid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminTrackLeaderboard(createEnd: string, createStart: string, token: string, closeEnd?: string, closeStart?: string, contestUnid?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesAdminTrackLeaderboardResponse> {
            const localVarAxiosArgs = AdminsApiAxiosParamCreator(configuration).getAdminTrackLeaderboard(createEnd, createStart, token, closeEnd, closeStart, contestUnid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProblemSubmissions(token: string, type?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesProblemSubmissionsResponse> {
            const localVarAxiosArgs = AdminsApiAxiosParamCreator(configuration).getProblemSubmissions(token, type, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} submissionUnid 
         * @param {string} token 
         * @param {SaveProblemSubmission} saveProblemSubmission 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveProblemSubmission(submissionUnid: string, token: string, saveProblemSubmission: SaveProblemSubmission, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesBaseResponse> {
            const localVarAxiosArgs = AdminsApiAxiosParamCreator(configuration).saveProblemSubmission(submissionUnid, token, saveProblemSubmission, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AdminsApi - factory interface
 * @export
 */
export const AdminsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _4efcf8f81b4c4da1857d5774b7a4268a(options?: any): AxiosPromise<ResponsesBaseResponse> {
            return AdminsApiFp(configuration)._4efcf8f81b4c4da1857d5774b7a4268a(options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _670e596461bc4626B936388501bab246(options?: any): AxiosPromise<ResponsesBaseResponse> {
            return AdminsApiFp(configuration)._670e596461bc4626B936388501bab246(options)(axios, basePath);
        },
        /**
         * 
         * @param {string} submissionUnid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _6c6b6fafBacb4a208b2629c9b6813fb9(submissionUnid: string, options?: any): AxiosPromise<ResponsesBaseResponse> {
            return AdminsApiFp(configuration)._6c6b6fafBacb4a208b2629c9b6813fb9(submissionUnid, options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _81aa684cA5e945e69356Fb2bd6276ce4(options?: any): AxiosPromise<ResponsesBaseResponse> {
            return AdminsApiFp(configuration)._81aa684cA5e945e69356Fb2bd6276ce4(options)(axios, basePath);
        },
        /**
         * 
         * @param {string} contestUnid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _9eeed0c75ddc4c9a8ae0D8b0adf02a6e(contestUnid: string, options?: any): AxiosPromise<ResponsesBaseResponse> {
            return AdminsApiFp(configuration)._9eeed0c75ddc4c9a8ae0D8b0adf02a6e(contestUnid, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} token 
         * @param {AddContest} addContest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAdminContests(token: string, addContest: AddContest, options?: any): AxiosPromise<ResponsesContestResponse> {
            return AdminsApiFp(configuration).addAdminContests(token, addContest, options)(axios, basePath);
        },
        /**
         * 
         * @param {LoginAccount1} loginAccount1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLogin(loginAccount1: LoginAccount1, options?: any): AxiosPromise<ResponsesLoginResponse> {
            return AdminsApiFp(configuration).adminLogin(loginAccount1, options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        b689938640df445d89cf729c13057f69(options?: any): AxiosPromise<ResponsesBaseResponse> {
            return AdminsApiFp(configuration).b689938640df445d89cf729c13057f69(options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ddf2b1ff6ebe462287d5Cf3420771d9d(options?: any): AxiosPromise<ResponsesBaseResponse> {
            return AdminsApiFp(configuration).ddf2b1ff6ebe462287d5Cf3420771d9d(options)(axios, basePath);
        },
        /**
         * 
         * @param {string} contestUnid 
         * @param {string} token 
         * @param {EditContest} editContest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editAdminContest(contestUnid: string, token: string, editContest: EditContest, options?: any): AxiosPromise<ResponsesContestResponse> {
            return AdminsApiFp(configuration).editAdminContest(contestUnid, token, editContest, options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fdce33d8Df12497cB67b193eb0708da6(options?: any): AxiosPromise<ResponsesBaseResponse> {
            return AdminsApiFp(configuration).fdce33d8Df12497cB67b193eb0708da6(options)(axios, basePath);
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminContests(token: string, options?: any): AxiosPromise<ResponsesContestsResponse> {
            return AdminsApiFp(configuration).getAdminContests(token, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} token 
         * @param {string} [end] 
         * @param {string} [start] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminCuratorLeaderboard(token: string, end?: string, start?: string, options?: any): AxiosPromise<ResponsesAdminCuratorLeaderboardResponse> {
            return AdminsApiFp(configuration).getAdminCuratorLeaderboard(token, end, start, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} token 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminFlagIssueDetails(token: string, email?: string, options?: any): AxiosPromise<ResponsesAdminFlagIssueDetailsResponse> {
            return AdminsApiFp(configuration).getAdminFlagIssueDetails(token, email, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} createEnd 
         * @param {string} createStart 
         * @param {string} token 
         * @param {string} [closeEnd] 
         * @param {string} [closeStart] 
         * @param {string} [contestUnid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminTrackLeaderboard(createEnd: string, createStart: string, token: string, closeEnd?: string, closeStart?: string, contestUnid?: string, options?: any): AxiosPromise<ResponsesAdminTrackLeaderboardResponse> {
            return AdminsApiFp(configuration).getAdminTrackLeaderboard(createEnd, createStart, token, closeEnd, closeStart, contestUnid, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} token 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProblemSubmissions(token: string, type?: string, options?: any): AxiosPromise<ResponsesProblemSubmissionsResponse> {
            return AdminsApiFp(configuration).getProblemSubmissions(token, type, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} submissionUnid 
         * @param {string} token 
         * @param {SaveProblemSubmission} saveProblemSubmission 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveProblemSubmission(submissionUnid: string, token: string, saveProblemSubmission: SaveProblemSubmission, options?: any): AxiosPromise<ResponsesBaseResponse> {
            return AdminsApiFp(configuration).saveProblemSubmission(submissionUnid, token, saveProblemSubmission, options)(axios, basePath);
        },
    };
};

/**
 * AdminsApi - object-oriented interface
 * @export
 * @class AdminsApi
 * @extends {BaseAPI}
 */
export class AdminsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminsApi
     */
    public _4efcf8f81b4c4da1857d5774b7a4268a(options?: any) {
        return AdminsApiFp(this.configuration)._4efcf8f81b4c4da1857d5774b7a4268a(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminsApi
     */
    public _670e596461bc4626B936388501bab246(options?: any) {
        return AdminsApiFp(this.configuration)._670e596461bc4626B936388501bab246(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} submissionUnid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminsApi
     */
    public _6c6b6fafBacb4a208b2629c9b6813fb9(submissionUnid: string, options?: any) {
        return AdminsApiFp(this.configuration)._6c6b6fafBacb4a208b2629c9b6813fb9(submissionUnid, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminsApi
     */
    public _81aa684cA5e945e69356Fb2bd6276ce4(options?: any) {
        return AdminsApiFp(this.configuration)._81aa684cA5e945e69356Fb2bd6276ce4(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} contestUnid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminsApi
     */
    public _9eeed0c75ddc4c9a8ae0D8b0adf02a6e(contestUnid: string, options?: any) {
        return AdminsApiFp(this.configuration)._9eeed0c75ddc4c9a8ae0D8b0adf02a6e(contestUnid, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} token 
     * @param {AddContest} addContest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminsApi
     */
    public addAdminContests(token: string, addContest: AddContest, options?: any) {
        return AdminsApiFp(this.configuration).addAdminContests(token, addContest, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {LoginAccount1} loginAccount1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminsApi
     */
    public adminLogin(loginAccount1: LoginAccount1, options?: any) {
        return AdminsApiFp(this.configuration).adminLogin(loginAccount1, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminsApi
     */
    public b689938640df445d89cf729c13057f69(options?: any) {
        return AdminsApiFp(this.configuration).b689938640df445d89cf729c13057f69(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminsApi
     */
    public ddf2b1ff6ebe462287d5Cf3420771d9d(options?: any) {
        return AdminsApiFp(this.configuration).ddf2b1ff6ebe462287d5Cf3420771d9d(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} contestUnid 
     * @param {string} token 
     * @param {EditContest} editContest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminsApi
     */
    public editAdminContest(contestUnid: string, token: string, editContest: EditContest, options?: any) {
        return AdminsApiFp(this.configuration).editAdminContest(contestUnid, token, editContest, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminsApi
     */
    public fdce33d8Df12497cB67b193eb0708da6(options?: any) {
        return AdminsApiFp(this.configuration).fdce33d8Df12497cB67b193eb0708da6(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminsApi
     */
    public getAdminContests(token: string, options?: any) {
        return AdminsApiFp(this.configuration).getAdminContests(token, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} token 
     * @param {string} [end] 
     * @param {string} [start] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminsApi
     */
    public getAdminCuratorLeaderboard(token: string, end?: string, start?: string, options?: any) {
        return AdminsApiFp(this.configuration).getAdminCuratorLeaderboard(token, end, start, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} token 
     * @param {string} [email] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminsApi
     */
    public getAdminFlagIssueDetails(token: string, email?: string, options?: any) {
        return AdminsApiFp(this.configuration).getAdminFlagIssueDetails(token, email, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} createEnd 
     * @param {string} createStart 
     * @param {string} token 
     * @param {string} [closeEnd] 
     * @param {string} [closeStart] 
     * @param {string} [contestUnid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminsApi
     */
    public getAdminTrackLeaderboard(createEnd: string, createStart: string, token: string, closeEnd?: string, closeStart?: string, contestUnid?: string, options?: any) {
        return AdminsApiFp(this.configuration).getAdminTrackLeaderboard(createEnd, createStart, token, closeEnd, closeStart, contestUnid, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} token 
     * @param {string} [type] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminsApi
     */
    public getProblemSubmissions(token: string, type?: string, options?: any) {
        return AdminsApiFp(this.configuration).getProblemSubmissions(token, type, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} submissionUnid 
     * @param {string} token 
     * @param {SaveProblemSubmission} saveProblemSubmission 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminsApi
     */
    public saveProblemSubmission(submissionUnid: string, token: string, saveProblemSubmission: SaveProblemSubmission, options?: any) {
        return AdminsApiFp(this.configuration).saveProblemSubmission(submissionUnid, token, saveProblemSubmission, options)(this.axios, this.basePath);
    }

}


/**
 * BannersApi - axios parameter creator
 * @export
 */
export const BannersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        b4d5034bD4a3477e8c57E9b51f97923d(options: any = {}): RequestArgs {
            const localVarPath = `/banners/`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBanner(options: any = {}): RequestArgs {
            const localVarPath = `/banners/`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BannersApi - functional programming interface
 * @export
 */
export const BannersApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        b4d5034bD4a3477e8c57E9b51f97923d(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesBaseResponse> {
            const localVarAxiosArgs = BannersApiAxiosParamCreator(configuration).b4d5034bD4a3477e8c57E9b51f97923d(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBanner(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesBannerResponse> {
            const localVarAxiosArgs = BannersApiAxiosParamCreator(configuration).getBanner(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * BannersApi - factory interface
 * @export
 */
export const BannersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        b4d5034bD4a3477e8c57E9b51f97923d(options?: any): AxiosPromise<ResponsesBaseResponse> {
            return BannersApiFp(configuration).b4d5034bD4a3477e8c57E9b51f97923d(options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBanner(options?: any): AxiosPromise<ResponsesBannerResponse> {
            return BannersApiFp(configuration).getBanner(options)(axios, basePath);
        },
    };
};

/**
 * BannersApi - object-oriented interface
 * @export
 * @class BannersApi
 * @extends {BaseAPI}
 */
export class BannersApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BannersApi
     */
    public b4d5034bD4a3477e8c57E9b51f97923d(options?: any) {
        return BannersApiFp(this.configuration).b4d5034bD4a3477e8c57E9b51f97923d(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BannersApi
     */
    public getBanner(options?: any) {
        return BannersApiFp(this.configuration).getBanner(options)(this.axios, this.basePath);
    }

}


/**
 * ContestsApi - axios parameter creator
 * @export
 */
export const ContestsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContestsForDisplay(options: any = {}): RequestArgs {
            const localVarPath = `/contests/`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContestsApi - functional programming interface
 * @export
 */
export const ContestsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContestsForDisplay(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesContestForDisplayResponse> {
            const localVarAxiosArgs = ContestsApiAxiosParamCreator(configuration).getContestsForDisplay(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ContestsApi - factory interface
 * @export
 */
export const ContestsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContestsForDisplay(options?: any): AxiosPromise<ResponsesContestForDisplayResponse> {
            return ContestsApiFp(configuration).getContestsForDisplay(options)(axios, basePath);
        },
    };
};

/**
 * ContestsApi - object-oriented interface
 * @export
 * @class ContestsApi
 * @extends {BaseAPI}
 */
export class ContestsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContestsApi
     */
    public getContestsForDisplay(options?: any) {
        return ContestsApiFp(this.configuration).getContestsForDisplay(options)(this.axios, this.basePath);
    }

}


/**
 * ConversationsApi - axios parameter creator
 * @export
 */
export const ConversationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} conversationUnid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _9e0f1ecb49834879A00f090185742fc6(conversationUnid: string, options: any = {}): RequestArgs {
            // verify required parameter 'conversationUnid' is not null or undefined
            if (conversationUnid === null || conversationUnid === undefined) {
                throw new RequiredError('conversationUnid','Required parameter conversationUnid was null or undefined when calling _9e0f1ecb49834879A00f090185742fc6.');
            }
            const localVarPath = `/conversations/{conversationUnid}/messages`
                .replace(`{${"conversationUnid"}}`, encodeURIComponent(String(conversationUnid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        baa7a7057b724dc7Baeb2ae604e1afab(options: any = {}): RequestArgs {
            const localVarPath = `/conversations/`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} conversationUnid 
         * @param {string} token 
         * @param {CreateMessage} createMessage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMessage(conversationUnid: string, token: string, createMessage: CreateMessage, options: any = {}): RequestArgs {
            // verify required parameter 'conversationUnid' is not null or undefined
            if (conversationUnid === null || conversationUnid === undefined) {
                throw new RequiredError('conversationUnid','Required parameter conversationUnid was null or undefined when calling createMessage.');
            }
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling createMessage.');
            }
            // verify required parameter 'createMessage' is not null or undefined
            if (createMessage === null || createMessage === undefined) {
                throw new RequiredError('createMessage','Required parameter createMessage was null or undefined when calling createMessage.');
            }
            const localVarPath = `/conversations/{conversationUnid}/messages`
                .replace(`{${"conversationUnid"}}`, encodeURIComponent(String(conversationUnid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof createMessage !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createMessage !== undefined ? createMessage : {}) : (createMessage || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} conversationUnid 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversationMessages(conversationUnid: string, token: string, options: any = {}): RequestArgs {
            // verify required parameter 'conversationUnid' is not null or undefined
            if (conversationUnid === null || conversationUnid === undefined) {
                throw new RequiredError('conversationUnid','Required parameter conversationUnid was null or undefined when calling getConversationMessages.');
            }
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling getConversationMessages.');
            }
            const localVarPath = `/conversations/{conversationUnid}/messages`
                .replace(`{${"conversationUnid"}}`, encodeURIComponent(String(conversationUnid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversations(token: string, options: any = {}): RequestArgs {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling getConversations.');
            }
            const localVarPath = `/conversations/`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConversationsApi - functional programming interface
 * @export
 */
export const ConversationsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} conversationUnid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _9e0f1ecb49834879A00f090185742fc6(conversationUnid: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesBaseResponse> {
            const localVarAxiosArgs = ConversationsApiAxiosParamCreator(configuration)._9e0f1ecb49834879A00f090185742fc6(conversationUnid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        baa7a7057b724dc7Baeb2ae604e1afab(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesBaseResponse> {
            const localVarAxiosArgs = ConversationsApiAxiosParamCreator(configuration).baa7a7057b724dc7Baeb2ae604e1afab(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} conversationUnid 
         * @param {string} token 
         * @param {CreateMessage} createMessage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMessage(conversationUnid: string, token: string, createMessage: CreateMessage, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesBaseResponse> {
            const localVarAxiosArgs = ConversationsApiAxiosParamCreator(configuration).createMessage(conversationUnid, token, createMessage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} conversationUnid 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversationMessages(conversationUnid: string, token: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesConversationResponse> {
            const localVarAxiosArgs = ConversationsApiAxiosParamCreator(configuration).getConversationMessages(conversationUnid, token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversations(token: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesConversationsResponse> {
            const localVarAxiosArgs = ConversationsApiAxiosParamCreator(configuration).getConversations(token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ConversationsApi - factory interface
 * @export
 */
export const ConversationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} conversationUnid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _9e0f1ecb49834879A00f090185742fc6(conversationUnid: string, options?: any): AxiosPromise<ResponsesBaseResponse> {
            return ConversationsApiFp(configuration)._9e0f1ecb49834879A00f090185742fc6(conversationUnid, options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        baa7a7057b724dc7Baeb2ae604e1afab(options?: any): AxiosPromise<ResponsesBaseResponse> {
            return ConversationsApiFp(configuration).baa7a7057b724dc7Baeb2ae604e1afab(options)(axios, basePath);
        },
        /**
         * 
         * @param {string} conversationUnid 
         * @param {string} token 
         * @param {CreateMessage} createMessage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMessage(conversationUnid: string, token: string, createMessage: CreateMessage, options?: any): AxiosPromise<ResponsesBaseResponse> {
            return ConversationsApiFp(configuration).createMessage(conversationUnid, token, createMessage, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} conversationUnid 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversationMessages(conversationUnid: string, token: string, options?: any): AxiosPromise<ResponsesConversationResponse> {
            return ConversationsApiFp(configuration).getConversationMessages(conversationUnid, token, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversations(token: string, options?: any): AxiosPromise<ResponsesConversationsResponse> {
            return ConversationsApiFp(configuration).getConversations(token, options)(axios, basePath);
        },
    };
};

/**
 * ConversationsApi - object-oriented interface
 * @export
 * @class ConversationsApi
 * @extends {BaseAPI}
 */
export class ConversationsApi extends BaseAPI {
    /**
     * 
     * @param {string} conversationUnid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationsApi
     */
    public _9e0f1ecb49834879A00f090185742fc6(conversationUnid: string, options?: any) {
        return ConversationsApiFp(this.configuration)._9e0f1ecb49834879A00f090185742fc6(conversationUnid, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationsApi
     */
    public baa7a7057b724dc7Baeb2ae604e1afab(options?: any) {
        return ConversationsApiFp(this.configuration).baa7a7057b724dc7Baeb2ae604e1afab(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} conversationUnid 
     * @param {string} token 
     * @param {CreateMessage} createMessage 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationsApi
     */
    public createMessage(conversationUnid: string, token: string, createMessage: CreateMessage, options?: any) {
        return ConversationsApiFp(this.configuration).createMessage(conversationUnid, token, createMessage, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} conversationUnid 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationsApi
     */
    public getConversationMessages(conversationUnid: string, token: string, options?: any) {
        return ConversationsApiFp(this.configuration).getConversationMessages(conversationUnid, token, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationsApi
     */
    public getConversations(token: string, options?: any) {
        return ConversationsApiFp(this.configuration).getConversations(token, options)(this.axios, this.basePath);
    }

}


/**
 * LeaderboardsApi - axios parameter creator
 * @export
 */
export const LeaderboardsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _41282cfbC4034c5aBe7626879311505f(options: any = {}): RequestArgs {
            const localVarPath = `/leaderboards/curators`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        e7b3dc72C54847e78757A2dcf5537ed3(options: any = {}): RequestArgs {
            const localVarPath = `/leaderboards/tracks`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        e7b8b9eb51644f3fB85c54bee9aefd50(options: any = {}): RequestArgs {
            const localVarPath = `/leaderboards/artists`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArtistLeaderboard(options: any = {}): RequestArgs {
            const localVarPath = `/leaderboards/artists`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCuratorLeaderboard(options: any = {}): RequestArgs {
            const localVarPath = `/leaderboards/curators`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrackLeaderboard(options: any = {}): RequestArgs {
            const localVarPath = `/leaderboards/tracks`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LeaderboardsApi - functional programming interface
 * @export
 */
export const LeaderboardsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _41282cfbC4034c5aBe7626879311505f(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesBaseResponse> {
            const localVarAxiosArgs = LeaderboardsApiAxiosParamCreator(configuration)._41282cfbC4034c5aBe7626879311505f(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        e7b3dc72C54847e78757A2dcf5537ed3(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesBaseResponse> {
            const localVarAxiosArgs = LeaderboardsApiAxiosParamCreator(configuration).e7b3dc72C54847e78757A2dcf5537ed3(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        e7b8b9eb51644f3fB85c54bee9aefd50(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesBaseResponse> {
            const localVarAxiosArgs = LeaderboardsApiAxiosParamCreator(configuration).e7b8b9eb51644f3fB85c54bee9aefd50(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArtistLeaderboard(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesArtistLeaderboardResponse> {
            const localVarAxiosArgs = LeaderboardsApiAxiosParamCreator(configuration).getArtistLeaderboard(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCuratorLeaderboard(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesCuratorLeaderboardResponse> {
            const localVarAxiosArgs = LeaderboardsApiAxiosParamCreator(configuration).getCuratorLeaderboard(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrackLeaderboard(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesTrackLeaderboardResponse> {
            const localVarAxiosArgs = LeaderboardsApiAxiosParamCreator(configuration).getTrackLeaderboard(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * LeaderboardsApi - factory interface
 * @export
 */
export const LeaderboardsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _41282cfbC4034c5aBe7626879311505f(options?: any): AxiosPromise<ResponsesBaseResponse> {
            return LeaderboardsApiFp(configuration)._41282cfbC4034c5aBe7626879311505f(options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        e7b3dc72C54847e78757A2dcf5537ed3(options?: any): AxiosPromise<ResponsesBaseResponse> {
            return LeaderboardsApiFp(configuration).e7b3dc72C54847e78757A2dcf5537ed3(options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        e7b8b9eb51644f3fB85c54bee9aefd50(options?: any): AxiosPromise<ResponsesBaseResponse> {
            return LeaderboardsApiFp(configuration).e7b8b9eb51644f3fB85c54bee9aefd50(options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArtistLeaderboard(options?: any): AxiosPromise<ResponsesArtistLeaderboardResponse> {
            return LeaderboardsApiFp(configuration).getArtistLeaderboard(options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCuratorLeaderboard(options?: any): AxiosPromise<ResponsesCuratorLeaderboardResponse> {
            return LeaderboardsApiFp(configuration).getCuratorLeaderboard(options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrackLeaderboard(options?: any): AxiosPromise<ResponsesTrackLeaderboardResponse> {
            return LeaderboardsApiFp(configuration).getTrackLeaderboard(options)(axios, basePath);
        },
    };
};

/**
 * LeaderboardsApi - object-oriented interface
 * @export
 * @class LeaderboardsApi
 * @extends {BaseAPI}
 */
export class LeaderboardsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeaderboardsApi
     */
    public _41282cfbC4034c5aBe7626879311505f(options?: any) {
        return LeaderboardsApiFp(this.configuration)._41282cfbC4034c5aBe7626879311505f(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeaderboardsApi
     */
    public e7b3dc72C54847e78757A2dcf5537ed3(options?: any) {
        return LeaderboardsApiFp(this.configuration).e7b3dc72C54847e78757A2dcf5537ed3(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeaderboardsApi
     */
    public e7b8b9eb51644f3fB85c54bee9aefd50(options?: any) {
        return LeaderboardsApiFp(this.configuration).e7b8b9eb51644f3fB85c54bee9aefd50(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeaderboardsApi
     */
    public getArtistLeaderboard(options?: any) {
        return LeaderboardsApiFp(this.configuration).getArtistLeaderboard(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeaderboardsApi
     */
    public getCuratorLeaderboard(options?: any) {
        return LeaderboardsApiFp(this.configuration).getCuratorLeaderboard(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeaderboardsApi
     */
    public getTrackLeaderboard(options?: any) {
        return LeaderboardsApiFp(this.configuration).getTrackLeaderboard(options)(this.axios, this.basePath);
    }

}


/**
 * PaymentsApi - axios parameter creator
 * @export
 */
export const PaymentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _7535638565034bc8B46e5fda51a9d404(options: any = {}): RequestArgs {
            const localVarPath = `/payments/`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {SubmissionPayment} submissionPayment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makePayment(token: string, submissionPayment: SubmissionPayment, options: any = {}): RequestArgs {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling makePayment.');
            }
            // verify required parameter 'submissionPayment' is not null or undefined
            if (submissionPayment === null || submissionPayment === undefined) {
                throw new RequiredError('submissionPayment','Required parameter submissionPayment was null or undefined when calling makePayment.');
            }
            const localVarPath = `/payments/`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof submissionPayment !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(submissionPayment !== undefined ? submissionPayment : {}) : (submissionPayment || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentsApi - functional programming interface
 * @export
 */
export const PaymentsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _7535638565034bc8B46e5fda51a9d404(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesBaseResponse> {
            const localVarAxiosArgs = PaymentsApiAxiosParamCreator(configuration)._7535638565034bc8B46e5fda51a9d404(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {SubmissionPayment} submissionPayment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makePayment(token: string, submissionPayment: SubmissionPayment, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesPaymentResponse> {
            const localVarAxiosArgs = PaymentsApiAxiosParamCreator(configuration).makePayment(token, submissionPayment, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * PaymentsApi - factory interface
 * @export
 */
export const PaymentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _7535638565034bc8B46e5fda51a9d404(options?: any): AxiosPromise<ResponsesBaseResponse> {
            return PaymentsApiFp(configuration)._7535638565034bc8B46e5fda51a9d404(options)(axios, basePath);
        },
        /**
         * 
         * @param {string} token 
         * @param {SubmissionPayment} submissionPayment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makePayment(token: string, submissionPayment: SubmissionPayment, options?: any): AxiosPromise<ResponsesPaymentResponse> {
            return PaymentsApiFp(configuration).makePayment(token, submissionPayment, options)(axios, basePath);
        },
    };
};

/**
 * PaymentsApi - object-oriented interface
 * @export
 * @class PaymentsApi
 * @extends {BaseAPI}
 */
export class PaymentsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public _7535638565034bc8B46e5fda51a9d404(options?: any) {
        return PaymentsApiFp(this.configuration)._7535638565034bc8B46e5fda51a9d404(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} token 
     * @param {SubmissionPayment} submissionPayment 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public makePayment(token: string, submissionPayment: SubmissionPayment, options?: any) {
        return PaymentsApiFp(this.configuration).makePayment(token, submissionPayment, options)(this.axios, this.basePath);
    }

}


/**
 * ProductsApi - axios parameter creator
 * @export
 */
export const ProductsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _4ee53bd22e8a44159009F4a654a194ff(options: any = {}): RequestArgs {
            const localVarPath = `/products/`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {string} [couponCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableProducts(token: string, couponCode?: string, options: any = {}): RequestArgs {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling getAvailableProducts.');
            }
            const localVarPath = `/products/`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (couponCode !== undefined) {
                localVarQueryParameter['couponCode'] = couponCode;
            }

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductsApi - functional programming interface
 * @export
 */
export const ProductsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _4ee53bd22e8a44159009F4a654a194ff(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesBaseResponse> {
            const localVarAxiosArgs = ProductsApiAxiosParamCreator(configuration)._4ee53bd22e8a44159009F4a654a194ff(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {string} [couponCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableProducts(token: string, couponCode?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesAvailableProductsResponse> {
            const localVarAxiosArgs = ProductsApiAxiosParamCreator(configuration).getAvailableProducts(token, couponCode, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ProductsApi - factory interface
 * @export
 */
export const ProductsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _4ee53bd22e8a44159009F4a654a194ff(options?: any): AxiosPromise<ResponsesBaseResponse> {
            return ProductsApiFp(configuration)._4ee53bd22e8a44159009F4a654a194ff(options)(axios, basePath);
        },
        /**
         * 
         * @param {string} token 
         * @param {string} [couponCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableProducts(token: string, couponCode?: string, options?: any): AxiosPromise<ResponsesAvailableProductsResponse> {
            return ProductsApiFp(configuration).getAvailableProducts(token, couponCode, options)(axios, basePath);
        },
    };
};

/**
 * ProductsApi - object-oriented interface
 * @export
 * @class ProductsApi
 * @extends {BaseAPI}
 */
export class ProductsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public _4ee53bd22e8a44159009F4a654a194ff(options?: any) {
        return ProductsApiFp(this.configuration)._4ee53bd22e8a44159009F4a654a194ff(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} token 
     * @param {string} [couponCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public getAvailableProducts(token: string, couponCode?: string, options?: any) {
        return ProductsApiFp(this.configuration).getAvailableProducts(token, couponCode, options)(this.axios, this.basePath);
    }

}


/**
 * QueuesApi - axios parameter creator
 * @export
 */
export const QueuesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        a6604412429e4b788404A166168b3aa8(options: any = {}): RequestArgs {
            const localVarPath = `/queues/submissions`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubmissionToRate(token?: string, options: any = {}): RequestArgs {
            const localVarPath = `/queues/submissions`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * QueuesApi - functional programming interface
 * @export
 */
export const QueuesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        a6604412429e4b788404A166168b3aa8(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesBaseResponse> {
            const localVarAxiosArgs = QueuesApiAxiosParamCreator(configuration).a6604412429e4b788404A166168b3aa8(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubmissionToRate(token?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesSubmissionWithArtistAndRatingResponse> {
            const localVarAxiosArgs = QueuesApiAxiosParamCreator(configuration).getSubmissionToRate(token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * QueuesApi - factory interface
 * @export
 */
export const QueuesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        a6604412429e4b788404A166168b3aa8(options?: any): AxiosPromise<ResponsesBaseResponse> {
            return QueuesApiFp(configuration).a6604412429e4b788404A166168b3aa8(options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubmissionToRate(token?: string, options?: any): AxiosPromise<ResponsesSubmissionWithArtistAndRatingResponse> {
            return QueuesApiFp(configuration).getSubmissionToRate(token, options)(axios, basePath);
        },
    };
};

/**
 * QueuesApi - object-oriented interface
 * @export
 * @class QueuesApi
 * @extends {BaseAPI}
 */
export class QueuesApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueuesApi
     */
    public a6604412429e4b788404A166168b3aa8(options?: any) {
        return QueuesApiFp(this.configuration).a6604412429e4b788404A166168b3aa8(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [token] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueuesApi
     */
    public getSubmissionToRate(token?: string, options?: any) {
        return QueuesApiFp(this.configuration).getSubmissionToRate(token, options)(this.axios, this.basePath);
    }

}


/**
 * SubmissionsApi - axios parameter creator
 * @export
 */
export const SubmissionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} submissionUnid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _1ee18d0f2db64477Af30Fa2050f2bd85(submissionUnid: string, options: any = {}): RequestArgs {
            // verify required parameter 'submissionUnid' is not null or undefined
            if (submissionUnid === null || submissionUnid === undefined) {
                throw new RequiredError('submissionUnid','Required parameter submissionUnid was null or undefined when calling _1ee18d0f2db64477Af30Fa2050f2bd85.');
            }
            const localVarPath = `/submissions/{submissionUnid}/ratings`
                .replace(`{${"submissionUnid"}}`, encodeURIComponent(String(submissionUnid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} submissionUnid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _8cc05a7d413141608d47794bd48e166c(submissionUnid: string, options: any = {}): RequestArgs {
            // verify required parameter 'submissionUnid' is not null or undefined
            if (submissionUnid === null || submissionUnid === undefined) {
                throw new RequiredError('submissionUnid','Required parameter submissionUnid was null or undefined when calling _8cc05a7d413141608d47794bd48e166c.');
            }
            const localVarPath = `/submissions/{submissionUnid}`
                .replace(`{${"submissionUnid"}}`, encodeURIComponent(String(submissionUnid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} submissionUnid 
         * @param {string} token 
         * @param {CreateComment} createComment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCommentForSubmission(submissionUnid: string, token: string, createComment: CreateComment, options: any = {}): RequestArgs {
            // verify required parameter 'submissionUnid' is not null or undefined
            if (submissionUnid === null || submissionUnid === undefined) {
                throw new RequiredError('submissionUnid','Required parameter submissionUnid was null or undefined when calling createCommentForSubmission.');
            }
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling createCommentForSubmission.');
            }
            // verify required parameter 'createComment' is not null or undefined
            if (createComment === null || createComment === undefined) {
                throw new RequiredError('createComment','Required parameter createComment was null or undefined when calling createCommentForSubmission.');
            }
            const localVarPath = `/submissions/{submissionUnid}/comments`
                .replace(`{${"submissionUnid"}}`, encodeURIComponent(String(submissionUnid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof createComment !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createComment !== undefined ? createComment : {}) : (createComment || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} submissionUnid 
         * @param {string} token 
         * @param {CreateRating} createRating 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRatingForSubmission(submissionUnid: string, token: string, createRating: CreateRating, options: any = {}): RequestArgs {
            // verify required parameter 'submissionUnid' is not null or undefined
            if (submissionUnid === null || submissionUnid === undefined) {
                throw new RequiredError('submissionUnid','Required parameter submissionUnid was null or undefined when calling createRatingForSubmission.');
            }
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling createRatingForSubmission.');
            }
            // verify required parameter 'createRating' is not null or undefined
            if (createRating === null || createRating === undefined) {
                throw new RequiredError('createRating','Required parameter createRating was null or undefined when calling createRatingForSubmission.');
            }
            const localVarPath = `/submissions/{submissionUnid}/ratings`
                .replace(`{${"submissionUnid"}}`, encodeURIComponent(String(submissionUnid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof createRating !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createRating !== undefined ? createRating : {}) : (createRating || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} submissionUnid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        d3a52f44604c40498a914ead28380da2(submissionUnid: string, options: any = {}): RequestArgs {
            // verify required parameter 'submissionUnid' is not null or undefined
            if (submissionUnid === null || submissionUnid === undefined) {
                throw new RequiredError('submissionUnid','Required parameter submissionUnid was null or undefined when calling d3a52f44604c40498a914ead28380da2.');
            }
            const localVarPath = `/submissions/{submissionUnid}/comments`
                .replace(`{${"submissionUnid"}}`, encodeURIComponent(String(submissionUnid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} submissionUnid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        d82998bd8baa4b19A4a2Bae613cdd2fb(submissionUnid: string, options: any = {}): RequestArgs {
            // verify required parameter 'submissionUnid' is not null or undefined
            if (submissionUnid === null || submissionUnid === undefined) {
                throw new RequiredError('submissionUnid','Required parameter submissionUnid was null or undefined when calling d82998bd8baa4b19A4a2Bae613cdd2fb.');
            }
            const localVarPath = `/submissions/{submissionUnid}/flag`
                .replace(`{${"submissionUnid"}}`, encodeURIComponent(String(submissionUnid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} submissionUnid 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        flagSubmission(submissionUnid: string, token: string, options: any = {}): RequestArgs {
            // verify required parameter 'submissionUnid' is not null or undefined
            if (submissionUnid === null || submissionUnid === undefined) {
                throw new RequiredError('submissionUnid','Required parameter submissionUnid was null or undefined when calling flagSubmission.');
            }
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling flagSubmission.');
            }
            const localVarPath = `/submissions/{submissionUnid}/flag`
                .replace(`{${"submissionUnid"}}`, encodeURIComponent(String(submissionUnid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} submissionUnid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubmission(submissionUnid: string, options: any = {}): RequestArgs {
            // verify required parameter 'submissionUnid' is not null or undefined
            if (submissionUnid === null || submissionUnid === undefined) {
                throw new RequiredError('submissionUnid','Required parameter submissionUnid was null or undefined when calling getSubmission.');
            }
            const localVarPath = `/submissions/{submissionUnid}`
                .replace(`{${"submissionUnid"}}`, encodeURIComponent(String(submissionUnid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SubmissionsApi - functional programming interface
 * @export
 */
export const SubmissionsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} submissionUnid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _1ee18d0f2db64477Af30Fa2050f2bd85(submissionUnid: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesBaseResponse> {
            const localVarAxiosArgs = SubmissionsApiAxiosParamCreator(configuration)._1ee18d0f2db64477Af30Fa2050f2bd85(submissionUnid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} submissionUnid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _8cc05a7d413141608d47794bd48e166c(submissionUnid: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesBaseResponse> {
            const localVarAxiosArgs = SubmissionsApiAxiosParamCreator(configuration)._8cc05a7d413141608d47794bd48e166c(submissionUnid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} submissionUnid 
         * @param {string} token 
         * @param {CreateComment} createComment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCommentForSubmission(submissionUnid: string, token: string, createComment: CreateComment, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesCommentResponse> {
            const localVarAxiosArgs = SubmissionsApiAxiosParamCreator(configuration).createCommentForSubmission(submissionUnid, token, createComment, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} submissionUnid 
         * @param {string} token 
         * @param {CreateRating} createRating 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRatingForSubmission(submissionUnid: string, token: string, createRating: CreateRating, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesRatingResponse> {
            const localVarAxiosArgs = SubmissionsApiAxiosParamCreator(configuration).createRatingForSubmission(submissionUnid, token, createRating, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} submissionUnid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        d3a52f44604c40498a914ead28380da2(submissionUnid: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesBaseResponse> {
            const localVarAxiosArgs = SubmissionsApiAxiosParamCreator(configuration).d3a52f44604c40498a914ead28380da2(submissionUnid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} submissionUnid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        d82998bd8baa4b19A4a2Bae613cdd2fb(submissionUnid: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesBaseResponse> {
            const localVarAxiosArgs = SubmissionsApiAxiosParamCreator(configuration).d82998bd8baa4b19A4a2Bae613cdd2fb(submissionUnid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} submissionUnid 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        flagSubmission(submissionUnid: string, token: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesFlaggedSubmissionResponse> {
            const localVarAxiosArgs = SubmissionsApiAxiosParamCreator(configuration).flagSubmission(submissionUnid, token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} submissionUnid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubmission(submissionUnid: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesSubmissionWithArtistAndRatingResponse> {
            const localVarAxiosArgs = SubmissionsApiAxiosParamCreator(configuration).getSubmission(submissionUnid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SubmissionsApi - factory interface
 * @export
 */
export const SubmissionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} submissionUnid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _1ee18d0f2db64477Af30Fa2050f2bd85(submissionUnid: string, options?: any): AxiosPromise<ResponsesBaseResponse> {
            return SubmissionsApiFp(configuration)._1ee18d0f2db64477Af30Fa2050f2bd85(submissionUnid, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} submissionUnid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _8cc05a7d413141608d47794bd48e166c(submissionUnid: string, options?: any): AxiosPromise<ResponsesBaseResponse> {
            return SubmissionsApiFp(configuration)._8cc05a7d413141608d47794bd48e166c(submissionUnid, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} submissionUnid 
         * @param {string} token 
         * @param {CreateComment} createComment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCommentForSubmission(submissionUnid: string, token: string, createComment: CreateComment, options?: any): AxiosPromise<ResponsesCommentResponse> {
            return SubmissionsApiFp(configuration).createCommentForSubmission(submissionUnid, token, createComment, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} submissionUnid 
         * @param {string} token 
         * @param {CreateRating} createRating 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRatingForSubmission(submissionUnid: string, token: string, createRating: CreateRating, options?: any): AxiosPromise<ResponsesRatingResponse> {
            return SubmissionsApiFp(configuration).createRatingForSubmission(submissionUnid, token, createRating, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} submissionUnid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        d3a52f44604c40498a914ead28380da2(submissionUnid: string, options?: any): AxiosPromise<ResponsesBaseResponse> {
            return SubmissionsApiFp(configuration).d3a52f44604c40498a914ead28380da2(submissionUnid, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} submissionUnid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        d82998bd8baa4b19A4a2Bae613cdd2fb(submissionUnid: string, options?: any): AxiosPromise<ResponsesBaseResponse> {
            return SubmissionsApiFp(configuration).d82998bd8baa4b19A4a2Bae613cdd2fb(submissionUnid, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} submissionUnid 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        flagSubmission(submissionUnid: string, token: string, options?: any): AxiosPromise<ResponsesFlaggedSubmissionResponse> {
            return SubmissionsApiFp(configuration).flagSubmission(submissionUnid, token, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} submissionUnid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubmission(submissionUnid: string, options?: any): AxiosPromise<ResponsesSubmissionWithArtistAndRatingResponse> {
            return SubmissionsApiFp(configuration).getSubmission(submissionUnid, options)(axios, basePath);
        },
    };
};

/**
 * SubmissionsApi - object-oriented interface
 * @export
 * @class SubmissionsApi
 * @extends {BaseAPI}
 */
export class SubmissionsApi extends BaseAPI {
    /**
     * 
     * @param {string} submissionUnid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubmissionsApi
     */
    public _1ee18d0f2db64477Af30Fa2050f2bd85(submissionUnid: string, options?: any) {
        return SubmissionsApiFp(this.configuration)._1ee18d0f2db64477Af30Fa2050f2bd85(submissionUnid, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} submissionUnid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubmissionsApi
     */
    public _8cc05a7d413141608d47794bd48e166c(submissionUnid: string, options?: any) {
        return SubmissionsApiFp(this.configuration)._8cc05a7d413141608d47794bd48e166c(submissionUnid, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} submissionUnid 
     * @param {string} token 
     * @param {CreateComment} createComment 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubmissionsApi
     */
    public createCommentForSubmission(submissionUnid: string, token: string, createComment: CreateComment, options?: any) {
        return SubmissionsApiFp(this.configuration).createCommentForSubmission(submissionUnid, token, createComment, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} submissionUnid 
     * @param {string} token 
     * @param {CreateRating} createRating 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubmissionsApi
     */
    public createRatingForSubmission(submissionUnid: string, token: string, createRating: CreateRating, options?: any) {
        return SubmissionsApiFp(this.configuration).createRatingForSubmission(submissionUnid, token, createRating, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} submissionUnid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubmissionsApi
     */
    public d3a52f44604c40498a914ead28380da2(submissionUnid: string, options?: any) {
        return SubmissionsApiFp(this.configuration).d3a52f44604c40498a914ead28380da2(submissionUnid, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} submissionUnid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubmissionsApi
     */
    public d82998bd8baa4b19A4a2Bae613cdd2fb(submissionUnid: string, options?: any) {
        return SubmissionsApiFp(this.configuration).d82998bd8baa4b19A4a2Bae613cdd2fb(submissionUnid, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} submissionUnid 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubmissionsApi
     */
    public flagSubmission(submissionUnid: string, token: string, options?: any) {
        return SubmissionsApiFp(this.configuration).flagSubmission(submissionUnid, token, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} submissionUnid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubmissionsApi
     */
    public getSubmission(submissionUnid: string, options?: any) {
        return SubmissionsApiFp(this.configuration).getSubmission(submissionUnid, options)(this.axios, this.basePath);
    }

}


/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _11ed4e5eDb9d45c584b7C8cfdc1ee79e(options: any = {}): RequestArgs {
            const localVarPath = `/users/optins`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _1e7fd670492d4c228ece812fef7429e7(options: any = {}): RequestArgs {
            const localVarPath = `/users/unpaid/last`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} cardID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _24cb5c0eB4474bc28859940f3d783fa0(cardID: string, options: any = {}): RequestArgs {
            // verify required parameter 'cardID' is not null or undefined
            if (cardID === null || cardID === undefined) {
                throw new RequiredError('cardID','Required parameter cardID was null or undefined when calling _24cb5c0eB4474bc28859940f3d783fa0.');
            }
            const localVarPath = `/users/cards/{cardID}`
                .replace(`{${"cardID"}}`, encodeURIComponent(String(cardID)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _58ceedf8893046aaA7c5012bbf108263(options: any = {}): RequestArgs {
            const localVarPath = `/users/profile`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _838deda57c2940b69702F8be6818666d(options: any = {}): RequestArgs {
            const localVarPath = `/users/`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        a65a4c9c3d9d4c7892a8De07124ef7ae(options: any = {}): RequestArgs {
            const localVarPath = `/users/flagIssues`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {AddCard} addCard 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCard(token: string, addCard: AddCard, options: any = {}): RequestArgs {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling addCard.');
            }
            // verify required parameter 'addCard' is not null or undefined
            if (addCard === null || addCard === undefined) {
                throw new RequiredError('addCard','Required parameter addCard was null or undefined when calling addCard.');
            }
            const localVarPath = `/users/cards`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof addCard !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(addCard !== undefined ? addCard : {}) : (addCard || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {AddFlagIssueDetails} addFlagIssueDetails 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFlagIssueDetails(token: string, addFlagIssueDetails: AddFlagIssueDetails, options: any = {}): RequestArgs {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling addFlagIssueDetails.');
            }
            // verify required parameter 'addFlagIssueDetails' is not null or undefined
            if (addFlagIssueDetails === null || addFlagIssueDetails === undefined) {
                throw new RequiredError('addFlagIssueDetails','Required parameter addFlagIssueDetails was null or undefined when calling addFlagIssueDetails.');
            }
            const localVarPath = `/users/flagIssues`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof addFlagIssueDetails !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(addFlagIssueDetails !== undefined ? addFlagIssueDetails : {}) : (addFlagIssueDetails || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        b65695f32ff149e8Bf59Dcbd52927a46(options: any = {}): RequestArgs {
            const localVarPath = `/users/cards`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} submissionUnid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c914416433e1414bAa5699700362e032(submissionUnid: string, options: any = {}): RequestArgs {
            // verify required parameter 'submissionUnid' is not null or undefined
            if (submissionUnid === null || submissionUnid === undefined) {
                throw new RequiredError('submissionUnid','Required parameter submissionUnid was null or undefined when calling c914416433e1414bAa5699700362e032.');
            }
            const localVarPath = `/users/submissions/{submissionUnid}/results`
                .replace(`{${"submissionUnid"}}`, encodeURIComponent(String(submissionUnid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cef19b9e7fe24c86Aee70be88b0fe782(options: any = {}): RequestArgs {
            const localVarPath = `/users/submissions`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {CreateSubmission} createSubmission 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSubmission(token: string, createSubmission: CreateSubmission, options: any = {}): RequestArgs {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling createSubmission.');
            }
            // verify required parameter 'createSubmission' is not null or undefined
            if (createSubmission === null || createSubmission === undefined) {
                throw new RequiredError('createSubmission','Required parameter createSubmission was null or undefined when calling createSubmission.');
            }
            const localVarPath = `/users/submissions`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof createSubmission !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createSubmission !== undefined ? createSubmission : {}) : (createSubmission || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        d4b102edB07d49e9Ae7663500ee5c91a(options: any = {}): RequestArgs {
            const localVarPath = `/users/rated/submissions`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} cardID 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCard(cardID: string, token: string, options: any = {}): RequestArgs {
            // verify required parameter 'cardID' is not null or undefined
            if (cardID === null || cardID === undefined) {
                throw new RequiredError('cardID','Required parameter cardID was null or undefined when calling deleteCard.');
            }
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling deleteCard.');
            }
            const localVarPath = `/users/cards/{cardID}`
                .replace(`{${"cardID"}}`, encodeURIComponent(String(cardID)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {EditUser} editUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editUser(token: string, editUser: EditUser, options: any = {}): RequestArgs {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling editUser.');
            }
            // verify required parameter 'editUser' is not null or undefined
            if (editUser === null || editUser === undefined) {
                throw new RequiredError('editUser','Required parameter editUser was null or undefined when calling editUser.');
            }
            const localVarPath = `/users/`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof editUser !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(editUser !== undefined ? editUser : {}) : (editUser || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCards(token: string, options: any = {}): RequestArgs {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling getCards.');
            }
            const localVarPath = `/users/cards`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastUnpaidSubmission(token: string, options: any = {}): RequestArgs {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling getLastUnpaidSubmission.');
            }
            const localVarPath = `/users/unpaid/last`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRatedSubmissions(token: string, options: any = {}): RequestArgs {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling getRatedSubmissions.');
            }
            const localVarPath = `/users/rated/submissions`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubmissions(token: string, options: any = {}): RequestArgs {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling getSubmissions.');
            }
            const localVarPath = `/users/submissions`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(token: string, options: any = {}): RequestArgs {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling getUser.');
            }
            const localVarPath = `/users/`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserProfile(token: string, options: any = {}): RequestArgs {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling getUserProfile.');
            }
            const localVarPath = `/users/profile`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} submissionUnid 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserSubmissionResults(submissionUnid: string, token: string, options: any = {}): RequestArgs {
            // verify required parameter 'submissionUnid' is not null or undefined
            if (submissionUnid === null || submissionUnid === undefined) {
                throw new RequiredError('submissionUnid','Required parameter submissionUnid was null or undefined when calling getUserSubmissionResults.');
            }
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling getUserSubmissionResults.');
            }
            const localVarPath = `/users/submissions/{submissionUnid}/results`
                .replace(`{${"submissionUnid"}}`, encodeURIComponent(String(submissionUnid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {EditOptIns} editOptIns 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOptIns(token: string, editOptIns: EditOptIns, options: any = {}): RequestArgs {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling updateOptIns.');
            }
            // verify required parameter 'editOptIns' is not null or undefined
            if (editOptIns === null || editOptIns === undefined) {
                throw new RequiredError('editOptIns','Required parameter editOptIns was null or undefined when calling updateOptIns.');
            }
            const localVarPath = `/users/optins`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof editOptIns !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(editOptIns !== undefined ? editOptIns : {}) : (editOptIns || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _11ed4e5eDb9d45c584b7C8cfdc1ee79e(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesBaseResponse> {
            const localVarAxiosArgs = UsersApiAxiosParamCreator(configuration)._11ed4e5eDb9d45c584b7C8cfdc1ee79e(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _1e7fd670492d4c228ece812fef7429e7(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesBaseResponse> {
            const localVarAxiosArgs = UsersApiAxiosParamCreator(configuration)._1e7fd670492d4c228ece812fef7429e7(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} cardID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _24cb5c0eB4474bc28859940f3d783fa0(cardID: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesBaseResponse> {
            const localVarAxiosArgs = UsersApiAxiosParamCreator(configuration)._24cb5c0eB4474bc28859940f3d783fa0(cardID, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _58ceedf8893046aaA7c5012bbf108263(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesBaseResponse> {
            const localVarAxiosArgs = UsersApiAxiosParamCreator(configuration)._58ceedf8893046aaA7c5012bbf108263(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _838deda57c2940b69702F8be6818666d(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesBaseResponse> {
            const localVarAxiosArgs = UsersApiAxiosParamCreator(configuration)._838deda57c2940b69702F8be6818666d(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        a65a4c9c3d9d4c7892a8De07124ef7ae(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesBaseResponse> {
            const localVarAxiosArgs = UsersApiAxiosParamCreator(configuration).a65a4c9c3d9d4c7892a8De07124ef7ae(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {AddCard} addCard 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCard(token: string, addCard: AddCard, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesBaseResponse> {
            const localVarAxiosArgs = UsersApiAxiosParamCreator(configuration).addCard(token, addCard, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {AddFlagIssueDetails} addFlagIssueDetails 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFlagIssueDetails(token: string, addFlagIssueDetails: AddFlagIssueDetails, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesBaseResponse> {
            const localVarAxiosArgs = UsersApiAxiosParamCreator(configuration).addFlagIssueDetails(token, addFlagIssueDetails, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        b65695f32ff149e8Bf59Dcbd52927a46(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesBaseResponse> {
            const localVarAxiosArgs = UsersApiAxiosParamCreator(configuration).b65695f32ff149e8Bf59Dcbd52927a46(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} submissionUnid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c914416433e1414bAa5699700362e032(submissionUnid: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesBaseResponse> {
            const localVarAxiosArgs = UsersApiAxiosParamCreator(configuration).c914416433e1414bAa5699700362e032(submissionUnid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cef19b9e7fe24c86Aee70be88b0fe782(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesBaseResponse> {
            const localVarAxiosArgs = UsersApiAxiosParamCreator(configuration).cef19b9e7fe24c86Aee70be88b0fe782(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {CreateSubmission} createSubmission 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSubmission(token: string, createSubmission: CreateSubmission, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesSubmissionResponse> {
            const localVarAxiosArgs = UsersApiAxiosParamCreator(configuration).createSubmission(token, createSubmission, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        d4b102edB07d49e9Ae7663500ee5c91a(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesBaseResponse> {
            const localVarAxiosArgs = UsersApiAxiosParamCreator(configuration).d4b102edB07d49e9Ae7663500ee5c91a(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} cardID 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCard(cardID: string, token: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesBaseResponse> {
            const localVarAxiosArgs = UsersApiAxiosParamCreator(configuration).deleteCard(cardID, token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {EditUser} editUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editUser(token: string, editUser: EditUser, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesUserResponse> {
            const localVarAxiosArgs = UsersApiAxiosParamCreator(configuration).editUser(token, editUser, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCards(token: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesCardsResponse> {
            const localVarAxiosArgs = UsersApiAxiosParamCreator(configuration).getCards(token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastUnpaidSubmission(token: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesSubmissionResponse> {
            const localVarAxiosArgs = UsersApiAxiosParamCreator(configuration).getLastUnpaidSubmission(token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRatedSubmissions(token: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesDetailedSubmissionsResponse> {
            const localVarAxiosArgs = UsersApiAxiosParamCreator(configuration).getRatedSubmissions(token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubmissions(token: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesSubmissionsResponse> {
            const localVarAxiosArgs = UsersApiAxiosParamCreator(configuration).getSubmissions(token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(token: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesUserResponse> {
            const localVarAxiosArgs = UsersApiAxiosParamCreator(configuration).getUser(token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserProfile(token: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesUserProfileResponse> {
            const localVarAxiosArgs = UsersApiAxiosParamCreator(configuration).getUserProfile(token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} submissionUnid 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserSubmissionResults(submissionUnid: string, token: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesSubmissionResultsResponse> {
            const localVarAxiosArgs = UsersApiAxiosParamCreator(configuration).getUserSubmissionResults(submissionUnid, token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {EditOptIns} editOptIns 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOptIns(token: string, editOptIns: EditOptIns, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesBaseResponse> {
            const localVarAxiosArgs = UsersApiAxiosParamCreator(configuration).updateOptIns(token, editOptIns, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _11ed4e5eDb9d45c584b7C8cfdc1ee79e(options?: any): AxiosPromise<ResponsesBaseResponse> {
            return UsersApiFp(configuration)._11ed4e5eDb9d45c584b7C8cfdc1ee79e(options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _1e7fd670492d4c228ece812fef7429e7(options?: any): AxiosPromise<ResponsesBaseResponse> {
            return UsersApiFp(configuration)._1e7fd670492d4c228ece812fef7429e7(options)(axios, basePath);
        },
        /**
         * 
         * @param {string} cardID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _24cb5c0eB4474bc28859940f3d783fa0(cardID: string, options?: any): AxiosPromise<ResponsesBaseResponse> {
            return UsersApiFp(configuration)._24cb5c0eB4474bc28859940f3d783fa0(cardID, options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _58ceedf8893046aaA7c5012bbf108263(options?: any): AxiosPromise<ResponsesBaseResponse> {
            return UsersApiFp(configuration)._58ceedf8893046aaA7c5012bbf108263(options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _838deda57c2940b69702F8be6818666d(options?: any): AxiosPromise<ResponsesBaseResponse> {
            return UsersApiFp(configuration)._838deda57c2940b69702F8be6818666d(options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        a65a4c9c3d9d4c7892a8De07124ef7ae(options?: any): AxiosPromise<ResponsesBaseResponse> {
            return UsersApiFp(configuration).a65a4c9c3d9d4c7892a8De07124ef7ae(options)(axios, basePath);
        },
        /**
         * 
         * @param {string} token 
         * @param {AddCard} addCard 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCard(token: string, addCard: AddCard, options?: any): AxiosPromise<ResponsesBaseResponse> {
            return UsersApiFp(configuration).addCard(token, addCard, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} token 
         * @param {AddFlagIssueDetails} addFlagIssueDetails 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFlagIssueDetails(token: string, addFlagIssueDetails: AddFlagIssueDetails, options?: any): AxiosPromise<ResponsesBaseResponse> {
            return UsersApiFp(configuration).addFlagIssueDetails(token, addFlagIssueDetails, options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        b65695f32ff149e8Bf59Dcbd52927a46(options?: any): AxiosPromise<ResponsesBaseResponse> {
            return UsersApiFp(configuration).b65695f32ff149e8Bf59Dcbd52927a46(options)(axios, basePath);
        },
        /**
         * 
         * @param {string} submissionUnid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c914416433e1414bAa5699700362e032(submissionUnid: string, options?: any): AxiosPromise<ResponsesBaseResponse> {
            return UsersApiFp(configuration).c914416433e1414bAa5699700362e032(submissionUnid, options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cef19b9e7fe24c86Aee70be88b0fe782(options?: any): AxiosPromise<ResponsesBaseResponse> {
            return UsersApiFp(configuration).cef19b9e7fe24c86Aee70be88b0fe782(options)(axios, basePath);
        },
        /**
         * 
         * @param {string} token 
         * @param {CreateSubmission} createSubmission 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSubmission(token: string, createSubmission: CreateSubmission, options?: any): AxiosPromise<ResponsesSubmissionResponse> {
            return UsersApiFp(configuration).createSubmission(token, createSubmission, options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        d4b102edB07d49e9Ae7663500ee5c91a(options?: any): AxiosPromise<ResponsesBaseResponse> {
            return UsersApiFp(configuration).d4b102edB07d49e9Ae7663500ee5c91a(options)(axios, basePath);
        },
        /**
         * 
         * @param {string} cardID 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCard(cardID: string, token: string, options?: any): AxiosPromise<ResponsesBaseResponse> {
            return UsersApiFp(configuration).deleteCard(cardID, token, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} token 
         * @param {EditUser} editUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editUser(token: string, editUser: EditUser, options?: any): AxiosPromise<ResponsesUserResponse> {
            return UsersApiFp(configuration).editUser(token, editUser, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCards(token: string, options?: any): AxiosPromise<ResponsesCardsResponse> {
            return UsersApiFp(configuration).getCards(token, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastUnpaidSubmission(token: string, options?: any): AxiosPromise<ResponsesSubmissionResponse> {
            return UsersApiFp(configuration).getLastUnpaidSubmission(token, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRatedSubmissions(token: string, options?: any): AxiosPromise<ResponsesDetailedSubmissionsResponse> {
            return UsersApiFp(configuration).getRatedSubmissions(token, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubmissions(token: string, options?: any): AxiosPromise<ResponsesSubmissionsResponse> {
            return UsersApiFp(configuration).getSubmissions(token, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(token: string, options?: any): AxiosPromise<ResponsesUserResponse> {
            return UsersApiFp(configuration).getUser(token, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserProfile(token: string, options?: any): AxiosPromise<ResponsesUserProfileResponse> {
            return UsersApiFp(configuration).getUserProfile(token, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} submissionUnid 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserSubmissionResults(submissionUnid: string, token: string, options?: any): AxiosPromise<ResponsesSubmissionResultsResponse> {
            return UsersApiFp(configuration).getUserSubmissionResults(submissionUnid, token, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} token 
         * @param {EditOptIns} editOptIns 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOptIns(token: string, editOptIns: EditOptIns, options?: any): AxiosPromise<ResponsesBaseResponse> {
            return UsersApiFp(configuration).updateOptIns(token, editOptIns, options)(axios, basePath);
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public _11ed4e5eDb9d45c584b7C8cfdc1ee79e(options?: any) {
        return UsersApiFp(this.configuration)._11ed4e5eDb9d45c584b7C8cfdc1ee79e(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public _1e7fd670492d4c228ece812fef7429e7(options?: any) {
        return UsersApiFp(this.configuration)._1e7fd670492d4c228ece812fef7429e7(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} cardID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public _24cb5c0eB4474bc28859940f3d783fa0(cardID: string, options?: any) {
        return UsersApiFp(this.configuration)._24cb5c0eB4474bc28859940f3d783fa0(cardID, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public _58ceedf8893046aaA7c5012bbf108263(options?: any) {
        return UsersApiFp(this.configuration)._58ceedf8893046aaA7c5012bbf108263(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public _838deda57c2940b69702F8be6818666d(options?: any) {
        return UsersApiFp(this.configuration)._838deda57c2940b69702F8be6818666d(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public a65a4c9c3d9d4c7892a8De07124ef7ae(options?: any) {
        return UsersApiFp(this.configuration).a65a4c9c3d9d4c7892a8De07124ef7ae(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} token 
     * @param {AddCard} addCard 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public addCard(token: string, addCard: AddCard, options?: any) {
        return UsersApiFp(this.configuration).addCard(token, addCard, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} token 
     * @param {AddFlagIssueDetails} addFlagIssueDetails 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public addFlagIssueDetails(token: string, addFlagIssueDetails: AddFlagIssueDetails, options?: any) {
        return UsersApiFp(this.configuration).addFlagIssueDetails(token, addFlagIssueDetails, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public b65695f32ff149e8Bf59Dcbd52927a46(options?: any) {
        return UsersApiFp(this.configuration).b65695f32ff149e8Bf59Dcbd52927a46(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} submissionUnid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public c914416433e1414bAa5699700362e032(submissionUnid: string, options?: any) {
        return UsersApiFp(this.configuration).c914416433e1414bAa5699700362e032(submissionUnid, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public cef19b9e7fe24c86Aee70be88b0fe782(options?: any) {
        return UsersApiFp(this.configuration).cef19b9e7fe24c86Aee70be88b0fe782(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} token 
     * @param {CreateSubmission} createSubmission 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public createSubmission(token: string, createSubmission: CreateSubmission, options?: any) {
        return UsersApiFp(this.configuration).createSubmission(token, createSubmission, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public d4b102edB07d49e9Ae7663500ee5c91a(options?: any) {
        return UsersApiFp(this.configuration).d4b102edB07d49e9Ae7663500ee5c91a(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} cardID 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public deleteCard(cardID: string, token: string, options?: any) {
        return UsersApiFp(this.configuration).deleteCard(cardID, token, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} token 
     * @param {EditUser} editUser 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public editUser(token: string, editUser: EditUser, options?: any) {
        return UsersApiFp(this.configuration).editUser(token, editUser, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getCards(token: string, options?: any) {
        return UsersApiFp(this.configuration).getCards(token, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getLastUnpaidSubmission(token: string, options?: any) {
        return UsersApiFp(this.configuration).getLastUnpaidSubmission(token, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getRatedSubmissions(token: string, options?: any) {
        return UsersApiFp(this.configuration).getRatedSubmissions(token, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getSubmissions(token: string, options?: any) {
        return UsersApiFp(this.configuration).getSubmissions(token, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUser(token: string, options?: any) {
        return UsersApiFp(this.configuration).getUser(token, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUserProfile(token: string, options?: any) {
        return UsersApiFp(this.configuration).getUserProfile(token, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} submissionUnid 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUserSubmissionResults(submissionUnid: string, token: string, options?: any) {
        return UsersApiFp(this.configuration).getUserSubmissionResults(submissionUnid, token, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} token 
     * @param {EditOptIns} editOptIns 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public updateOptIns(token: string, editOptIns: EditOptIns, options?: any) {
        return UsersApiFp(this.configuration).updateOptIns(token, editOptIns, options)(this.axios, this.basePath);
    }

}


/**
 * WebhooksApi - axios parameter creator
 * @export
 */
export const WebhooksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _18bdade3690b4e1dA85dEf888b009466(options: any = {}): RequestArgs {
            const localVarPath = `/webhooks/hubspot/emails`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hubspotEmailHook(options: any = {}): RequestArgs {
            const localVarPath = `/webhooks/hubspot/emails`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WebhooksApi - functional programming interface
 * @export
 */
export const WebhooksApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _18bdade3690b4e1dA85dEf888b009466(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesBaseResponse> {
            const localVarAxiosArgs = WebhooksApiAxiosParamCreator(configuration)._18bdade3690b4e1dA85dEf888b009466(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hubspotEmailHook(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesBaseResponse> {
            const localVarAxiosArgs = WebhooksApiAxiosParamCreator(configuration).hubspotEmailHook(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * WebhooksApi - factory interface
 * @export
 */
export const WebhooksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _18bdade3690b4e1dA85dEf888b009466(options?: any): AxiosPromise<ResponsesBaseResponse> {
            return WebhooksApiFp(configuration)._18bdade3690b4e1dA85dEf888b009466(options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hubspotEmailHook(options?: any): AxiosPromise<ResponsesBaseResponse> {
            return WebhooksApiFp(configuration).hubspotEmailHook(options)(axios, basePath);
        },
    };
};

/**
 * WebhooksApi - object-oriented interface
 * @export
 * @class WebhooksApi
 * @extends {BaseAPI}
 */
export class WebhooksApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhooksApi
     */
    public _18bdade3690b4e1dA85dEf888b009466(options?: any) {
        return WebhooksApiFp(this.configuration)._18bdade3690b4e1dA85dEf888b009466(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhooksApi
     */
    public hubspotEmailHook(options?: any) {
        return WebhooksApiFp(this.configuration).hubspotEmailHook(options)(this.axios, this.basePath);
    }

}


