import React from 'react';
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import {Formik, FormikValues} from "formik";
import * as yup from 'yup';
import withApiHandler, {ErrorHandler, InjectedApiHandlerProps} from "../hocs/WithApiHandler";
import withSystemState, {InjectedSystemStateProps} from "../hocs/WithSystemState";
import '../../sass/components/SubmitTrackForm.scss';
import asApiClient from "../../api_clients/as_client/ASApiClient";
import {AddFlagIssueDetails} from "../../api_clients/as_client/src";
import Spinner from "react-bootstrap/Spinner";

interface FlagIssueFormBaseProps{
    onSuccess:()=>void;
}

type FlagIssueFormProps = FlagIssueFormBaseProps & InjectedApiHandlerProps & InjectedSystemStateProps;

interface FlagIssueFormState{
    submitting: boolean
}

const formSchema = yup.object({
    device: yup.string().required(),
    browser: yup.string().required(),
    problem: yup.string().required()
});

const problems: ProblemMap = {
    problem1: "I click play, but don’t hear anything",
    problem2: "I see an error that the URL is not valid",
    problem3: "I see an error that the URL set to private",
    problem4: "other or I’m not sure"
};

interface ProblemMap {
    [key: string]: string
}

class FlagIssueForm extends React.Component<FlagIssueFormProps, FlagIssueFormState> {
    state={
        submitting: false
    };

    submit = (formData:any) =>{
        let problemsArr = [] as string[];
        let arr = formData.problem.split("|");
        arr.forEach((problem:string)=>{
            problemsArr.push(problems[problem])
        });
        let data = {
            problems: problemsArr.join("|"),
            device: formData.device,
            browser: formData.browser,
        } as AddFlagIssueDetails;
        this.setState({
            submitting:true
        });
        this.props.handleRequest(asApiClient.usersApi.addFlagIssueDetails(this.props.systemState.token,data),()=>{
            this.props.onSuccess();
        },undefined,()=>{
            this.setState({submitting:false});
        });
    };

    getSubmitButton = (submitHandler:any) =>{

        return(
            <Button disabled={this.state.submitting} variant="primary" size="lg" block onClick={submitHandler}>{!this.state.submitting?<span>Submit</span>: <><Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
            />
            Submitting...</>}</Button>
        );
    };

    onCustomCheckBoxHandle = (e:any, setFieldValue:any,values:FormikValues) =>{
        let array = [] as string[];
        if(values.problem!==''){
            array = values.problem.split("|");
        }
        if(e.target.checked){
            array.push(e.target.id);
            let val = array.join("|");
            setFieldValue("problem",val);
        }else{
            let newArr = array.filter((a:string) => a !== e.target.id);
            let val = newArr.join("|");
            setFieldValue("problem",val);
        }
    };

    render(){
        return(
            <div className={"optin-form wn-form"}>
                <Formik
                    validationSchema={formSchema}
                    onSubmit={this.submit}
                    enableReinitialize={true}
                    initialValues={{device:'',browser:'',problem:''}}
                >
                    {({
                          handleSubmit,
                          handleChange,
                          handleBlur,
                          values,
                          touched,
                          isValid,
                          errors,
                          setFieldValue,
                      }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Form.Row>
                                <Form.Group as={Col} xs="12">
                                    <Form.Label className={"form-title"}>We notice you’ve reported more tracks than most people. Please tell us more?</Form.Label>
                                    <Col sm={12} className={"form-radio-group"}>
                                        <Form.Label className={"form-title"}>I'm using</Form.Label>
                                        <Form.Check
                                            type="radio"
                                            label={<span>An iPhone</span>}
                                            name="device"
                                            id="deviceIphone"
                                            value={"iphone"}
                                            isInvalid={!!errors.device}
                                            feedback={"this field is required"}
                                            onChange={handleChange}
                                        />
                                        <Form.Check
                                            type="radio"
                                            label={<span>An Android Phone</span>}
                                            name="device"
                                            id="deviceAndroid"
                                            value={"android"}
                                            isInvalid={!!errors.device}
                                            feedback={"this field is required"}
                                            onChange={handleChange}
                                        />
                                        <Form.Check
                                            type="radio"
                                            label={<span>A Laptop/Desktop</span>}
                                            name="device"
                                            id="devicePC"
                                            value={"laptop/desktop"}
                                            isInvalid={!!errors.device}
                                            feedback={"this field is required"}
                                            onChange={handleChange}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.device}
                                        </Form.Control.Feedback>
                                    </Col>
                                    <Col sm={12} className={"form-radio-group"}>
                                        <Form.Label className={"form-title"}>I'm using</Form.Label>
                                        <Form.Check
                                            type="radio"
                                            label={<span>Chrome</span>}
                                            name="browser"
                                            id="browserChrome"
                                            value={"chrome"}
                                            isInvalid={!!errors.browser}
                                            feedback={"this field is required"}
                                            onChange={handleChange}
                                        />
                                        <Form.Check
                                            type="radio"
                                            label={<span>Safari</span>}
                                            name="browser"
                                            id="browserSafari"
                                            value={"safari"}
                                            isInvalid={!!errors.browser}
                                            feedback={"this field is required"}
                                            onChange={handleChange}
                                        />
                                        <Form.Check
                                            type="radio"
                                            label={<span>Firefox</span>}
                                            name="browser"
                                            id="browserFirefox"
                                            value={"firefox"}
                                            isInvalid={!!errors.browser}
                                            feedback={"this field is required"}
                                            onChange={handleChange}
                                        />
                                        <Form.Check
                                            type="radio"
                                            label={<span>other or I’m not sure</span>}
                                            name="browser"
                                            id="browserOther"
                                            value={"other or I’m not sure"}
                                            isInvalid={!!errors.browser}
                                            feedback={"this field is required"}
                                            onChange={handleChange}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.browser}
                                        </Form.Control.Feedback>
                                    </Col>

                                    <Col sm={12} className={"form-radio-group"}>
                                        <Form.Label className={"form-title"}>What seems to be the problem?</Form.Label>
                                        <Form.Check
                                            type="checkbox"
                                            label={<span>I click play, but don’t hear anything</span>}
                                            name="problem1"
                                            id="problem1"
                                            isInvalid={!!errors.problem}
                                            feedback={"At least one of these fields is required"}
                                            onChange={(e:any)=>this.onCustomCheckBoxHandle(e,setFieldValue,values)}
                                        />
                                        <Form.Check
                                            type="checkbox"
                                            label={<span>I see an error that the URL is not valid</span>}
                                            name="problem2"
                                            id="problem2"
                                            isInvalid={!!errors.problem}
                                            feedback={"At least one of these fields is required"}
                                            onChange={(e:any)=>this.onCustomCheckBoxHandle(e,setFieldValue,values)}
                                        />
                                        <Form.Check
                                            type="checkbox"
                                            label={<span>I see an error that the URL set to private</span>}
                                            name="problem3"
                                            id="problem3"
                                            isInvalid={!!errors.problem}
                                            feedback={"At least one of these fields is required"}
                                            onChange={(e:any)=>this.onCustomCheckBoxHandle(e,setFieldValue,values)}
                                        />
                                        <Form.Check
                                            type="checkbox"
                                            label={<span>other or I’m not sure</span>}
                                            name="problem4"
                                            id="problem4"
                                            value={"other or I’m not sure"}
                                            isInvalid={!!errors.problem}
                                            onChange={(e:any)=>this.onCustomCheckBoxHandle(e,setFieldValue,values)}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.problem}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                            </Form.Row>
                            {this.getSubmitButton(handleSubmit)}
                        </Form>
                    )}
                </Formik>
            </div>
        );
    }
}

export default withSystemState(withApiHandler(FlagIssueForm,ErrorHandler.TOAST));