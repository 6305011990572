import React from 'react';
import { BrowserRouter as Router, Route,Switch } from "react-router-dom";
import LandingPage from "./LandingPage";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import ProblemSubmissionsPage from "./ProblemSubmissionsPage";
import AdminLogin from "./AdminLogin";
import AdminRestrictedRoute from "../components/misc/AdminRestrictedRoute";
import NoPageFountPage from "./NoPageFound";
import ResetPassword from "./ResetPasswordPage";
import AdminsLeaderBoardsPage from "./AdminsLeaderboardsPage";
import AdminsFlagIssueDetailsPage from "./AdminsFlagIssueDetailsPage";
import AdminHomePage from "./AdminHomePage";

class App extends React.Component {

    render() {
        return(
            <Router>
                <ToastContainer />
                <Switch>
                    <Route path="/" exact={true} component={LandingPage} />
                    <Route path="/admins" exact={true} component={AdminLogin} />
                    <Route path="/resetPassword" exact component={ResetPassword} />
                    <AdminRestrictedRoute path={"/admins/home"} exact={true} component={AdminHomePage}/>
                    <AdminRestrictedRoute path={"/admins/problemSubmissions"} exact={true} component={ProblemSubmissionsPage}/>
                    <AdminRestrictedRoute path="/admins/leaderboards" exact component={AdminsLeaderBoardsPage} />
                    <AdminRestrictedRoute path="/admins/flagIssues" exact component={AdminsFlagIssueDetailsPage} />
                    <Route path="*">
                        <NoPageFountPage/>
                    </Route>
                </Switch>
            </Router>
        );
    }
}

export default App;