import {UPDATE_SYSTEM_TOKEN, SystemActionTypes, SystemState, UPDATE_ADMIN_SYSTEM_TOKEN, UPDATE_SUBMISSION} from './types'

const initialState: SystemState = {
    token: "",
    adminToken: "",
    submission: {}
};

export function systemReducer(state = initialState, action: SystemActionTypes): SystemState {
    switch (action.type) {
        case UPDATE_SUBMISSION:{
            let newState = {...state};
            newState.submission = action.payload;
            return newState
        }
        case UPDATE_SYSTEM_TOKEN: {
            let newState = {...state};
            if(action.payload.length>0) {
                localStorage.setItem('token', action.payload);
            }else{
                localStorage.removeItem('token');
            }
            newState.token = action.payload;
            return newState
        }
        case UPDATE_ADMIN_SYSTEM_TOKEN: {
            let newState = {...state};
            if(action.payload.length>0) {
                localStorage.setItem('adminToken', action.payload);
            }else{
                localStorage.removeItem('adminToken');
            }
            newState.adminToken = action.payload;
            return newState
        }
        default:
            return state
    }
}