import React from 'react';
import {RouteComponentProps, withRouter} from "react-router-dom";
import '../sass/components/AdminFlagIssueDetailsPage.scss';
import '../sass/common.scss';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-datetime/css/react-datetime.css';
import withSystemState, {InjectedSystemStateProps} from "../components/hocs/WithSystemState";
import withApiHandler, {ErrorHandler, InjectedApiHandlerProps} from "../components/hocs/WithApiHandler";
import asApiClient from "../api_clients/as_client/ASApiClient";
import {AxiosResponse} from "axios";
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import {MiscFlagIssueDetails, ResponsesAdminFlagIssueDetailsResponse} from "../api_clients/as_client/src";
import Table from "react-bootstrap/Table";
import Moment from "react-moment";
import * as _ from "lodash";
import ReactPaginate from 'react-paginate';
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import {FormControl} from "react-bootstrap";
// @ts-ignore
import LoadingOverlay from 'react-loading-overlay';

export type AdminsFlagIssueDetailsPageProps = RouteComponentProps & InjectedSystemStateProps & InjectedApiHandlerProps;
interface AdminsFlagIssueDetailsPageState {
    loading: boolean;
    flagIssues: MiscFlagIssueDetails[];
    pagedFlagIssues: MiscFlagIssueDetails[][];
    page: number;
    searchTerm: string;
}

class AdminsFlagIssueDetailsPage extends React.Component<AdminsFlagIssueDetailsPageProps,AdminsFlagIssueDetailsPageState> {

    state={
        loading:false,
        flagIssues: [] as MiscFlagIssueDetails[],
        pagedFlagIssues: [[]],
        page: 0,
        searchTerm: '',
    };

    componentDidMount(): void {
        this.loadDetails();
    }

    loadDetails = (email?:string) =>{
        this.setState({loading:true});
        this.props.handleRequest(asApiClient.adminsApi.getAdminFlagIssueDetails(this.props.systemState.adminToken,email),(response:AxiosResponse<ResponsesAdminFlagIssueDetailsResponse>)=>{
            if(response.data.data.details!==null && response.data.data.details!==undefined){
                if(response.data.data.details!=null && response.data.data.details.length>0){
                    let pagedDetails = _.chunk(response.data.data.details,20);
                    this.setState({pagedFlagIssues:pagedDetails,page:0, flagIssues:response.data.data.details})
                }else{
                    this.setState({pagedFlagIssues:[[]],page:0, flagIssues:[]});
                }
            }
        },undefined,()=>{
            this.setState({loading:false});
        });
    };

    searchWithTerm = () =>{
        this.loadDetails(this.state.searchTerm);
    };

    clearTerm = () =>{
        this.setState({searchTerm:''},()=>{
            this.loadDetails();
        })
    };

    render() {
        return(
            <LoadingOverlay
                active={this.state.loading}
                spinner
                text='Loading...'
            >
                <div className={"admin-flagIssueDetails"}>
                    <div className={"search-details"}>
                        <InputGroup className="mb-3">
                            <FormControl
                                placeholder="User Email"
                                aria-label="User Email"
                                onChange={(e:any)=>{this.setState({searchTerm:e.target.value})}}
                                value={this.state.searchTerm}
                            />
                            <InputGroup.Append>
                                <Button variant="primary" onClick={this.searchWithTerm}>Go</Button>
                                <Button variant="outline-secondary" onClick={this.clearTerm}>Clear</Button>
                            </InputGroup.Append>
                        </InputGroup>
                    </div>
                    {this.state.flagIssues.length<=0?null:<div className={"pagination-container"}><ReactPaginate forcePage={this.state.page} onPageChange={(selectedItem: { selected: number })=>this.setState({page:selectedItem.selected})} previousLabel={"<"} nextLabel={">"} breakClassName={"page-item"} breakLinkClassName={"page-link"} activeClassName={"active"} containerClassName={"pagination"} previousLinkClassName={"page-link"} previousClassName={"page-item"} nextClassName={"page-item"} pageLinkClassName={"page-link"} nextLinkClassName={"page-link"} pageClassName={"page-item"} pageCount={this.state.pagedFlagIssues.length} pageRangeDisplayed={3} marginPagesDisplayed={2}/></div>}
                    <Table responsive striped bordered hover variant="dark">
                        <thead>
                        <tr>
                            <th>Time</th>
                            <th>User Name</th>
                            <th>User Email</th>
                            <th>Device</th>
                            <th>Browser</th>
                            <th>Problem</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.pagedFlagIssues[this.state.page].map((entry:MiscFlagIssueDetails,index:number)=>{
                            return(
                                <tr key={"artist-entry-"+index}>
                                    <td><Moment local format="MM/DD/YYYY hh:mm">{entry.createTime}</Moment></td>
                                    <td>{entry.userName}</td>
                                    <td>{entry.userEmail}</td>
                                    <td>{entry.device}</td>
                                    <td>{entry.browser}</td>
                                    <td className={"problem"}>{entry.problems}</td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </Table>
                </div>
            </LoadingOverlay>
        );
    }
}

export default withSystemState(withRouter(withApiHandler(AdminsFlagIssueDetailsPage,ErrorHandler.TOAST)));