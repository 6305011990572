import React from 'react';
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import {AxiosResponse} from "axios";
import {Formik} from "formik";
import * as yup from 'yup';
import '../sass/common.scss';
import '../sass/components/LoginForm.scss';
import '../sass/components/AdminLoginForm.scss';
import withApiHandler, {ErrorHandler, InjectedApiHandlerProps} from "../components/hocs/WithApiHandler";
import withSystemState, {InjectedSystemStateProps} from "../components/hocs/WithSystemState";
import asApiClient from "../api_clients/as_client/ASApiClient";
import {RouteComponentProps, withRouter} from "react-router";
import {ResponsesLoginResponse} from "../api_clients/as_client/src";

interface AdminLoginPageBaseProps{

}

type AdminLoginPageProps = AdminLoginPageBaseProps & InjectedApiHandlerProps & InjectedSystemStateProps & RouteComponentProps;

interface AdminLoginPageState{
}

const loginSchema = yup.object({
    email: yup.string().required(),
    password: yup.string().required(),
});

class AdminLoginPage extends React.Component<AdminLoginPageProps, AdminLoginPageState> {
    state={

    };

    submit = (formData:any) =>{
        this.props.handleRequest(asApiClient.adminsApi.adminLogin(formData),(response:AxiosResponse<ResponsesLoginResponse>)=>{
            this.handleLoginResponse(response)
        })
    };

    handleLoginResponse = (response:AxiosResponse<ResponsesLoginResponse>) =>{
        if(this.props.updateAdminToken !== undefined) {
            this.props.updateAdminToken(response.data.data.token);
            this.props.history.push("/admins/home");
        }
    };

    getSubmitMenu = (submitHandler:any) =>{
        return (
            <div>
                <Button variant="primary" size="lg" block onClick={submitHandler}>Login</Button>
            </div>
        );
    };

    render(){
        return(
            <div className={"admin-login login-form wn-form"}>
                <Formik
                    validationSchema={loginSchema}
                    onSubmit={this.submit}
                    initialValues={{
                        email: '',
                        password: '',
                    }}
                >
                    {({
                          handleSubmit,
                          handleChange,
                          handleBlur,
                          values,
                          touched,
                          isValid,
                          errors,
                      }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Form.Row>

                                <Form.Group as={Col} xs="12" controlId="validationEmail">
                                    <Form.Label className={"sr-only"}>enter Email</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="email"
                                        placeholder={"enter email"}
                                        value={values.email}
                                        onChange={handleChange}
                                        isInvalid={!!errors.email}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.email}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} xs="12" controlId="validationPassword">
                                    <Form.Label className={"sr-only"}>Enter Password</Form.Label>
                                    <Form.Control
                                        type="password"
                                        name="password"
                                        placeholder={"enter password"}
                                        value={values.password}
                                        onChange={handleChange}
                                        isInvalid={!!errors.password}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.password}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row>
                            {this.getSubmitMenu(handleSubmit)}
                        </Form>
                    )}
                </Formik>
            </div>
        );
    }
}

export default withSystemState(withRouter(withApiHandler(AdminLoginPage,ErrorHandler.TOAST)));