import React from 'react';
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import {AxiosResponse} from "axios";
import {Formik} from "formik";
import * as yup from 'yup';
import withApiHandler, {ErrorHandler, InjectedApiHandlerProps} from "../hocs/WithApiHandler";
import withSystemState, {InjectedSystemStateProps} from "../hocs/WithSystemState";
import asApiClient from "../../api_clients/as_client/ASApiClient";
import '../../sass/common.scss';
import '../../sass/components/OptinForm.scss';
import {DbmodelsUser, EditOptIns, ResponsesBaseResponse} from "../../api_clients/as_client/src";

interface OptInFormBaseProps{
    onSuccess: ()=>void;
    user: DbmodelsUser|null;
    submissionUnid: string;
}

type OptInFormProps = OptInFormBaseProps & InjectedApiHandlerProps & InjectedSystemStateProps;

interface OptInFormState{
}

const optionSchema = yup.object({
    enterContests: yup.string().required(),
    firstName: yup.string().when('enterContests', {
        is: "yes",
        then: yup.string().required(),
        otherwise: yup.string(),
    }),
    lastName: yup.string().when('enterContests', {
        is: "yes",
        then: yup.string().required(),
        otherwise: yup.string(),
    }),
    zip: yup.string().when('enterContests', {
        is: "yes", // alternatively: (val) => val == true
        then: yup.string().required(),
        otherwise: yup.string(),
    }),
    email: yup.string().when('enterContests', {
        is: "yes", // alternatively: (val) => val == true
        then: yup.string().required(),
        otherwise: yup.string(),
    }),
    acceptsMarketing: yup.bool()
});

class OptInForm extends React.Component<OptInFormProps, OptInFormState> {
    state={
    };

    submit = (formData:any) =>{
        let submitData = {
            submissionUnid: this.props.submissionUnid,
            enterContests: formData.enterContests==="yes",
            acceptsMarketing: formData.acceptsMarketing,
            contactInfo:{
                contactFirstName: formData.firstName,
                contactLastName: formData.lastName,
                contactEmail: formData.email,
                contactZip: formData.zip
            }
        } as EditOptIns;
        this.props.handleRequest(asApiClient.usersApi.updateOptIns(this.props.systemState.token,submitData),(response:AxiosResponse<ResponsesBaseResponse>)=>{
            this.props.onSuccess();
        })
    };

    getSubmitButton = (submitHandler:any) =>{
        return(
            <Button variant="primary" size="lg" block onClick={submitHandler}>Let's Go!</Button>
        );
    };

    getInitialValues = () =>{
        let firstName = '';
        let lastName = '';
        let email = '';
        let zip = '';
        if(this.props.user!=null){
            let user = this.props.user
            if(user.contactFirstName!=null){
                firstName = user.contactFirstName
            }
            if(user.contactLastName!=null){
                lastName = user.contactLastName
            }
            if(user.contactEmail!=null){
                email = user.contactEmail
            }
            if(user.contactZip!=null){
                zip = user.contactZip
            }
        }

        return {
            enterContests:'',
            firstName: firstName,
            lastName: lastName,
            zip:zip,
            email:email,
            acceptsMarketing: false
        }
    };

    render(){
        return (
            <div className={"optin-form wn-form"}>
                <Formik
                    validationSchema={optionSchema}
                    onSubmit={this.submit}
                    enableReinitialize={true}
                    initialValues={this.getInitialValues()}
                >
                    {({
                          handleSubmit,
                          handleChange,
                          handleBlur,
                          values,
                          touched,
                          isValid,
                          errors,
                      }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Form.Row>
                                <Form.Group as={Col} xs="12">
                                    <Form.Label className={"form-title"}>Enter this track in Who's Next music submission contests?</Form.Label>
                                    <Col sm={10} className={"form-radio-group"}>
                                        <Form.Check
                                            type="radio"
                                            label={<span>Yes, <span className={"light"}>enter this track in one or more Who's Next contests (until the track has received the required number of ratings)</span></span>}
                                            name="enterContests"
                                            id="enterContestsYes"
                                            value={"yes"}
                                            isInvalid={!!errors.enterContests}
                                            feedback={"this field is required"}
                                            onChange={handleChange}
                                        />
                                        <Form.Check
                                            className={"no-margin"}
                                            type="radio"
                                            label={<span>No, <span className={"light"}> I only want feedback. Do not enter this track in any Who's Next contests</span></span>}
                                            name="enterContests"
                                            id="enterContestsNo"
                                            value={"no"}
                                            isInvalid={!!errors.enterContests}
                                            feedback={"this field is required"}
                                            onChange={handleChange}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.enterContests}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                            </Form.Row>
                                {values.enterContests==="yes"?<>
                                <Form.Row>
                                    <Form.Label className={"form-title"}>Contact Information</Form.Label>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} md="6" controlId="firstNameGroup">
                                        <Form.Label>First Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="firstName"
                                            value={values.firstName}
                                            onChange={handleChange}
                                            isInvalid={!!errors.firstName}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.firstName}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="6" controlId="lastNameGroup">
                                        <Form.Label>Last Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="lastName"
                                            value={values.lastName}
                                            onChange={handleChange}
                                            isInvalid={!!errors.lastName}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.lastName}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} xs="8" controlId="validationEmail">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="email"
                                            value={values.email}
                                            onChange={handleChange}
                                            isInvalid={!!errors.email}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.email}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="zipCodeGroup">
                                        <Form.Label>Zip Code</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="zip"
                                            value={values.zip}
                                            onChange={handleChange}
                                            isInvalid={!!errors.zip}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.zip}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Form.Row></>:null}
                            <Form.Row>
                                {this.props.user===null||!this.props.user.acceptsMarketing?<Form.Group as={Col} xs="12">
                                    <Form.Check
                                        className={"css-checkbox"}
                                        required
                                        name="acceptsMarketing"
                                        label="Keep me informed of exclusive news and offers"
                                        onChange={handleChange}
                                        checked={values.acceptsMarketing}
                                        isInvalid={!!errors.acceptsMarketing}
                                        feedback={errors.acceptsMarketing}
                                        id="validationAcceptsMarketing"
                                    />
                                </Form.Group>:null}
                            </Form.Row>
                            {this.getSubmitButton(handleSubmit)}
                        </Form>
                    )}
                </Formik>
            </div>
        );
    }
}

export default withSystemState(withApiHandler(OptInForm,ErrorHandler.TOAST));