import * as React from "react";
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import App from "./pages/App";
import {createStore} from "redux";
import {rootReducer} from "./store";
import {Provider} from "react-redux";
import {updateAdminToken, updateToken} from "./store/system/actions";
import config from "./config/config";
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import TagManager from 'react-gtm-module'

const options = {
    autoConfig: true, 	// set pixel's autoConfig
    debug: false, 		// enable logs
};
ReactPixel.init(config.fb.pixelID, undefined, options);

ReactGA.initialize(config.googleAnalytics.id);
ReactGA.plugin.require("ecommerce",{ path: '/log', debug: true });
ReactGA.pageview(window.location.pathname + window.location.search);

// @ts-ignore
export var _hsq = window._hsq = window._hsq || [];

const tagManagerArgs = {
    gtmId: config.googleGMT.id
};
TagManager.initialize(tagManagerArgs);

const store = createStore(rootReducer);

const token = localStorage.getItem('token');
if(token){
    store.dispatch(updateToken(token));
}

const adminToken = localStorage.getItem('adminToken');
if(adminToken){
    store.dispatch(updateAdminToken(adminToken));
}

FB.init({
    appId: config.fb.appID,
    version          : 'v6.0'
});
ReactPixel.pageView();

ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
