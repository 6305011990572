import React, {Component} from 'react';
import  "../../sass/components/UserCards.scss";
import  "../../sass/common.scss";
import Button from "react-bootstrap/Button";
import {MiscCard} from "../../api_clients/as_client/src";

interface UserCardsProps {
    cards: MiscCard[];
    onSelected?: (card:MiscCard)=>void;
    onDelete?: (card:MiscCard)=>void;
}

class UserCards extends Component<UserCardsProps,any> {

    onSelected = (card:MiscCard)=>{
        if(!(this.props.onSelected===null || this.props.onSelected===undefined)){
            this.props.onSelected(card);
        }
    };

    onDelete = (card:MiscCard) =>{
        if(!(this.props.onDelete===null || this.props.onDelete===undefined)){
            this.props.onDelete(card)
        }
    };

    getDeleteButton = (card:MiscCard):React.ReactNode =>{
        if(!(this.props.onDelete===null || this.props.onDelete===undefined)){
            return(
                <Button className="delete-button" variant={"danger"} onClick={(e:any)=>{e.stopPropagation();this.onDelete(card)}}>Delete</Button>
            );
        }
        return null;
    };

    render() {
        return (
            <div className={"wn-list user-cards"}>
                {this.props.cards.map((card:MiscCard)=>{
                    return(
                        <div className={"item" + (!(this.props.onSelected===null || this.props.onSelected===undefined)?" action":"")} key={"card-"+card.unid} onClick={()=>this.onSelected(card)}>
                            <div className={"last4"}>{card.brand} ending in {card.last4}</div>
                            <div className={"expiration"}>Expires: {card.expMonth}/{card.expYear}</div>
                            {this.getDeleteButton(card)}
                        </div>
                    );
                })}
            </div>
        );
    }
}

export default UserCards;