import React from 'react';
import {RouteComponentProps, withRouter} from "react-router-dom";
import '../sass/components/FlaggedSubmissionsPage.scss';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import withSystemState, {InjectedSystemStateProps} from "../components/hocs/WithSystemState";
import withApiHandler, {ErrorHandler, InjectedApiHandlerProps} from "../components/hocs/WithApiHandler";
import asApiClient from "../api_clients/as_client/ASApiClient";
import {AxiosResponse} from "axios";
import SubmissionPlayer from "../components/misc/SubmissionPlayer";
import {Formik} from "formik";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import * as yup from "yup";
import Button from "react-bootstrap/Button";
import NavBar, {NavbarAction} from "../components/navigation/NavBar";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import Spinner from "react-bootstrap/Spinner";
import {
    DbmodelsContest,
    MiscProblemSubmission,
    ResponsesBaseResponse,
    ResponsesProblemSubmissionsResponse, SaveProblemSubmission
} from "../api_clients/as_client/src";
// @ts-ignore
import LoadingOverlay from 'react-loading-overlay';
import Moment from "react-moment";
import Table from "react-bootstrap/Table";
import {getContestTitle, getProblemSubmissionTitle} from "./AdminHomePage";
import {ModalEntry} from "./LandingPage";

export type ProblemSubmissionsPageProps = ProblemSubmissionsBaseProps & RouteComponentProps & InjectedSystemStateProps & InjectedApiHandlerProps;

interface ProblemSubmissionsBaseProps{
    openModal: (modal: ModalEntry)=>void;
    closeModal: ()=>void;
}

interface ProblemSubmissionsPageState {
    loading: boolean;
    submissions: MiscProblemSubmission[];
    type: string;
}

const formSchema = yup.object({
    link: yup.string().required(),
    problem: yup.string(),
    status: yup.number(),
    removeFlags: yup.bool()
});

class ProblemSubmissionsPage extends React.Component<ProblemSubmissionsPageProps,ProblemSubmissionsPageState> {

    state={
        loading:false,
        submissions: [] as MiscProblemSubmission[],
        type:"flagged",
    };

    componentDidMount(): void {
        this.loadProblemSubmissions("flagged");
    }

    loadProblemSubmissions = (type:string) =>{
        this.setState({loading:true});
        this.props.handleRequest(asApiClient.adminsApi.getProblemSubmissions(this.props.systemState.adminToken,type),(response:AxiosResponse<ResponsesProblemSubmissionsResponse>)=>{
            if(response.data.data.submissions!==null) {
                this.setState({submissions: response.data.data.submissions});
            }
        },undefined,()=>{
            this.setState({loading:false});
        });
    };

    submit = (formData:any,submission: MiscProblemSubmission, done?:()=>void) =>{
        this.setState({loading:true});
        let request = formData as SaveProblemSubmission;
        request.status = Number(formData.status);
        this.props.handleRequest(asApiClient.adminsApi.saveProblemSubmission(submission.submissionUnid as string,this.props.systemState.adminToken,request),(response:AxiosResponse<ResponsesBaseResponse>)=>{
            this.props.closeModal();
            this.loadProblemSubmissions(this.state.type);
        },undefined,()=>{
            this.setState({loading:false});
            if(done!==undefined){
                done();
            }
        })
    };

    openSubmission = (submission:MiscProblemSubmission) =>{
        this.props.openModal({
            title: getProblemSubmissionTitle(),
            body: <ProblemSubmission submit={this.submit} submission={submission} type={this.state.type}/>
        })
    };

    getPageContent = () =>{
        if(this.state.loading){
            return null;
        }

        if(this.state.submissions.length<=0){
            return(
                <div>
                    No {this.state.type==="flagged"?"Flagged":"Disabled"} Submissions
                </div>
            );
        }

        return (
            <Table responsive striped bordered hover variant="dark">
                <thead>
                <tr>
                    {this.state.type==="flagged"?<th># of Flags</th>:null}
                    <th>Track Name</th>
                    <th>Problem</th>
                </tr>
                </thead>
                <tbody>
                {this.state.submissions.map((entry:MiscProblemSubmission,index:number)=>{
                    return(
                        <tr key={"contest-entry-"+index} onClick={()=>this.openSubmission(entry)}>
                            {this.state.type==="flagged"?<td>{entry.flagCount}</td>:null}
                            <td>{entry.submissionTitle}</td>
                            <td>{entry.submissionProblem}</td>
                        </tr>
                    );
                })}
                </tbody>
            </Table>
        );
    };

    typeChange = (value:string) =>{
        this.setState({type:value, submissions: []});
        if(value==="flagged"||value==="disabled"){
            this.loadProblemSubmissions(value)
        }
    };

    render() {
        return(
            <LoadingOverlay
                active={this.state.loading}
                spinner
                text='Loading...'
            >
                <div className={"flagged-submissions"}>
                    <ToggleButtonGroup className={"type-selector"} type="radio" name="options" value={this.state.type} onChange={this.typeChange}>
                        <ToggleButton value={"flagged"}>Flagged Submissions</ToggleButton>
                        <ToggleButton value={"disabled"}>Disabled Submissions</ToggleButton>
                    </ToggleButtonGroup>
                    {this.getPageContent()}
                </div>
            </LoadingOverlay>
        );
    }
}

interface ProblemSubmissionState {
    loading: boolean;
    alteredLink: string,
}

interface ProblemSubmissionProps {
    submission: MiscProblemSubmission;
    type: string;
    submit: (formData:any, submission:MiscProblemSubmission, done?:()=>void)=>void;
}

class ProblemSubmission extends React.Component<ProblemSubmissionProps,ProblemSubmissionState> {

    state={
        loading:false,
        alteredLink:''
    };

    getInitialValues = () =>{
        return {
            link: this.props.submission.submissionLink,
            problem: this.props.submission.submissionProblem,
            removeFlags: false,
            status: this.props.submission.submissionStatus
        }
    };

    onCustomHandleChange = (e:any, formikChangeHandler:any) =>{
        if(e.target.id === "validationLink"){
            let link = e.target.value;
            this.setState({
                alteredLink: link
            });
        }
        formikChangeHandler(e);
    };

    getSubmitMenu = (submitHandler:any) =>{
        return (
            <div>
                <Button variant="primary" size="lg" block onClick={submitHandler} disabled={this.state.loading}>Save</Button>
            </div>
        );
    };

    submit = (formData:any) =>{
        this.setState({loading:true});
        this.props.submit(formData,this.props.submission,()=>this.setState({loading:false}))
    };

    getSubmissionLink = () =>{
        if(this.state.alteredLink.length>0){
            return this.state.alteredLink;
        }
        return this.props.submission.submissionLink as string;
    };

    render() {
        return(
            <LoadingOverlay
                active={this.state.loading}
                spinner
                text='Loading...'
            >
                <div className={"problem-submission"}>
                    <div className={"submission"}>
                        <div className={"title"}>{this.props.submission.submissionTitle}</div>
                        <div className={"artist"}>Number of Flags: {this.props.submission.flagCount}</div>
                        <SubmissionPlayer link={this.getSubmissionLink()} playing={true}/>
                    </div>
                    <div className={"wn-form"}>
                        <Formik
                            validationSchema={formSchema}
                            onSubmit={this.submit}
                            enableReinitialize={true}
                            initialValues={this.getInitialValues()}
                        >
                            {({
                                  handleSubmit,
                                  handleChange,
                                  handleBlur,
                                  values,
                                  touched,
                                  isValid,
                                  errors,
                              }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <Form.Row>
                                        <Form.Group as={Col} xs="12" controlId="validationLink">
                                            <Form.Label>Link</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="link"
                                                placeholder={"enter link"}
                                                value={values.link}
                                                onChange={(e:any)=>this.onCustomHandleChange(e,handleChange)}
                                                isInvalid={!!errors.link}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.link}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} xs="12" controlId="validationProblem">
                                            <Form.Label>Problem</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="problem"
                                                placeholder={"enter problem"}
                                                value={values.problem}
                                                onChange={handleChange}
                                                isInvalid={!!errors.problem}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.problem}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        {this.props.type==="flagged"?<Form.Group as={Col} xs="12">
                                            <Form.Check
                                                required
                                                name="removeFlags"
                                                label="Remove From Flagged List"
                                                onChange={handleChange}
                                                checked={values.removeFlags}
                                                isInvalid={!!errors.removeFlags}
                                                feedback={errors.removeFlags}
                                                id="validationRemoveFlags"
                                            />
                                        </Form.Group>:null}
                                        <Form.Group as={Col} xs="12">
                                            <Form.Label>Status</Form.Label>
                                            <Form.Control as="select" onChange={handleChange} name="status" id="validationStatus" value={values.status+""}>
                                                <option value={1}>In Progress</option>
                                                <option value={3}>Disable</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Form.Row>
                                    {this.getSubmitMenu(handleSubmit)}
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </LoadingOverlay>
        );
    }
}

export default withSystemState(withRouter(withApiHandler(ProblemSubmissionsPage,ErrorHandler.TOAST)));