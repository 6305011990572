import {SystemActionTypes, UPDATE_ADMIN_SYSTEM_TOKEN, UPDATE_SYSTEM_TOKEN,UPDATE_SUBMISSION} from './types'
import {
    DbmodelsSubmission,
} from "../../api_clients/as_client/src";
export function updateToken(newToken: string): SystemActionTypes {
    return {
        type: UPDATE_SYSTEM_TOKEN,
        payload: newToken
    }
}

export function updateAdminToken(newToken: string): SystemActionTypes {
    return {
        type: UPDATE_ADMIN_SYSTEM_TOKEN,
        payload: newToken
    }
}

export function updateSubmission(submission: DbmodelsSubmission): SystemActionTypes{
    return {
        type: UPDATE_SUBMISSION,
        payload: submission
    }
}