import React from 'react'
import {RouteProps, Redirect, Route} from 'react-router-dom'
import withSystemState, {InjectedSystemStateProps} from "../hocs/WithSystemState";

type AdminRestrictedRouteProps = RouteProps & InjectedSystemStateProps;

class AdminRestrictedRoute extends React.Component<AdminRestrictedRouteProps, any> {

    render(){
        let tokenSet = this.props.systemState.adminToken.length>0;
        let CompType = this.props.component as React.ComponentClass;
        return(
            <Route render={(props) => {
                if(this.props.path !== props.location.pathname){
                    return null;
                }
                if(tokenSet){
                    return (<CompType {...props}/>);
                }else {
                    return (<Redirect to="/admins"/>);
                }
            }}/>
        );
    }
}

export default withSystemState(AdminRestrictedRoute);