import config from "../../config/config";
import * as asAPI from "./src";

export class ASApiClient {
    adminsApi: asAPI.AdminsApi;
    accountsApi : asAPI.AccountsApi;
    usersApi: asAPI.UsersApi;
    productsApi: asAPI.ProductsApi;
    paymentsApi: asAPI.PaymentsApi;
    conversationsApi: asAPI.ConversationsApi;
    leaderboardsApi: asAPI.LeaderboardsApi;
    queuesApi: asAPI.QueuesApi;
    submissionsApi: asAPI.SubmissionsApi;
    bannersApi: asAPI.BannersApi;
    contestsAPI: asAPI.ContestsApi;
    constructor() {
        this.adminsApi = new asAPI.AdminsApi(undefined,config.asApi.endpoint);
        this.accountsApi = new asAPI.AccountsApi(undefined,config.asApi.endpoint);
        this.usersApi = new asAPI.UsersApi(undefined,config.asApi.endpoint);
        this.productsApi = new asAPI.ProductsApi(undefined,config.asApi.endpoint);
        this.paymentsApi = new asAPI.PaymentsApi(undefined,config.asApi.endpoint);
        this.conversationsApi = new asAPI.ConversationsApi(undefined,config.asApi.endpoint);
        this.leaderboardsApi = new asAPI.LeaderboardsApi(undefined,config.asApi.endpoint);
        this.queuesApi = new asAPI.QueuesApi(undefined,config.asApi.endpoint);
        this.submissionsApi = new asAPI.SubmissionsApi(undefined,config.asApi.endpoint);
        this.bannersApi = new asAPI.BannersApi(undefined,config.asApi.endpoint);
        this.contestsAPI = new asAPI.ContestsApi(undefined,config.asApi.endpoint);
    }
}

const asApiClient = new ASApiClient();

export default asApiClient;