import React, {Component} from 'react';
import "../../sass/common.scss";
import withApiHandler, {ErrorHandler, InjectedApiHandlerProps} from "../hocs/WithApiHandler";
import asApiClient from "../../api_clients/as_client/ASApiClient";
import {AxiosResponse} from "axios";
import withSystemState, {InjectedSystemStateProps} from "../hocs/WithSystemState";
import Moment from "react-moment";
import {getViewRatedTrackTitle, ModalEntry} from "../../pages/LandingPage";
import SubmissionDisplay from "./SubmissionDisplay";
import "../../sass/components/SubmissionRater.scss";
import {
    DbmodelsSubmissionDetailed,
    ResponsesCommentResponse,
    ResponsesDetailedSubmissionsResponse
} from "../../api_clients/as_client/src";

interface RatedSubmissionsBaseProps {
    openModal: (modal: ModalEntry)=>void;
}

interface RatedSubmissionsState{
    submissions: DbmodelsSubmissionDetailed[];
}

type RatedSubmissionsProps = RatedSubmissionsBaseProps & InjectedApiHandlerProps & InjectedSystemStateProps;

class RatedSubmissions extends Component<RatedSubmissionsProps,RatedSubmissionsState> {
    state={
        submissions: [] as DbmodelsSubmissionDetailed[],
    };
    componentDidMount(): void {
        this.props.handleRequest(asApiClient.usersApi.getRatedSubmissions(this.props.systemState.token),(response:AxiosResponse<ResponsesDetailedSubmissionsResponse>)=>{
            if(response.data.data!==undefined&&response.data.data.submissions!==null && response.data.data.submissions!==undefined) {
                this.setState({
                    submissions: response.data.data.submissions
                });
            }
        });
    }

    showSubmission = (index:number) =>{
        this.props.openModal({
            title: getViewRatedTrackTitle(),
            body: <div className={"submission-modal"}><SubmissionDisplay viewOnly={true} submission={this.state.submissions[index]} onComment={(formData:any)=>this.onComment(formData,index)}/></div>
        })
    };

    onComment = (formData:any, index:number) =>{
        let request = formData;
        let submission = this.state.submissions[index];
        this.props.handleRequest(asApiClient.submissionsApi.createCommentForSubmission(submission.unid as string,this.props.systemState.token,request),(response:AxiosResponse<ResponsesCommentResponse>)=>{
            if(response.data.data!==undefined) {
                let newSubmissions = [...this.state.submissions];
                let newSub = {...submission};
                newSub.comment = response.data.data.comment;
                newSubmissions[index] = newSub;
                this.setState({
                    submissions: newSubmissions
                }, () => {
                    this.showSubmission(index);
                });
            }
        });
    };

    render() {
        return (
            <div className={"rated-submissions"}>
                <div className={"wn-list user-rated"}>
                    {this.state.submissions.map((submission:DbmodelsSubmissionDetailed,index:number)=>{
                        return(
                            <div className={"item"} key={"submission-"+index} onClick={()=>this.showSubmission(index)}>
                                <div className={"title"}>{submission.title}</div>
                                <div className={"create-date"}>{<Moment local format="MM/DD/YYYY hh:mm">{submission.createTime}</Moment>}</div>
                                <div className={"status"}>{submission.comment!==null&&submission.comment!==undefined?"Commented":"No Comment"}</div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

export default withSystemState(withApiHandler(RatedSubmissions,ErrorHandler.TOAST));