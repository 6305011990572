import React from 'react';
import withApiHandler, {ErrorHandler, InjectedApiHandlerProps} from "../hocs/WithApiHandler";
import withSystemState, {InjectedSystemStateProps} from "../hocs/WithSystemState";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../../sass/components/Checkout.scss";
import "../../sass/common.scss";
import Collapse from "react-bootstrap/Collapse";
import Button from "react-bootstrap/Button";
import {AxiosResponse} from "axios";
import Spinner from "react-bootstrap/Spinner";
import UserCards from "./UserCards";
import asApiClient from "../../api_clients/as_client/ASApiClient";
import PaymentSelector from "./PaymentSelector";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import * as _ from "lodash";
import {
    MiscAvailableProductWithDiscount,
    MiscCard,
    ResponsesAvailableProductsResponse,
    ResponsesBaseResponse, ResponsesPaymentResponse, ResponsesSubmissionResponse, SubmissionPayment
} from "../../api_clients/as_client/src";
import TagManager from "react-gtm-module";
import ReactGA from 'react-ga';
import ReactPixel from "react-facebook-pixel";
import {_hsq} from "../../index";
import config from "../../config/config";

import LoadingOverlay from 'react-loading-overlay';

interface CheckoutBaseProps{
    submissionUnid: string;
    onComplete: (submissionUnid:string)=>void;
}

interface CheckoutState{
    selectedCard: MiscCard|null|undefined;
    selectedCardToken: stripe.Token|null|undefined;
    saveCard: boolean;
    availableProducts: MiscAvailableProductWithDiscount[];
    loading: boolean;
    couponCode: string;
}

type CheckoutProps = CheckoutBaseProps & InjectedApiHandlerProps & InjectedSystemStateProps

class Checkout extends React.Component<CheckoutProps,CheckoutState> {

    state={
        selectedCard: null,
        selectedCardToken: null,
        availableProducts: [] as MiscAvailableProductWithDiscount[],
        saveCard: false,
        loading: true,
        couponCode: "",
    };

    isUnMounted = false;

    componentDidMount(): void {
        this.loadAvailableProducts();
    }

    loadAvailableProducts = () =>{
        this.setState({
            loading: true
        });
        this.props.handleRequest(asApiClient.productsApi.getAvailableProducts(this.props.systemState.token,this.state.couponCode),(response:AxiosResponse<ResponsesAvailableProductsResponse>)=>{
            if(response.data.data!==undefined && response.data.data.availableProducts!==undefined) {
                this.setState({
                    availableProducts: response.data.data.availableProducts
                });
            }
        },undefined,()=>{
            this.setState({
                loading:false
            });
        });

        //todo: use this to get the last unpaid submission
        // this.props.handleRequest(asApiClient.usersApi.getLastUnpaidSubmission(this.props.systemState.token),(response:AxiosResponse<ResponsesSubmissionResponse>)=>{
        //     console.log(response.data);
        // });
    };

    componentWillUnmount(): void {
        this.isUnMounted = true;
    }

    paymentSelected = () =>{
        ReactGA.event({
            category: "SubmitTrack",
            action: "WN - Submit Track - CC",
        });
        ReactPixel.trackCustom("WN - Submit Track - CC",null);
        _hsq.push(["trackEvent", {
            id: config.hubSpot.events.submitCC
        }]);
    };

    onNewCardSelected = (token: stripe.Token, saveCard: boolean) =>{
        this.paymentSelected();
        let miscCard = {...token.card} as MiscCard;
        this.setState({selectedCard:miscCard,selectedCardToken:token,saveCard: saveCard});
    };

    onExistingCardSelected = (card: MiscCard) =>{
        this.paymentSelected();
        this.setState({selectedCard:card});
    };

    getUserCard = (card: stripe.Card|null) =>{
        if(card!=null) {
            let miscCard = {...card} as unknown as MiscCard;
            return (
                <UserCards cards={[miscCard]}/>
            );
        }
        return null;
    };

    makePayment = _.throttle(()=>{
        let body = {} as SubmissionPayment;
        let selectedCardToken = this.state.selectedCardToken;
        let selectedCard = this.state.selectedCard;
        if(selectedCardToken!==null && selectedCardToken!==undefined){
            let casted = selectedCardToken as stripe.Token;
            body.newCard = {
                tokenID: casted.id,
                save: this.state.saveCard,
            };
        }else if(selectedCard!==null && selectedCard!==undefined){
            let casted = selectedCard as MiscCard;
            body.cardID = casted.unid;
        }
        body.productUnid = this.state.availableProducts[this.state.availableProducts.length-1].unid;
        body.submissionUnid = this.props.submissionUnid;
        body.couponCode = this.state.couponCode;
        this.setState({
            loading:true
        });
        this.props.handleRequest(asApiClient.paymentsApi.makePayment(this.props.systemState.token,body),(response:AxiosResponse<ResponsesPaymentResponse>)=>{
            ReactGA.plugin.execute('ecommerce', 'addTransaction', {
                'id': response.data.data.transactionID, // Transaction ID. Required.
                'revenue': response.data.data.amount+"", // Grand Total.
            });
            ReactGA.plugin.execute('ecommerce', 'addItem', {
                id: response.data.data.transactionID,
                name: 'Submission',
                sku: 'submissionSKU', // seems to be required
                price: response.data.data.amount+"",
                quantity: '1',
            });
            ReactGA.plugin.execute('ecommerce', 'send',{});
            ReactGA.plugin.execute('ecommerce', 'clear',{});
            this.props.onComplete(this.props.submissionUnid);
        },undefined,()=>{
            if(!this.isUnMounted) {
                this.setState({
                    loading: false
                });
            }
        });
    },2000,{leading:true,trailing:false});

    handleCouponCodeChange = (e:any) =>{
        this.setState({couponCode: e.target.value});
    };

    getPageContent = () =>{
        let product = this.state.availableProducts[this.state.availableProducts.length-1];
        if(product){
            return(
                <>
                    <div>
                        <div className={"product"}>
                            <div className={"name"}>{product.name}</div>
                            <div className={"price"}>{
                                product.discountedPrice!==null && product.discountedPrice!==undefined ? 
                                (<>
                                    <del>${Number(product.price).toFixed(2).toString().replace('.00','')}</del>
                                    <br/>
                                    <h4 className='text-danger font-weight-bold'>${Number(product.discountedPrice).toFixed(2).toString().replace('.00','')}</h4>
                                </>) : 
                                `$${Number(product.price).toFixed(2).toString().replace('.00','')}`}
                            </div>
                        </div>
                    </div>
                    <div className={"checkout-content"}>
                        <Collapse in={this.state.selectedCard===null}>
                            <div>
                                <PaymentSelector saveCardOptional={true} onNewCardSelected={this.onNewCardSelected} onExistingCardSelected={this.onExistingCardSelected}/>
                                <div className={"wn-form"}>
                                    <Form.Group controlId="couponCode">
                                        <Form.Label className={"sr-only"}>Coupon Code</Form.Label>
                                        <InputGroup>
                                            <Form.Control
                                                type="text"
                                                name="name"
                                                placeholder={"coupon code"}
                                                value={this.state.couponCode}
                                                onChange={this.handleCouponCodeChange}
                                            />
                                            <InputGroup.Append>
                                                <Button onClick={this.loadAvailableProducts}>Apply</Button>
                                            </InputGroup.Append>
                                        </InputGroup>
                                    </Form.Group>
                                </div>
                            </div>
                        </Collapse>
                        <Collapse in={this.state.selectedCard!==null}>
                            <div>
                                {this.getUserCard(this.state.selectedCard)}
                                <div className={"button-aligner"}>
                                    <Button variant="primary" size="lg" block onClick={this.makePayment} disabled={this.state.availableProducts.length<=0}>Pay</Button>
                                </div>
                            </div>
                        </Collapse>
                        <div className={"text-link"}>Need Help? <a href="https://www.cognitoforms.com/MediaCo1/WhosNextHelpDesk" target="_blank">Contact Us</a></div>
                    </div>
                </>
            )
        }
    };

    render(){
        return(
            <LoadingOverlay
                active={this.state.loading}
                spinner
                text='Loading...'
            >
                <div className={"checkout wn-form"}>
                    {this.getPageContent()}
                </div>
             </LoadingOverlay>
        );
    }
}

export default withSystemState(withApiHandler(Checkout,ErrorHandler.TOAST));