import React from 'react';
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import {AxiosResponse} from "axios";
import {Formik} from "formik";
import * as yup from 'yup';
import '../sass/common.scss';
import '../sass/components/LoginForm.scss';
import '../sass/components/AdminLoginForm.scss';
import withApiHandler, {ErrorHandler, InjectedApiHandlerProps} from "../components/hocs/WithApiHandler";
import withSystemState, {InjectedSystemStateProps} from "../components/hocs/WithSystemState";
import asApiClient from "../api_clients/as_client/ASApiClient";
import {RouteComponentProps, withRouter} from "react-router";
import NavBar, {NavbarAction} from "../components/navigation/NavBar";
import {toast} from "react-toastify";
import {ResponsesLoginResponse} from "../api_clients/as_client/src";

interface ResetPasswordPageBaseProps{

}

type ResetPasswordPageProps = ResetPasswordPageBaseProps & InjectedApiHandlerProps & InjectedSystemStateProps & RouteComponentProps;

interface ResetPasswordPageState{
}

const resetPasswordSchema = yup.object({
    password: yup.string().required().min(6),
    verifyPassword: yup.string()
        .oneOf([yup.ref('password'), null], 'Passwords must match')
        .required('Confirm Password is required').min(6),
});

class ResetPasswordPage extends React.Component<ResetPasswordPageProps, ResetPasswordPageState> {
    state={

    };

    submit = (formData:any) =>{
        const params = new URLSearchParams(this.props.location.search);
        const tokenEscaped = params.get('token');
        if(tokenEscaped) {
            let token = unescape(tokenEscaped);
            console.log(token);
            this.props.handleRequest(asApiClient.accountsApi.resetPassword(token,formData),(response:AxiosResponse<ResponsesLoginResponse>)=>{
                toast.success("Password Reset!",{position: toast.POSITION.TOP_CENTER});
                setTimeout(()=>{this.props.history.push("/")}, 3000);
            })
        }else{
            toast.error("Invalid Token", {position: toast.POSITION.TOP_CENTER});
        }
    };

    getSubmitMenu = (submitHandler:any) =>{
        return (
            <div>
                <Button variant="primary" size="lg" block onClick={submitHandler}>Submit</Button>
            </div>
        );
    };

    getNavbarActions = ():NavbarAction[] =>{
        return[{
            title:"Home",
            id: "home",
            action: ()=>{
                this.props.history.push("/");
            }
        }] as NavbarAction[];
    };

    render(){
        let navbarActions = this.getNavbarActions();
        return(
            <div>
                <NavBar actions={navbarActions} title={"Reset Password"} expand={navbarActions.length<=3?true:"md"}/>
                <div className={"admin-login login-form wn-form"}>
                    <Formik
                        validationSchema={resetPasswordSchema}
                        onSubmit={this.submit}
                        initialValues={{
                            password: '',
                            verifyPassword: ''
                        }}
                    >
                        {({
                              handleSubmit,
                              handleChange,
                              handleBlur,
                              values,
                              touched,
                              isValid,
                              errors,
                          }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Form.Row>
                                    <Form.Group as={Col} xs="12" controlId="validationPassword">
                                        <Form.Label className={"sr-only"}>Enter Password</Form.Label>
                                        <Form.Control
                                            type="password"
                                            name="password"
                                            placeholder={"enter password"}
                                            value={values.password}
                                            onChange={handleChange}
                                            isInvalid={!!errors.password}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.password}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} xs="12" controlId="validationVerifyPassword">
                                        <Form.Label className={"sr-only"}>Verify Password</Form.Label>
                                        <Form.Control
                                            type="password"
                                            name="verifyPassword"
                                            placeholder={"verify password"}
                                            value={values.verifyPassword}
                                            onChange={handleChange}
                                            isInvalid={!!errors.verifyPassword}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.verifyPassword}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Form.Row>
                                {this.getSubmitMenu(handleSubmit)}
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        );
    }
}

export default withSystemState(withRouter(withApiHandler(ResetPasswordPage,ErrorHandler.TOAST)));