import React from 'react';
import '../sass/common.scss';
import '../sass/components/LoginForm.scss';
import '../sass/components/AdminLoginForm.scss';
import withApiHandler, {ErrorHandler, InjectedApiHandlerProps} from "../components/hocs/WithApiHandler";
import withSystemState, {InjectedSystemStateProps} from "../components/hocs/WithSystemState";
import {RouteComponentProps, withRouter} from "react-router";
import NavBar, {NavbarAction} from "../components/navigation/NavBar";

interface NoPageFountPageBaseProps{

}

type NoPageFountPageProps = NoPageFountPageBaseProps & InjectedApiHandlerProps & InjectedSystemStateProps & RouteComponentProps;

interface NoPageFountPageState{
}

class NoPageFountPage extends React.Component<NoPageFountPageProps, NoPageFountPageState> {
    state={

    };

    render(){
        let navbarActions = [{
            title:"Home",
            id: "home",
            action: ()=>{
                this.props.history.push("/");
            }
        }] as NavbarAction[];
        return (
            <div>
                <NavBar actions={navbarActions} title={"Page Not Found"} expand={navbarActions.length<=3?true:"md"}/>
            </div>
        );
    }
}

export default withSystemState(withRouter(withApiHandler(NoPageFountPage,ErrorHandler.TOAST)));