import React from 'react';
import withApiHandler, {ErrorHandler, InjectedApiHandlerProps} from "../hocs/WithApiHandler";
import withSystemState, {InjectedSystemStateProps} from "../hocs/WithSystemState";
import "../../sass/pages/SubmissionResults.scss";
import "../../sass/common.scss";
import {AxiosResponse} from "axios";
import Spinner from "react-bootstrap/Spinner";
import {RouteComponentProps, withRouter} from 'react-router-dom';
import asApiClient from "../../api_clients/as_client/ASApiClient";
import Moment from "react-moment";
import Avatar from 'react-avatar';
import {IconContext} from "react-icons";
import Button from "react-bootstrap/Button";
import Conversation from "./Conversation";
import HotIcon from '../../images/hot-icon.png';
import WorkIcon from '../../images/work-icon.png';
import TrashIcon from '../../images/trash-icon.png';
import {
    MiscConversation,
    MiscConversationMessage,
    MiscRatingResult,
    MiscSubmissionResults,
    ResponsesSubmissionResultsResponse
} from "../../api_clients/as_client/src";

interface SubmissionResultsBaseProps{
    submissionUnid: string;
}

interface SubmissionResultsState{
    loading: boolean;
    submissionResult: MiscSubmissionResults|null;
    selectedResult: MiscRatingResult|null;
}

type SubmissionResultsProps = SubmissionResultsBaseProps & InjectedApiHandlerProps & InjectedSystemStateProps & RouteComponentProps

class SubmissionResults extends React.Component<SubmissionResultsProps,SubmissionResultsState> {

    state={
        loading: false,
        submissionResult:null as MiscSubmissionResults|null,
        selectedResult: null as MiscRatingResult|null
    };

    componentDidMount(): void {
        this.loadResults();
    }

    loadResults = () =>{
        this.setState({
            loading:true,
        });
        this.props.handleRequest(asApiClient.usersApi.getUserSubmissionResults(this.props.submissionUnid,this.props.systemState.token),(response:AxiosResponse<ResponsesSubmissionResultsResponse>)=>{
            this.setState({
                submissionResult: response.data.data.submissionResults
            })
        },undefined,()=>{
            this.setState({
                loading:false
            })
        });
    };

    onViewMessages = (ratingResult: MiscRatingResult) =>{
        this.setState({selectedResult: ratingResult});
    };

    getPageContent = () =>{
        if(this.state.loading){
            return(
                <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            );
        }
        if(this.state.selectedResult===null || this.state.selectedResult===undefined){
            return this.getRatingResults();
        }else{
            let result = this.state.selectedResult;
            let conversationData = {
                curatorName: result.curatorName,
                rating: result.rating,
                trackName: "",
                conversationUnid: result.conversationUnid,
                curatorPicture: result.curatorProfilePicture
            } as MiscConversation;
            return(
                <Conversation conversation={conversationData} onBackHandler={()=>{this.setState({selectedResult:null})}} conversationUnid={conversationData.conversationUnid as string}/>
            );
        }
    };

    getRatingResults = () =>{
        if(this.state.submissionResult!==null && this.state.submissionResult!==undefined && this.state.submissionResult.ratingsResult!==null && this.state.submissionResult.ratingsResult!==undefined && this.state.submissionResult.ratingsResult.length>0) {
            return (
                <>
                    {this.state.submissionResult.ratingsResult.map((ratingResult:MiscRatingResult,index:number)=>{
                        return(
                            <div key={"rating-"+index}>
                                <RatingResultDisplay ratingResult={ratingResult} onViewMessages={()=>this.onViewMessages(ratingResult)}/>
                            </div>
                        );
                    })}
                </>
            );
        }
        return(
            <div>No Results Yet</div>
        );
    };

    render(){
        return(
            <>
                <div className={"submission-results"}>
                    {this.getPageContent()}
                </div>
            </>
        );
    }
}

interface RatingResultDisplayProps{
    ratingResult: MiscRatingResult;
    onViewMessages?: (conversationUnid:string)=>void;
    hideComment?: boolean;
}

export function RatingResultDisplay(props:RatingResultDisplayProps) {
    let ratingResult = props.ratingResult;
    let icon = null;
    if (ratingResult.rating === 3) {
        icon = <img className={"rate-icon"} src={HotIcon} alt={"hot"}/>;
    } else if (ratingResult.rating === 2) {
        icon = <img className={"rate-icon"} src={WorkIcon} alt={"needs work"}/>;
    } else {
        icon = <img className={"rate-icon"} src={TrashIcon} alt={"trash"}/>;
    }
    let viewMessagesBurron = null;
    if(props.onViewMessages!==null && props.onViewMessages!==undefined && ratingResult.comment!==null && ratingResult.comment!==undefined && ratingResult.comment.length>0) {
        let onViewMessages = props.onViewMessages;
        viewMessagesBurron = <Button block={true} size={"lg"} onClick={()=>onViewMessages(ratingResult.conversationUnid as string)}>Respond to your Fan</Button>
    }
    let src = undefined as string | undefined;
    if(ratingResult.curatorProfilePicture!==undefined && ratingResult.curatorProfilePicture.length>0){
        src = ratingResult.curatorProfilePicture;
    }
    return(
        <div className={"wn-list submissions-result"}>
            <div className={"item"}>
                <Avatar size={"40px"} src={src} name={ratingResult.curatorName} round={true}/>
                <div className={"stacked-title wn-form"}>
                    <div className={"name"}>{ratingResult.curatorName}</div>
                    <Moment className={"date"} local format="MMM,DD YYYY hh:mm a">{ratingResult.rateTime}</Moment>
                    {props.hideComment===true?null:
                        <div className={"comment-text"}>
                            {ratingResult.comment}
                        </div>}
                    {viewMessagesBurron}
                </div>
                <div className={"rating-container"}>
                    <IconContext.Provider value={{size: "1.2em"}}>
                        {icon}
                    </IconContext.Provider>
                </div>
            </div>
        </div>
    );
}

export default withSystemState(withApiHandler(withRouter(SubmissionResults),ErrorHandler.TOAST));