import React from 'react';
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import {AxiosResponse} from "axios";
import {Formik} from "formik";
import * as yup from 'yup';
import withApiHandler, {ErrorHandler, InjectedApiHandlerProps} from "../hocs/WithApiHandler";
import withSystemState, {InjectedSystemStateProps} from "../hocs/WithSystemState";
import asApiClient from "../../api_clients/as_client/ASApiClient";
import '../../sass/common.scss';
import {DbmodelsUser, ResponsesUserProfileResponse, ResponsesUserResponse} from "../../api_clients/as_client/src";
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { isValidPhoneNumber } from 'react-phone-number-input'
import {toast} from "react-toastify";

interface EditUserFormBaseProps{
    onSuccess: ()=>void;
    fbLogin: boolean;
}

type EditUserFormProps = EditUserFormBaseProps & InjectedApiHandlerProps & InjectedSystemStateProps;

interface EditUserFormState{
    user: DbmodelsUser | null;
    invalidPhone: boolean,
    loading:boolean;
}

const editEmailProfileSchema = yup.object({
    name: yup.string().required(),
    email: yup.string().required(),
    password: yup.string(),
    phoneNumber: yup.string()
});

const editFBProfileSchema = yup.object({
    email: yup.string().required().email(),
    name: yup.string().required(),
    phoneNumber: yup.string()
});

class EditUserForm extends React.Component<EditUserFormProps, EditUserFormState> {
    state = {
        user: null as DbmodelsUser|null,
        invalidPhone: false,
        loading: false
    };

    componentDidMount(): void {
        this.props.handleRequest(asApiClient.usersApi.getUser(this.props.systemState.token),(response:AxiosResponse<ResponsesUserResponse>)=>{
            if(response.data.data!==undefined) {
                this.setState({
                    user: response.data.data.user as DbmodelsUser
                });
            }
        })
    }

    submit = (formData:any) =>{
        if(this.props.fbLogin && formData.password!==null && formData.password!==undefined){
            delete formData.password;
        }
        if(!this.props.fbLogin && formData.password!==null && formData.password!==undefined && formData.password.length<=0){
            delete formData.password;
        }
        let phone = formData.phoneNumber;
        if(phone!==null && phone !==undefined && phone.length>0){
            if(!isValidPhoneNumber(phone) || phone.length==1){
                this.setState({invalidPhone:true});
                toast.error("Invalid phone number. Please check your country's flag!", {position: toast.POSITION.TOP_CENTER});
                return;
            }
        }else{
            delete formData.phoneNumber;
        }
        this.props.handleRequest(asApiClient.usersApi.editUser(this.props.systemState.token,formData),(response:AxiosResponse<ResponsesUserResponse>)=>{
            this.props.onSuccess();
        });
    };

    getInitialFormValues = (user:DbmodelsUser) =>{
        let email = '';
        if(user!==undefined && user.email!==undefined){
            email = user.email as string;
        }
        let phone = '';
        if(user!==undefined && user.phoneNumber!==undefined && user.phoneNumber!==null){
            phone = user.phoneNumber
        }
        if(this.props.fbLogin) {
            return {
                email: email,
                name: user.name as string,
                phoneNumber: phone
            };
        }else{
            return {
                email: email,
                password: '',
                name: user.name as string,
                phoneNumber: phone
            };
        }
    };

    render(){
        if(this.state.loading||this.state.user===null){
            return null;
        }
        let user = this.state.user;
        return(
            <div className={"wn-form"}>
                <Formik
                    validationSchema={this.props.fbLogin?editFBProfileSchema:editEmailProfileSchema}
                    onSubmit={this.submit}
                    initialValues={this.getInitialFormValues(user)}
                >
                    {({
                          handleSubmit,
                          handleChange,
                          handleBlur,
                          values,
                          touched,
                          isValid,
                          setFieldValue,
                          errors,
                      }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Form.Row>
                                <Form.Group as={Col} xs="12" controlId="validationName">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="name"
                                        value={values.name}
                                        onChange={handleChange}
                                        isInvalid={!!errors.name}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} xs="12" controlId="validationEmail">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="email"
                                        value={values.email}
                                        onChange={handleChange}
                                        isInvalid={!!errors.email}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.email}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} xs="12">
                                    <Form.Label>Phone Number</Form.Label>
                                    <PhoneInput
                                        className={"custom-input" + (this.state.invalidPhone?" invalid":"")}
                                        placeholder="Enter phone number"
                                        value={values.phoneNumber}
                                        onChange={(e:any)=>{this.setState({invalidPhone:false});setFieldValue("phoneNumber",e)}}/>
                                </Form.Group>
                                {this.props.fbLogin?null:
                                    <Form.Group as={Col} xs="12" controlId="validationPassword">
                                        <Form.Label>New Password</Form.Label>
                                        <Form.Control
                                            type="password"
                                            name="password"
                                            value={values.password}
                                            onChange={handleChange}
                                            isInvalid={!!errors.password}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.password}
                                        </Form.Control.Feedback>
                                    </Form.Group>}
                            </Form.Row>
                            <Button variant="primary" size="lg" block onClick={()=>handleSubmit()}>Save</Button>
                        </Form>
                    )}
                </Formik>
            </div>
        );
    }
}

export default withSystemState(withApiHandler(EditUserForm,ErrorHandler.TOAST));