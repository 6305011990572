import React, {Component} from 'react';
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import {RouteComponentProps, withRouter} from "react-router";
import withSystemState, {InjectedSystemStateProps} from "../hocs/WithSystemState";
import wnLogo from '../../images/WNLogo.png';
import '../../sass/components/NavBar.scss';
import NavDropdown from "react-bootstrap/NavDropdown";
import { ReactComponent as Hot97Logo } from '../../images/hot97-logo.svg';

interface NavBarPropsBase{
    actions: NavbarAction[];
    expand?:boolean|"sm"|"md"|"lg";
    title?:string;
    onToggle?:(expanded: boolean)=>void;
}

export interface NavbarAction{
    title: string;
    id: string;
    location?: string;
    action?: ()=>void;
    subNavActions?: NavbarAction[];
}

export interface NavBarState{
    expanded: boolean;
}

type NavBarProps = NavBarPropsBase & RouteComponentProps & InjectedSystemStateProps;

class NavBar extends Component<NavBarProps,any> {

    state={
        expanded:false
    };

    goTo = (destination:string) =>{
        this.props.history.push(destination);
    };

    getActionLink = (action:NavbarAction, dropDown: boolean) =>{
        let onClick = ()=>{};
        if (action.location !== null && action.location !== undefined && action.location.length > 0) {
            let location = action.location as string;
            onClick = () => this.goTo(location);
        }
        if (action.action !== null && action.action !== undefined) {
            onClick = action.action
        }
        if(dropDown) {
            return(
                <NavDropdown.Item href={"#"+action.id} key={"sub-action-"+action.id} onClick={onClick}>{action.title}</NavDropdown.Item>
            );
        }else{
            return (
                <Nav.Link href={"#"+action.id} key={"action-"+action.id} onClick={onClick}>{action.title}</Nav.Link>
            );
        }
    };

    getNavbarContent = () =>{
        return(
            <>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                        {this.props.actions.map((navAction:NavbarAction)=>{
                            if(navAction.subNavActions!==null && navAction.subNavActions!==undefined && navAction.subNavActions.length>0){
                                return(
                                    <NavDropdown title={navAction.title} id={"subNav-"+navAction.id} key={"sub-nav-"+navAction.id}>
                                        {navAction.subNavActions.map((subNavAction:NavbarAction)=>{
                                            return this.getActionLink(subNavAction,true);
                                        })}
                                    </NavDropdown>
                                );
                            }else{
                                return this.getActionLink(navAction,false);
                            }
                        })}
                    </Nav>
                    <div className={"nav-footer"}>
                        <div className={"text"}>
                            <div className={"aligner"}>
                            Who’s Next is brought to you by Hot 97 FM.
                            </div>
                        </div>
                        <div className={"logo"}>
                            <Hot97Logo/>
                        </div>
                    </div>
                </Navbar.Collapse>

            </>
        );
    };

    onToggle = (expanded: boolean) =>{
        this.setState({
            expanded:expanded
        })
    };

    getTitle = () =>{
        if(this.state.expanded){
            return "Menu";
        }else{
            return this.props.title;
        }
    };

    render() {
        return(
            <Navbar bg="dark" variant="dark" expand={false} className={"app-navbar"} sticky={"top"} collapseOnSelect={true} onToggle={this.onToggle}>
                <Navbar.Brand as={"div"}><img className={"wn-logo"} src={wnLogo} alt={"Who’s Next logo"}/><div className={"nav-title"}>{this.getTitle()}</div></Navbar.Brand>
                {this.getNavbarContent()}
                {this.props.children}
            </Navbar>
        );
    }
}

export default withSystemState(withRouter(NavBar));