import React, {Component} from 'react';
import "../../sass/components/Checkout.scss";
import "../../sass/common.scss";
import asApiClient from "../../api_clients/as_client/ASApiClient";
import {AxiosResponse} from "axios";
import withApiHandler, {ErrorHandler, InjectedApiHandlerProps} from "../hocs/WithApiHandler";
import withSystemState, {InjectedSystemStateProps} from "../hocs/WithSystemState";
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {
    DbmodelsSubmission,
    DbmodelsUser,
    ResponsesSubmissionResponse
} from "../../api_clients/as_client/src";
import {
    getPaymentTitle,
    getContestsTitle,
    getPaymentThankYouTitle,
    ModalEntry
} from "../../pages/LandingPage";
import Checkout from "../misc/Checkout";
import OptInForm from '../misc/OptInForm'
import ReactPlayer from "react-player";
import LoadingOverlay from 'react-loading-overlay';
import Button from "react-bootstrap/Button";
import '../../sass/components/LastCheckoutView.scss'
import Form from "react-bootstrap/Form";

interface LastCheckoutViewBaseProps {
    user : DbmodelsUser,
    onCheckoutSuccess: ()=> void,
    openModal: (modal:ModalEntry)=>void;
    closeModal: ()=>void;
}

interface LastCheckoutViewState{
    submission: DbmodelsSubmission;
    loading: boolean;
    linkErr: boolean;
    linkLoaded: boolean;
    submitting: boolean
}

type LastCheckoutViewProps = LastCheckoutViewBaseProps & InjectedApiHandlerProps & InjectedSystemStateProps & RouteComponentProps;

class LastCheckoutView extends Component<LastCheckoutViewProps,LastCheckoutViewState> {

    state ={
        submission : {},
        loading: false,
        linkErr: false,
        linkLoaded: false,
        submitting: false
    }

    componentDidMount(): void {
        this.loadSubmission();
    }

    loadSubmission = () => {
        this.setState({
            loading: true
        })
        this.props.handleRequest(asApiClient.usersApi.getLastUnpaidSubmission(this.props.systemState.token),(response:AxiosResponse<ResponsesSubmissionResponse>)=>{
            if(response.data.data.submission!==null && response.data.data.submission!==undefined) {
                let submission = response.data.data.submission;
                this.props.updateSubmission(submission)
                this.setState({
                    submission: submission
                });
            }
        },undefined,()=>{
            this.setState({
                loading: false
            })
        });
    };

    getUnpaidSubmissionTitle=()=>{
        return(
            <div className={"payment-title"}>
                <div className={"modal-wn-title"}>
                    <div className={"main-title"}>
                        Unpaid Track
                    </div>
                    <div className={"sub-title"}>
                        By entering information below, you affirm you own this track’s copyright
                    </div>
                </div>
            </div>
        );
    }

    getUnpaidSubmissionLink=(submission: DbmodelsSubmission)=>{
      if(submission.link){
        return (
          <div>
              <Form>
                  <Form.Group>
                      <Form.Label>Title</Form.Label>
                      <Form.Control readOnly={true} value={submission.title} />
                  </Form.Group>
                  <Form.Group>
                      <Form.Label>Link</Form.Label>
                      <Form.Control readOnly={true} value={submission.link} />
                  </Form.Group>
              </Form>
          </div>
        )
      }
    }

    getUnpaidSubmissionButton=()=>{
      return(
        <div className='last-checkout-button'>
          <Button 
            className={'last-checkout-button__continue'}
            disabled={ this.state.linkErr || !this.state.linkLoaded} 
            variant="primary" 
            size="lg" 
            block 
            onClick={()=> this.handleSubmitTrackSuccess(this.state.submission)}
          >
            {<span>Continue</span>}
          </Button>
        </div>
      )  
    }

    getPreview = (submission: DbmodelsSubmission) =>{
      let link =submission.link;
      if(link){
          return (
            <div className={'player-wrapper '}>
              <ReactPlayer
                className='react-player'
                url={link}
                width='100%'
                height='100%'
                controls={true}
                playing onError={() => {this.setState({linkErr: true});console.log("play error")}}
                onReady={() => {this.setState({linkLoaded: true});console.log("play ready")}}/>     
            </div>
          );  
      }
    };

    onOptInComplete=()=>{
        this.props.openModal({
            title: getPaymentThankYouTitle(),
            body: (
                <div>
                </div>
            )
        })
        setTimeout(()=>{
            this.props.closeModal()
            this.props.onCheckoutSuccess()
        },3000)
    }

    onPaymentComplete = (submissionUnid:string) =>{
        let sub = this.state.submission as DbmodelsSubmission;
        if(!sub.optInContests){
            this.props.openModal({
                title: getContestsTitle(),
                body: <OptInForm onSuccess={this.onOptInComplete} submissionUnid={submissionUnid} user={this.props.user}/>,
            })
        }else{
            this.props.openModal({
                title: getContestsTitle(),
                body: <p>Success!</p>
            })
        }
       
    };

    handleSubmitTrackSuccess =(submission:DbmodelsSubmission)=>{
        this.props.openModal({
            title: getPaymentTitle(submission.title),
            body: <Checkout onComplete={this.onPaymentComplete} submissionUnid={submission.unid as string}/>,
        })
    }

    render() {
        var { loading,submission}= this.state
        return (
            <LoadingOverlay
                active={loading}
                spinner
                text='Loading...'
            >
                <div className={"unpaid-track"}>
                    {this.getUnpaidSubmissionTitle()}
                    {this.getUnpaidSubmissionLink(submission)}
                    {this.getPreview(submission)}
                    {this.getUnpaidSubmissionButton()}
                </div>
            </LoadingOverlay>
        );
    }

}

export default withSystemState(withApiHandler(withRouter(LastCheckoutView),ErrorHandler.TOAST));