import React from 'react';
import {SystemState} from "../../store/system/types";
import {compose, Dispatch} from "redux";
import {connect} from "react-redux";
import {updateAdminToken, updateToken, updateSubmission} from "../../store/system/actions";
import {Subtract} from "utility-types";
import {AppState} from "../../store";
import {
    DbmodelsSubmission,
} from "../../api_clients/as_client/src";
export interface InjectedSystemStateProps {
    systemState: SystemState;
    updateToken: (token:string)=>void;
    removeToken: ()=>void;
    updateAdminToken: (token:string)=>void;
    removeAdminToken: ()=>void;
    updateSubmission:(submission: DbmodelsSubmission)=> void;
}

const withSystemStateFunc = <P extends InjectedSystemStateProps>(Component: React.ComponentType<P>) =>
    class WithSystemState extends React.Component<P, any> {
        render() {
            return (
                <Component
                    {...this.props as P}
                    updateToken={this.props.updateToken}
                    removeToken={this.props.removeToken}
                    updateAdminToken={this.props.updateAdminToken}
                    removeAdminToken={this.props.removeAdminToken}
                    systemState={this.props.systemState}
                    updateSubmission={this.props.updateSubmission}
                />
            );
        }
    };

const mapStateToProps = (state:AppState) => {
    return {
        systemState: {...state.system}
    }
};

const mapDispatchToProps = (dispatch:Dispatch) => {
    return {
        updateToken: (token:string) => {
            dispatch(updateToken(token))
        },
        removeToken: () => {
            dispatch(updateToken(""))
        },
        updateAdminToken: (token:string) => {
            dispatch(updateAdminToken(token))
        },
        removeAdminToken: () => {
            dispatch(updateAdminToken(""))
        },
        updateSubmission:(submission: DbmodelsSubmission)=>{
            dispatch(updateSubmission(submission))
        }
    }
};

const withSystemState = <P extends InjectedSystemStateProps>(Component: React.ComponentType<P>)=> {
    let composed=compose<React.ComponentType<Subtract<P,InjectedSystemStateProps>>>(
        connect(mapStateToProps, mapDispatchToProps),
        withSystemStateFunc);
    return composed(Component);
};

export default withSystemState;