import React from 'react';
import withApiHandler, {ErrorHandler, InjectedApiHandlerProps} from "../hocs/WithApiHandler";
import withSystemState, {InjectedSystemStateProps} from "../hocs/WithSystemState";
import "../../sass/pages/ProfileView.scss";
import "../../sass/common.scss";
import {AxiosResponse} from "axios";
import Spinner from "react-bootstrap/Spinner";
import asApiClient from "../../api_clients/as_client/ASApiClient";
import Card from "react-bootstrap/Card";
import Avatar from "react-avatar";
import {IconContext} from "react-icons";
import {FaCircle, FaCircleNotch, FaRegCircle} from "react-icons/fa";
import ProgressBar from "react-bootstrap/ProgressBar";
import {getEditAccountTitle, ModalEntry} from "../../pages/LandingPage";
import Button from "react-bootstrap/Button";
import EditUserForm from "../misc/EditUserForm";
import {MiscUserBadge, ResponsesUserProfileResponse} from "../../api_clients/as_client/src";

interface ProfileViewBaseProps {
    openModal: (modal:ModalEntry)=>void;
    closeModal: ()=>void;
    loadUser: ()=>void;
}

interface ProfileViewState {
    loadingProfile: boolean;
    picture: string;
    userName: string;
    curatorPoints: number;
    artistPoints: number | null;
    badges: MiscUserBadge[] | null;
    fbLogin: boolean;
}

type ProfileViewProps = ProfileViewBaseProps & InjectedApiHandlerProps & InjectedSystemStateProps

class ProfileView extends React.Component<ProfileViewProps, ProfileViewState> {

    state = {
        userName: "",
        picture: "",
        curatorPoints: 0,
        artistPoints: null,
        loadingProfile: false,
        badges: null as MiscUserBadge[] | null,
        fbLogin: false,
    };

    componentDidMount(): void {
        this.loadProfile();
    }

    onSuccessEditUser = () =>{
        this.loadProfile();
        this.props.loadUser();
        this.props.closeModal();
    };

    loadProfile = () => {
        this.setState({
            loadingProfile: true,
        });
        this.props.handleRequest(asApiClient.usersApi.getUserProfile(this.props.systemState.token), (response: AxiosResponse<ResponsesUserProfileResponse>) => {
            this.setState({
                userName: response.data.data.name,
                curatorPoints: response.data.data.curatorPoints,
                artistPoints: response.data.data.artistPoints,
                badges: response.data.data.badges,
                picture: response.data.data.picture,
                fbLogin: response.data.data.fbLogin
            });
        }, undefined, () => {
            this.setState({
                loadingProfile: false
            })
        });
    };

    openEditUserModal = () =>{
        this.props.openModal({
            title: getEditAccountTitle(),
            body: <div className={"edit-user-modal"}><EditUserForm onSuccess={this.onSuccessEditUser} fbLogin={this.state.fbLogin}/></div>
        });
    };

    getProfileSection = () => {
        if (this.state.loadingProfile) {
            return (
                <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            );
        }
        let src = undefined as string | undefined;
        if(this.state.picture.length>0){
            src = this.state.picture;
        }
        return (
            <div className={"wn-list user-profile wn-form"}>
                <div className={"item"}>
                    <Avatar size={"40px"} name={this.state.userName} src={src} round={true}/>
                    <div className={"stacked-title"}>
                        <div className={"name"}>{this.state.userName}</div>
                        <div className={"points"}>Curator Points: {this.state.curatorPoints}</div>
                        {this.state.artistPoints===null?null: <div className={"points"}>Artist Points: {this.state.artistPoints}</div>}
                    </div>
                    <Button onClick={()=>this.openEditUserModal()}>Edit</Button>
                </div>
            </div>
        );
    };

    getBadgesSection = () =>{
        if(this.state.badges !== null && this.state.badges!==undefined && this.state.badges.length>0){
            return(
                <div className={"badges"}>
                    {this.state.badges.map((badge:MiscUserBadge,index:number)=>{
                        let badgeStatus = badge.status as string;
                        let color = "white";
                        let colorString = badgeStatus.toLowerCase();
                        if(colorString==="platinum"){
                            color = "#B4A0BF";
                        }else if(colorString==="gold"){
                            color = "#ffd700";
                        }else if(colorString==="silver"){
                            color = "#aaa9ad";
                        }else if(colorString==="bronze"){
                            color = "#cd7f32";
                        }

                        let icon = <FaCircle/>;
                        if(color==="white"){
                            icon = <FaRegCircle/>
                        }

                        let status = "None";
                        if(badgeStatus.length>0){
                            status = badgeStatus;
                        }

                        return(
                            <div className={"badge-wrapper"} key={"badge-"+index}>
                                <div className={"user-badge"}>
                                    <IconContext.Provider value={{size: "60px",color:color}}>
                                        <div className={"name"}>
                                            {badge.badgeName}
                                        </div>
                                        <div className={"icon"}>
                                            {icon}
                                        </div>
                                        <div className={"status"}>
                                            {status}
                                        </div>
                                        <div className={"progress-text"}>
                                            {badge.statusProgression}%
                                        </div>
                                        <ProgressBar now={badge.statusProgression}  />
                                    </IconContext.Provider>
                                </div>
                            </div>
                        );
                    })}
                </div>
            );
        }
        return(
            <div className={"badges"}>
                No Badges Yet
            </div>
        );
    };

    render() {
        return (
            <>
                <div className={"profile-view"}>
                    {this.getProfileSection()}
                    {this.getBadgesSection()}
                </div>
            </>
        );
    }
}

export default withSystemState(withApiHandler(ProfileView, ErrorHandler.TOAST));