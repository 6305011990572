import React from 'react';
import withApiHandler, {ErrorHandler, InjectedApiHandlerProps} from "../hocs/WithApiHandler";
import withSystemState, {InjectedSystemStateProps} from "../hocs/WithSystemState";
import "../../sass/pages/MessagesView.scss";
import "../../sass/common.scss";
import {AxiosResponse} from "axios";
import Spinner from "react-bootstrap/Spinner";
import {RouteComponentProps, withRouter} from 'react-router-dom';
import asApiClient from "../../api_clients/as_client/ASApiClient";
import Conversation, {ConversationPreviewDisplay} from "../misc/Conversation";
import {
    DbmodelsConversationMessage,
    DbmodelsSubmission,
    MiscConversation,
    ResponsesConversationsResponse
} from "../../api_clients/as_client/src";
import {toast} from "react-toastify";

interface MessagesViewBaseProps {
}

interface MessagesViewState {
    conversations: MiscConversation[];
    loading: boolean;
    selectedConversation: MiscConversation|null;
}

type MessagesViewProps =
    MessagesViewBaseProps
    & InjectedApiHandlerProps
    & InjectedSystemStateProps
    & RouteComponentProps

class MessagesView extends React.Component<MessagesViewProps, MessagesViewState> {

    state = {
        conversations: [] as MiscConversation[],
        loading: false,
        selectedConversation: null as MiscConversation|null
    };

    componentDidMount(): void {
        this.loadConversations(()=>{
            this.loadConversationFromPram();
        });
    }

    loadConversations = (done?:()=>void) => {
        this.setState({
            loading: true,
        });
        this.props.handleRequest(asApiClient.conversationsApi.getConversations(this.props.systemState.token), (response: AxiosResponse<ResponsesConversationsResponse>) => {
            if (response.data.data.conversations !== null && response.data.data.conversations !== undefined) {
                this.setState({
                    conversations: response.data.data.conversations
                },done);
            }
        }, undefined, () => {
            this.setState({
                loading: false
            })
        });
    };

    loadConversationFromPram = () =>{
        let convoParam = this.getConvoParam();
        if(convoParam!==null){
            for (let i = 0; i < this.state.conversations.length; i++) {
                if(this.state.conversations[i].conversationUnid===convoParam){
                    this.setState({selectedConversation:this.state.conversations[i]},()=>{
                        this.props.history.push(window.location.pathname)
                    });
                    return;
                }
            }
            toast.error("Error. Looks like the link is not valid with your account. Please login with the account associated with this link.",{position: toast.POSITION.TOP_CENTER});
        }
    };

    getConvoParam = () =>{
        const urlParams = new URLSearchParams(document.location.search);
        let conv = urlParams.get('conv');
        if(conv!==null && conv.length>0){
            return conv;
        }
        return null;
    };

    getPageContent = () => {
        if (this.state.loading) {
            return (
                <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            );
        }
        if(this.state.selectedConversation!==null && this.state.selectedConversation!==undefined){
            return(
                <Conversation conversation={this.state.selectedConversation} conversationUnid={this.state.selectedConversation.conversationUnid as string} onBackHandler={()=>this.setState({selectedConversation:null})}/>
            );
        }
        return (
            <div className={"wn-list conversations-container"}>
                {this.state.conversations.map((conversation: MiscConversation, index: number) => {
                    return (
                        <div key={"rating-" + index} >
                            <ConversationPreviewDisplay conversation={conversation} onViewMessages={()=>this.setState({selectedConversation:conversation})}/>
                        </div>
                    );
                })}
            </div>

        );
    };

    render() {
        return (
            <>
                <div className={"messages-view"}>
                    {this.getPageContent()}
                </div>
            </>
        );
    }
}

export default withSystemState(withApiHandler(withRouter(MessagesView), ErrorHandler.TOAST));