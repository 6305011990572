import React, {Component} from 'react';
import {
    Elements, ReactStripeElements, StripeProvider
} from 'react-stripe-elements';
import Spinner from "react-bootstrap/Spinner";
import  "../../sass/common.scss";
import {AxiosResponse} from "axios";
import withSystemState, {InjectedSystemStateProps} from "../hocs/WithSystemState";
import withApiHandler, {ErrorHandler, InjectedApiHandlerProps} from "../hocs/WithApiHandler";
import config from "../../config/config";
import UserCards from "./UserCards";
import CardEntryForm from "./CardEntryForm";
import Collapse from "react-bootstrap/Collapse";
import Button from "react-bootstrap/Button";
import asApiClient from "../../api_clients/as_client/ASApiClient";
import {MiscCard, ResponsesCardsResponse} from "../../api_clients/as_client/src";

interface BasePaymentSelectorProps{
    onExistingCardSelected?: (card:MiscCard)=>void;
    onNewCardSelected?: (token: stripe.Token, saveCard: boolean)=>void;
    saveCardOptional?: boolean;
}

type PaymentSelectorProps = BasePaymentSelectorProps & ReactStripeElements.InjectedStripeProps & InjectedApiHandlerProps & InjectedSystemStateProps

class PaymentSelector extends Component<PaymentSelectorProps,any> {
    state={
        existingCards: [] as MiscCard[],
        loading: false,
        cardEntry: false
    };

    componentDidMount(): void {
        this.loadCards();
    }

    loadCards = () =>{
        this.setState({loading:true});
        this.props.handleRequest(asApiClient.usersApi.getCards(this.props.systemState.token),(response:AxiosResponse<ResponsesCardsResponse>)=>{
            if(response.data.data!==undefined && response.data.data.cards!==null && response.data.data.cards!==undefined && response.data.data.cards.length>0){
                this.setState({existingCards:response.data.data.cards, cardEntry:false});
            }else{
                this.setState({cardEntry:true});
            }
        },undefined,()=>{
            this.setState({loading:false});
        });
    };

    tokenHandler = (token: stripe.Token, saveCard: boolean) =>{
        if(!(this.props.onNewCardSelected===undefined || this.props.onNewCardSelected===null)){
            this.props.onNewCardSelected(token,saveCard);
        }
    };

    onExistingCardSelected = (card:MiscCard) =>{
        if(!(this.props.onExistingCardSelected===undefined || this.props.onExistingCardSelected===null)){
            this.props.onExistingCardSelected(card);
        }
    };

    getSelectorContent = () =>{
        if(this.state.loading){
            return(
                <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            );
        }
        let onCancelHandler = null;
        if(this.state.existingCards.length>0){
            onCancelHandler = ()=>this.setState({cardEntry:false});
        }
        return(
            <>
                <Collapse in={!this.state.cardEntry}>
                    <div>
                        <UserCards cards={this.state.existingCards} onSelected={this.onExistingCardSelected}/>
                    </div>
                </Collapse>
                {this.state.cardEntry?null:<div className={"wn-form button-aligner"}><Button variant="primary" size="lg" block onClick={()=>this.setState({cardEntry:true})}>Use Another Card</Button></div>}
                <Collapse in={this.state.cardEntry}>
                    <div>
                        <Elements>
                            <CardEntryForm saveOptional={this.props.saveCardOptional} tokenHandler={this.tokenHandler} onCancel={onCancelHandler}/>
                        </Elements>
                    </div>
                </Collapse>
            </>
        );
    };

    render() {
        return (
            <StripeProvider apiKey={config.stripe.key}>
                {this.getSelectorContent()}
            </StripeProvider>
        );
    }
}

export default withSystemState(withApiHandler(PaymentSelector,ErrorHandler.TOAST));