import React from 'react';
import {RouteComponentProps, withRouter} from "react-router-dom";
import '../sass/components/AdminLeaderboardsPage.scss';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-datetime/css/react-datetime.css';
import withSystemState, {InjectedSystemStateProps} from "../components/hocs/WithSystemState";
import withApiHandler, {ErrorHandler, InjectedApiHandlerProps} from "../components/hocs/WithApiHandler";
import NavBar, {NavbarAction} from "../components/navigation/NavBar";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import {Formik} from "formik";
import * as yup from "yup";
import moment from 'moment';
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import {toast} from "react-toastify";
import asApiClient from "../api_clients/as_client/ASApiClient";
import {AxiosResponse} from "axios";
import {
    DbmodelsContest,
    MiscAdminCuratorLeaderboardEntry,
    MiscAdminTrackLeaderboardEntry, ResponsesAdminCuratorLeaderboardResponse,
    ResponsesAdminTrackLeaderboardResponse, ResponsesContestsResponse,
    ResponsesProblemSubmissionsResponse
} from "../api_clients/as_client/src";
import Table from "react-bootstrap/Table";
// @ts-ignore
import LoadingOverlay from 'react-loading-overlay';
import { CSVLink } from "react-csv";
let DateTime = require('react-datetime');

export type AdminsLeaderBoardsPageProps = RouteComponentProps & InjectedSystemStateProps & InjectedApiHandlerProps;
interface AdminsLeaderBoardsPageState {
    loading: boolean;
    type: string;
    startDateTime: moment.Moment;
    endDateTime: moment.Moment;
    closeStartDateTime: moment.Moment;
    closeEndDateTime: moment.Moment;
    trackEntries: MiscAdminTrackLeaderboardEntry[];
    curatorEntries: MiscAdminCuratorLeaderboardEntry[];
    contests: DbmodelsContest[];
    selectedContest: string;
}

class AdminsLeaderBoardsPage extends React.Component<AdminsLeaderBoardsPageProps,AdminsLeaderBoardsPageState> {

    state={
        loading:false,
        type: "curators",
        startDateTime: moment(),
        endDateTime: moment(),
        closeStartDateTime: moment(),
        closeEndDateTime: moment(),
        trackEntries: [] as MiscAdminTrackLeaderboardEntry[],
        curatorEntries: [] as MiscAdminCuratorLeaderboardEntry[],
        contests: [] as DbmodelsContest[],
        selectedContest: ''
    };

    componentDidMount(): void {
        this.loadContests();
    }

    loadContests = () =>{
        this.setState({loading:true});
        this.props.handleRequest(asApiClient.adminsApi.getAdminContests(this.props.systemState.adminToken),(response:AxiosResponse<ResponsesContestsResponse>)=>{
            if(response.data.data.contests!==null) {
                this.setState({contests: response.data.data.contests});
            }
        },undefined,()=>{
            this.setState({loading:false});
        });
    };

    loadTracks = (start:string, end:string, closeStart?:string, closeEnd?:string) =>{
        let contest = undefined as undefined|string;
        if(this.state.selectedContest.length>0 && this.state.selectedContest!=="None"){
            contest = this.state.selectedContest;
        }
        this.setState({loading:true});
        this.props.handleRequest(asApiClient.adminsApi.getAdminTrackLeaderboard(end,start,this.props.systemState.adminToken,closeEnd,closeStart,contest),(response:AxiosResponse<ResponsesAdminTrackLeaderboardResponse>)=>{
            if(response.data.data.trackLeaderboard!==null) {
                this.setState({trackEntries: response.data.data.trackLeaderboard});
            }
        },undefined,()=>{
            this.setState({loading:false});
        });
    };

    loadCurators = (start:string, end:string) =>{
        this.setState({loading:true});
        this.props.handleRequest(asApiClient.adminsApi.getAdminCuratorLeaderboard(this.props.systemState.adminToken,end,start),(response:AxiosResponse<ResponsesAdminCuratorLeaderboardResponse>)=>{
            if(response.data.data.curatorLeaderboard!==null) {
                this.setState({curatorEntries: response.data.data.curatorLeaderboard});
            }
        },undefined,()=>{
            this.setState({loading:false});
        });
    };

    typeChange = (value:string) =>{
        this.setState({type:value,trackEntries:[],curatorEntries:[]});
    };

    submit = () =>{
        if(!moment(this.state.startDateTime).isValid()){
            toast.error("Invalid Start",{position: toast.POSITION.TOP_CENTER});
            return;
        }
        if(!moment(this.state.endDateTime).isValid()){
            toast.error("Invalid End",{position: toast.POSITION.TOP_CENTER});
            return;
        }
        let startDateTime = this.state.startDateTime.utc(false).format();
        let endDateTime = this.state.endDateTime.utc(false).format();
        let type = this.state.type;
        if(type==="curators"){
            this.loadCurators(startDateTime,endDateTime);
        }else if(type==="tracks"){
            if(!moment(this.state.closeEndDateTime).isValid()){
                toast.error("Invalid Close Start",{position: toast.POSITION.TOP_CENTER});
                return;
            }
            if(!moment(this.state.closeStartDateTime).isValid()){
                toast.error("Invalid Close End",{position: toast.POSITION.TOP_CENTER});
                return;
            }
            let closeStartDateTime = this.state.closeStartDateTime.utc(false).format();
            let closeEndDateTime = this.state.closeEndDateTime.utc(false).format();
            this.loadTracks(startDateTime,endDateTime,closeStartDateTime,closeEndDateTime);
        }
    };

    onStartDateChange = (value:any) =>{
        this.setState({
            startDateTime:moment(value)
        })
    };

    onCloseStartDateChange = (value:any) =>{
        this.setState({
            closeStartDateTime:moment(value)
        })
    };

    onEndDateChange = (value:any) =>{
        this.setState({
            endDateTime:moment(value)
        })
    };

    onCloseEndDateChange = (value:any) =>{
        this.setState({
            closeEndDateTime:moment(value)
        })
    };

    getLeaderBoardDisplay = () =>{
        if(this.state.loading){
            return null;
        }
        let type = this.state.type;
        if(type==="curators"){
            return this.getCuratorTable();
        }else if(type==="tracks"){
            return this.getTrackTable();
        }

    };

    getTrackCSVData = () =>{
        let tracks = this.state.trackEntries;
        let data = [];
        for(let x =0; x<tracks.length; x++){
            data.push({
                artistName: tracks[x].artistName,
                email: tracks[x].artistEmail,
                points: tracks[x].points,
                trackName: tracks[x].trackName,
                link: tracks[x].trackUrl
            });
        }
        return data;
    };

    getTrackTable = () =>{
        if(this.state.trackEntries!==null && this.state.trackEntries.length>0) {
            return (
                <>
                    <div className={"centered-button-row"}>
                        <CSVLink data={this.getTrackCSVData()} filename={"tracks-leaderboard.csv"}
                                 className="btn btn-primary">Download</CSVLink>
                    </div>
                <Table responsive striped bordered hover variant="dark">
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Points</th>
                        <th>Track Name</th>
                        <th>Artist Name</th>
                        <th>Artist Email</th>
                        <th>Artist FbID</th>
                        <th>Track Url</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.trackEntries.map((entry: MiscAdminTrackLeaderboardEntry, index: number) => {
                        return (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{entry.points}</td>
                                <td>{entry.trackName}</td>
                                <td>{entry.artistName}</td>
                                <td>{entry.artistEmail}</td>
                                <td>{entry.artistFbID}</td>
                                <td>{entry.trackUrl}</td>
                            </tr>
                        );
                    })}
                    </tbody>
                </Table>
                </>
            );
        }
        return null;
    };

    getCuratorCSVData = () =>{
        let curators = this.state.curatorEntries;
        let data = [];
        for(let x =0; x<curators.length; x++){
            data.push({
                name: curators[x].name,
                email: curators[x].email,
                points: curators[x].points,
            });
        }
        return data;
    };

    getCuratorTable = () =>{
        if(this.state.curatorEntries!==null && this.state.curatorEntries.length>0) {
            return (
                <>
                    <div className={"centered-button-row"}>
                        <CSVLink data={this.getCuratorCSVData()} filename={"curators-leaderboard.csv"}
                                 className="btn btn-primary">Download</CSVLink>
                    </div>
                    <Table responsive striped bordered hover variant="dark">
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Points</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>FbID</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.curatorEntries.map((entry: MiscAdminCuratorLeaderboardEntry, index: number) => {
                            return (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{entry.points}</td>
                                    <td>{entry.name}</td>
                                    <td>{entry.email}</td>
                                    <td>{entry.fbID}</td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </Table>
                </>
            );
        }
        return null;
    };

    selectContest = (e:any) =>{
        let contestUnid = e.target.value;
        for (let i = 0; i < this.state.contests.length; i++) {
            let contest = this.state.contests[i];
            if(contest.unid===contestUnid){
                this.setState({
                    startDateTime: moment(contest.startTime),
                    endDateTime: moment(contest.endTime),
                    selectedContest:e.target.value
                });
                return;
            }
        }

        this.setState({selectedContest:e.target.value})
    };

    getInstructions = () =>{
        if(this.state.type==="curators"){
            return(
                <>
                    <div className={"center"}>
                        Selecting a contest will simply fill in the dates for you. Please keep in mind of the following
                    </div>
                    <ul>
                        <li>Start - start time for when to start calculating the curator points</li>
                    </ul>
                    <ul>
                        <li>End - end time for when to stop calculating the curator points</li>
                    </ul>
                </>
            );
        }else if (this.state.type==="tracks"){
            return(
                <>
                    <div className={"center"}>
                        Please Note the following about selecting a contest vs not selecting a contest
                    </div>
                    <ul>
                        <li>Selecting a contest - will get submissions that opted to be in contests AND will take into account submissions that opted to be in the selected contest</li>
                        <li>NOT selecting a contest - will get submissions regardless if they opted to be in contests or not</li>
                    </ul>
                    <div className={"center"}>
                        Definitions
                    </div>
                    <ul>
                        <li>Create Start - The start time for which tracks must have been submitted</li>
                        <li>Create End - The end time for which tracks must have been submitted</li>
                        <li>Close Start - The start time for which tracks must have been closed (they received all the ratings they needed and got a track score)</li>
                        <li>Close End - The end time for which tracks must have been closed (they received all the ratings they needed and got a track score)</li>
                    </ul>
                </>
            );
        }
        return null;
    };

    render() {
        return(
            <LoadingOverlay
                active={this.state.loading}
                spinner
                text='Loading...'
            >
                <div className={"admin-leaderboards"}>
                    <div className={"notes"}>
                        {this.getInstructions()}
                    </div>

                    <ToggleButtonGroup className={"type-selector"} type="radio" name="options" value={this.state.type} onChange={this.typeChange}>
                        <ToggleButton value={"curators"}>Curators</ToggleButton>
                        <ToggleButton value={"tracks"}>Tracks</ToggleButton>
                    </ToggleButtonGroup>
                    <div className={"wn-form"}>
                        <Form noValidate onSubmit={this.submit}>
                            <Form.Row>
                                <Form.Group as={Col} xs="12">
                                    <Form.Label>Contest</Form.Label>
                                    <Form.Control as="select" onChange={this.selectContest} name="contest" value={this.state.selectedContest}>
                                        <option value={"None"}></option>
                                        {this.state.contests.map((contest:DbmodelsContest,index:number)=>{
                                            return(
                                                <option key={"contest-"+index} value={contest.unid}>{contest.name+" "+moment(contest.startTime).format("MM/DD/YYYY")+" - "+moment(contest.endTime).format("MM/DD/YYYY")}</option>
                                            );
                                        })}
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group as={Col} xs="6" controlId="validationLink">
                                    <Form.Label>{this.state.type==="curator"?"Start":"Create Start"}</Form.Label>
                                    <DateTime value={this.state.startDateTime} onChange={this.onStartDateChange}/>
                                </Form.Group>
                                <Form.Group as={Col} xs="6" controlId="validationLink">
                                    <Form.Label>{this.state.type==="curator"?"End":"Create End"}</Form.Label>
                                    <DateTime value={this.state.endDateTime} onChange={this.onEndDateChange}/>
                                </Form.Group>
                                {this.state.type==="tracks"?
                                    <>
                                    <Form.Group as={Col} xs="6" controlId="validationLink">
                                        <Form.Label>Close Start</Form.Label>
                                        <DateTime value={this.state.closeStartDateTime} onChange={this.onCloseStartDateChange}/>
                                    </Form.Group>
                                    <Form.Group as={Col} xs="6" controlId="validationLink">
                                    <Form.Label>Close End</Form.Label>
                                    <DateTime value={this.state.closeEndDateTime} onChange={this.onCloseEndDateChange}/>
                                    </Form.Group></>:null}
                            </Form.Row>
                            <div>
                                <Button variant="primary" size="lg" block onClick={this.submit} disabled={this.state.loading}>Load</Button>
                            </div>
                        </Form>
                    </div>
                    <div className={"leaderboard-display"}>
                        {this.getLeaderBoardDisplay()}
                    </div>
                </div>
            </LoadingOverlay>
        );
    }
}

export default withSystemState(withRouter(withApiHandler(AdminsLeaderBoardsPage,ErrorHandler.TOAST)));